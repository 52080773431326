<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">Messaging made simple</h1>
                  <p class="cntnt">
                    Build stronger connections with customers through our
                    reliable and secure channels.
                  </p>
                  <router-link to="/sign-up" class="cmn_btn light">
                    Start now
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/messaging_banner_1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">
                    Send messages your <br />
                    customers want to receive
                  </h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>Worldwide Coverage</strong>
                      <span
                        >Get access to our 185+ countries with multi-language
                        capabilities.
                      </span>
                    </li>
                    <li>
                      <strong>Accelerated Velocity</strong>
                      <span
                        >Worldwide server deployment which allows for
                        millisecond transmission for your customers.
                      </span>
                    </li>
                    <li>
                      <strong>Global Partners</strong>
                      <span
                        >Connect with our 1,000+ global partner operators to
                        reach out to your customers anywhere, anytime.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">Messages that you can trust</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#smsOtp"
                  type="button"
                  role="tab"
                  aria-controls="smsOtp"
                  aria-selected="true"
                >
                  <strong> SMS OTP </strong>
                  <span>
                    Deliver seamless authorization <br />
                    codes through SMS
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#marketingOtp"
                  type="button"
                  role="tab"
                  aria-controls="marketingOtp"
                  aria-selected="false"
                >
                  <strong> Marketing SMS </strong>
                  <span>
                    Efficiently market your brand <br />
                    and products with SMS
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-3"
                  data-bs-toggle="tab"
                  data-bs-target="#notificationOtp"
                  type="button"
                  role="tab"
                  aria-controls="notificationOtp"
                  aria-selected="false"
                >
                  <strong> SMS Notification </strong>
                  <span>
                    Keep your customers informed efficiently through SMS
                    notifications
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-4"
                  data-bs-toggle="tab"
                  data-bs-target="#emailOtp"
                  type="button"
                  role="tab"
                  aria-controls="emailOtp"
                  aria-selected="false"
                >
                  <strong> Email OTP </strong>
                  <span>
                    Send ultra-secure authorization codes directly to your
                    customer's email inbox
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="smsOtp"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <span class="tab_btn_desc">
                    Deliver seamless authorization <br />
                    codes through SMS
                  </span>
                    <figure>
                      <img
                        src="../../assets/images/products_applications/effortless_security.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Effortless Security</h4>
                    <p class="cntnt">
                      SMS verification codes for fast and secure sign-up or
                      login on apps and websites.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/forget_me_not.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Forget Me Not</h4>
                    <p class="cntnt">
                      SMS verification for swift password recovery whilst
                      ensuring account security.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Payment_icon.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Payment Authentication</h4>
                    <p class="cntnt">
                      SMS verification for user identity authentication during
                      online transactions to prevent financial loss.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="marketingOtp"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <span class="tab_btn_desc">
                    Efficiently market your brand <br />
                    and products with SMS
                  </span>
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Effecient_User_Acquisition.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Effecient User <br />
                      Acquisition
                    </h4>
                    <p class="cntnt">
                      Send event details and offers via SMS with a registration
                      link to attract customers and boost website visits
                      cost-effectively.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Omnichannel_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Omnichannel <br />
                      Marketing
                    </h4>
                    <p class="cntnt">
                      Market products on e-commerce, games, social media, and
                      live videos for precise, cost-effective customer outreach.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Targeted_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Targeted Retargeting</h4>
                    <p class="cntnt">
                      Send promotional SMS with new product discounts, deals and
                      benefits to efficiently re-engage inactive customers.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="notificationOtp"
                role="tabpanel"
                aria-labelledby="tab-3"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <span class="tab_btn_desc">
                    Keep your customers informed efficiently through SMS
                    notifications
                  </span>
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Create_lifelong_customers.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Create Lifelong <br />
                      Customers
                    </h4>
                    <p class="cntnt">
                      Build loyalty with your customers by keeping them informed
                      and valued.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Attentive_customer_care.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Attentive <br />
                      Customer Care
                    </h4>
                    <p class="cntnt">
                      Send prompt reminders for classes, bills, top-ups,
                      receipts, and more.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/hand_shake.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Personal Touch</h4>
                    <p class="cntnt">
                      Build deeper connection and show customer appreciation
                      through sincere greetings.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="emailOtp"
                role="tabpanel"
                aria-labelledby="tab-4"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <span class="tab_btn_desc">
                    Send ultra-secure authorization codes directly to your
                    customer's email inbox
                  </span>
                    <figure>
                      <img
                        src="../../assets/images/products_applications/effortless_security.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Effortless Security</h4>
                    <p class="cntnt">
                      Email verification codes for fast and secure sign-up or
                      login on apps and websites.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/forget_me_not.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Forget Me Not</h4>
                    <p class="cntnt">
                      Email verification for swift password recovery whilst
                      ensuring account security.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Payment_icon.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Payment Authentication</h4>
                    <p class="cntnt">
                      Email verification for user identity authentication during
                      online transactions to prevent financial loss.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ProductMessage",
};
</script>

