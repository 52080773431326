import { createApp } from 'vue'
import App from './App.vue'

// Owl bootstrap
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap styles
import 'bootstrap'; // Import Bootstrap JavaScript components

// assets files
import './assets/fonts/stylesheet.css'; // Import custom style file
import './assets/css/animate.css';
import './assets/css/github-style.css';
import './assets/css/header-style.css';
import './assets/css/mohit_style.css';
import './assets/css/404.css';
import './assets/css/style.css';




// router
import router from './routes.js'



// for dynamic title 
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'NXCloud';
    window.scrollTo(0, 0);
    next();
});

createApp(App).use(router).mount('#app')
