<template>
  <footer class="main-footer">
    <div class="chatbot_wrap" :class="{ active: isChatbot }">
      <button class="chatbot_btn" @click="toggleChatBox">
        <img src="../assets/images/Chat.svg" alt="chatbot" />
      </button>
      <ul class="list">
        <li>
          <a href="#" target="_blank">
            <i class="icon">
              <img
                src="../assets/images/bot_live_chat.svg"
                alt="bot_live_chat"
              />
            </i>
            <span class="text">Live Chat</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="icon">
              <img src="../assets/images/bot_wechat.svg" alt="bot_wechat" />
            </i>
            <span class="text">WeChat</span>
          </a>
        </li>
        <li>
          <a href="tel:400-7800-900">
            <i class="icon">
              <img src="../assets/images/bot_phone.svg" alt="bot_phone" />
            </i>
            <span class="text">Phone</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="container desktop">
      <div class="row">
        <div class="col-md-4">
          <router-link to="/zh">
            <img
              src="../assets/images/footer_logo.svg"
              alt="NXCLOUD"
              class="img-fluid"
            />
          </router-link>
          <div class="footer-icons-wrap">
            <div class="footer-icons">
              <a
                href="https://www.facebook.com/people/NXCLOUD/100063278852731/"
                target="_blank"
              >
                <img src="../assets/images/fb.svg" class="img-fluid" alt="fb"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.linkedin.com/company/nxcloud/"
                target="_blank"
                ><img
                  src="../assets/images/li.svg"
                  class="img-fluid"
                  alt="linkdin"
              /></a>
            </div>
            <div class="footer-icons">
              <a href="https://twitter.com/NXCLOUD1" target="_blank">
                <img src="../assets/images/x.svg" class="img-fluid" alt="tw"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.instagram.com/nxcloud_communication/"
                target="_blank"
                ><img
                  src="../assets/images/instagram-white-icon.svg"
                  class="img-fluid"
                  alt="en"
              /></a>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">沟通</h5>
                <ul>
                  <li>
                    <router-link to="/zh-product-message"> 消息 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-voice"> 语音 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-conversation">
                      互动对话
                    </router-link>
                  </li>
                </ul>

                <h5 class="footer-list-hdng solution-hdng">应用</h5>
                <ul>
                  <li>
                    <router-link to="/zh-product-fraud-prevention">
                      防欺诈策略
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-global-number">
                      全球号码
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-customer-engagement">
                      客户互动
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">行业</h5>
                <ul>
                  <li>
                    <router-link to="/zh-industry-e-commerce">
                      跨境电商
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-education">
                      在线教育
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-fintech">
                      高新技术
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-gaming">
                      出海游戏
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-logistics">
                      跨境物流
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-online-social">
                      在线社交
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-retail">
                      实体零售
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">公司</h5>
                <ul>
                  <li>
                    <router-link to="/zh-company"> 关于 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-terms-services">
                      服务协议
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-privacy-policy">
                      隐私政策
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mobile">
      <div class="row">
        <div class="col-12">
          <div class="footer_logo_wrap">
            <router-link to="/zh">
              <img
                src="../assets/images/footer_logo.svg"
                alt="NXCLOUD"
                class="img-fluid"
              />
            </router-link>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">沟通</h5>
                <ul>
                  <li>
                    <router-link to="/zh-product-message"> 消息 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-voice"> 语音 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-conversation">
                      互动对话
                    </router-link>
                  </li>
                </ul>

                <h5 class="footer-list-hdng">行业</h5>
                <ul>
                  <li>
                    <router-link to="/zh-industry-e-commerce">
                      跨境电商
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-education">
                      在线教育
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-fintech"> 高新技术 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-gaming"> 出海游戏 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-logistics">
                      跨境物流
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-online-social">
                      在线社交
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-retail"> 实体零售 </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">应用</h5>
                <ul>
                  <li>
                    <router-link to="/zh-product-fraud-prevention">
                      防欺诈策略
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-global-number">
                      全球号码
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-customer-engagement">
                      客户互动
                    </router-link>
                  </li>
                </ul>
                <h5 class="footer-list-hdng">公司</h5>
                <ul>
                  <li>
                    <router-link to="/zh-company"> 关于 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-terms-services">
                      服务协议
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-privacy-policy">
                      隐私政策
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="footer-icons-wrap">
            <div class="footer-icons">
              <a
                href="https://www.facebook.com/people/NXCLOUD/100063278852731/"
                target="_blank"
              >
                <img src="../assets/images/fb.svg" class="img-fluid" alt="fb"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.linkedin.com/company/nxcloud/"
                target="_blank"
                ><img
                  src="../assets/images/li.svg"
                  class="img-fluid"
                  alt="linkdin"
              /></a>
            </div>
            <div class="footer-icons">
              <a href="https://twitter.com/NXCLOUD1" target="_blank">
                <img src="../assets/images/x.svg" class="img-fluid" alt="tw"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.instagram.com/nxcloud_communication/"
                target="_blank"
                ><img
                  src="../assets/images/instagram-white-icon.svg"
                  class="img-fluid"
                  alt="en"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCh",
  data() {
    return {
      changeTabFromUrl: "",
      isChatbot: false,
      isChatFormContainer: false,
      isChatFormWrap: false,
      isThankYou: false,
    };
  },
  mounted() {
    // Pehli baar ID ko retrieve karna
    this.getIdfromUrl();
    // Vue Router ka afterEach hook register karna
    this.$router.afterEach(() => {
      // Har inner page change hone par ID ko retrieve karna
      this.getIdfromUrl();
    });
  },

  methods: {
    // changeTab function
    changeTab() {
      console.log("changeTabFromUrl " + this.changeTabFromUrl);
      const tabBtn = `[data-bs-target="#${this.changeTabFromUrl}"]`;
      console.log(tabBtn);
      setTimeout(() => {
        const targetElement = document.querySelector(tabBtn);
        if (targetElement) {
          targetElement.click();
          document.querySelector(".prod_desc_section").scrollIntoView({
            behavior: "smooth",
          });
        } else {
          console.error(`Element with selector ${tabBtn} not found.`);
        }
      }, 1000);
    },

    // getIdfromUrl for acti  ve tab on change
    getIdfromUrl() {
      // Pura URL le lena ke bajayf sirf query string le lena.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      // ID parameter ko extract karna.
      const id = urlParams.get("id");
      if (id) {
        this.changeTabFromUrl = id;
        this.changeTab();
      }
    },
    // Toggle Chatbot
    toggleChatBox(event) {
      event.preventDefault();
      if (!this.isChatbot) {
        this.isChatbot = true;
      } else {
        this.isChatbot = false;
      }
    },
    // Toggle Chatbot Form
    toggleChatForm(event) {
      event.preventDefault();
      if (!this.isChatFormContainer) {
        this.isChatFormContainer = true;
        this.isChatFormWrap = true;
      } else {
        this.isChatFormContainer = false;
        this.isChatFormWrap = false;
      }
    },
    // Close chatbot form
    closeChatForm(event) {
      event.preventDefault();
      if (this.isChatFormContainer) {
        this.isChatFormContainer = false;
        this.isThankYou = false;
      }
    },
    // Chatbot form submit
    submitChatForm(event) {
      event.preventDefault();
      if (!this.isThankYou) {
        this.isThankYou = true;
        this.isChatFormWrap = false;
      }
    },
    // Thankyou done
    thankyouDone(event) {
      event.preventDefault();
      this.isChatbot = false;
      this.isChatFormContainer = false;
      this.isChatFormWrap = false;
      this.isThankYou = false;
    },
  },
};
</script>

<style scoped>
.contact_sec .form_wrap {
  display: none;
}

.contact_sec .form_wrap.active {
  display: block !important;
}

.chatbot_wrap
  .list
  li.chatbotFormPopup
  .chatbotFormPopupBox
  .thankyou_wrap.active {
  display: block !important;
}
</style>
