<template>
  <HeaderFooterGlobal />
</template>

<script>
import HeaderFooterGlobal from './components/HeaderFooterGlobal.vue'

// Aos
import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
  name: 'App',
  components: {
    HeaderFooterGlobal,
  },
  mounted() {
    AOS.init(
      {
        // Global settings:
        disable: false,
        startEvent: 'DOMContentLoaded',
        initClassName: 'aos-init',
        animatedClassName: 'aos-animate',
        useClassNames: false,
        disableMutationObserver: false,
        debounceDelay: 50,
        throttleDelay: 99,
        offset: 120,
        delay: 0,
        duration: 2000,
        easing: 'ease-in-out-back',
        once: true,
        mirror: false, 
        anchorPlacement: 'top-bottom'
      }
    )
  }
}

</script>
