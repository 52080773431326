<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">金融科技解决方案</h1>
                  <p class="cntnt">
                    为跨国银行、跨境支付等企业提供全方位<br />
                    的全球通信解决方案
                  </p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img src="../../assets/images/industry/fintech_banner.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benifits -->
    <section class="benifits">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>
            为金融科技企业定制完整的<br />
            行业解决方案
          </h2>
        </div>
        <div class="content desktop">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/lower_cost_arrow.svg" alt="lower_cost_arrow." />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>降低获客成本</h3>
                    <p>
                      使用多产品组合形式，构建自动化营销
                      体系，快速获客、精准营销， 宣传公司产品，促进销售转化。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/account_protection.svg" alt="account_protection" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>强化信息安全</h3>
                    <p>
                      在注册、快捷登录、异地登录、修改密
                      码、身份认证等环节，提供多种验证方 式，验证用户身份，
                      保障账号信息安全。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/reduce_bBad_debt.svg" alt="reduce_bBad_debt" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>减少坏账损失</h3>
                    <p>
                      构建“AI审核+AI逾期通知+AI提醒”的组
                      合型风险管理模式，进行智能化还款通
                      知提醒，高效提升用户回款， 降低坏账率。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content mobile">
          <div class="row justify-content-center">
            <div class="ecommerceSolutionsSlider swiperSlider slider_equal_height_no_bg_default_pagination">
              <swiper :modules="modules" :slides-per-view="1" :space-between="20" :equal-height="true"
                :autoplay="{ delay: 5000, disableOnInteraction: true }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="industrySlider" @slideChange="industrySliderSlideChange">
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/lower_cost_arrow.svg" alt="lower_cost_arrow." />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>降低获客成本</h3>
                          <p>
                            使用多产品组合形式，构建自动化营销
                            体系，快速获客、精准营销， 宣传公司产品，促进销售转化。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/account_protection.svg" alt="account_protection" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>强化信息安全</h3>
                          <p>
                            在注册、快捷登录、异地登录、修改密
                            码、身份认证等环节，提供多种验证方 式，验证用户身份，
                            保障账号信息安全。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/reduce_bBad_debt.svg" alt="reduce_bBad_debt" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>减少坏账损失</h3>
                          <p>
                            构建“AI审核+AI逾期通知+AI提醒”的组
                            合型风险管理模式，进行智能化还款通
                            知提醒，高效提升用户回款， 降低坏账率。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new col_3_3 pt_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>客户全旅程服务</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-4">
              <div class="content_box step step_1">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/growth_marketing.svg" alt="growth_marketing" />
                </div>
                <div class="text_wrap">
                  <h3>1. 营销拓客</h3>
                  <div class="tags_wrap">
                    <router-link :to="{
                      path: '/zh-product-message',
                      query: { id: 'marketingOtp' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="marketingOtp"
                        data-url="../zh-product-message">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/marketing_sms_text.svg" alt="icon" />
                        </i>
                        <span class="text">营销短信</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{
                      path: '/zh-product-fraud-prevention',
                      query: { id: 'numberCheck' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="numberCheck"
                        data-url="../zh-product-fraud-prevention">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/number_check.svg" alt="icon" />
                        </i>
                        <span class="text">号码检测</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-customer-engagement',
                      query: { id: 'AICC' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/aicc_icon.svg" alt="icon" />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_2">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/customer_service.svg" alt="customer_service" />
                </div>
                <div class="text_wrap">
                  <h3>2. 客户咨询</h3>
                  <div class="tags_wrap">
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'whatsappBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="icon" />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'viberBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/viber_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'zaloBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="zaloBusiness"
                        data-url="../zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/zalo_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-customer-engagement',
                      query: { id: 'AICC' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/aicc_icon.svg" alt="icon" />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <router-link to="/zh-product-global-number" class="tag icon_item">
                      <i class="icon">
                        <img src="../../assets/images/customer_journey_section/global_number.svg" alt="icon" />
                      </i>
                      <span class="text">全球虚拟号码</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_3">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/customer_alert.svg" alt="customer_alert" />
                </div>
                <div class="text_wrap">
                  <h3>3. 服务提醒</h3>
                  <div class="tags_wrap">
                    <router-link :to="{
                      path: '/zh-product-message',
                      query: { id: 'smsOtp' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="smsOtp"
                        data-url="../zh-product-message">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/sms_otp.svg" alt="icon" />
                        </i>
                        <span class="text">通知短信</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{
                      path: '/zh-product-voice',
                      query: { id: 'voiceOtp' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="voiceOtp"
                        data-url="../zh-product-voice">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/voice_otp.svg" alt="icon" />
                        </i>
                        <span class="text">语音通知</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-customer-engagement',
                      query: { id: 'AICC' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/aicc_icon.svg" alt="icon" />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey_new pt_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-12">
              <div class="img_box text-center">
                <img src="../../assets/images/industry_fintech.svg" alt="industry_fintech.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row justify-content-end">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 1</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 2</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 3</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row flex-row-reverse">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 6</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 7</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recommended products -->
    <section class="product_sec pt_100 pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>产品推荐</h2>
        </div>
        <div class="content desktop">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/privacy_icon.svg" alt="privacy_number" />
                </div>
                <div class="text_wrap">
                  <h3>隐私号码</h3>
                  <p>
                    在通信过程中代替真实号码进行显号，以实现在不透露用户真实号码信息的前提下进行常规通信业务，从而保护双方个人信息安全。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/aicc-change.svg" alt="NXcallbot" />
                </div>
                <div class="text_wrap">
                  <h3>AICC</h3>
                  <p>
                    融合传统呼叫中心和AI智能技术，为企业提供全方位、智能化客户服务方案，轻松、快捷搭建集客户服务、营销推广等场景的一体化客户服务中心。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/email_otp_icon.svg" alt="email_OTP" />
                </div>
                <div class="text_wrap">
                  <h3>邮件验证码</h3>
                  <p>
                    用户在注册登录、密码找回以及身份验证时，<br />请求发送到邮箱的数字验证码或链接，<br />进行安全验证即可完成操作。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/voice_notification_icon.svg" alt="voice_notifications" />
                </div>
                <div class="text_wrap">
                  <h3>语音通知</h3>
                  <p>
                    通过语音外呼自动播报给用户，向企业用户和会员传递通知信息，节省人工成本，提升工作效率。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content mobile">
          <div class="row justify-content-center">
            <div class="customerSlider swiperSlider numberNav slider_equal_height">
              <swiper :modules="modules" :slides-per-view="1" :space-between="50"
                :autoplay="{ delay: 5000, disableOnInteraction: false }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="customerSliderSwiper" @slideChange="customerSliderSlideChange">
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/privacy_icon.svg" alt="privacy_number" />
                      </div>
                      <div class="text_wrap">
                        <h3>隐私号码</h3>
                        <p>
                          在通信过程中代替真实号码进行显号，以实现在不透露用户真实号码信息的前提下进行常规通信业务，从而保护双方个人信息安全。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/aicc-change.svg" alt="NXcallbot" />
                      </div>
                      <div class="text_wrap">
                        <h3>AICC</h3>
                        <p>
                          融合传统呼叫中心和AI智能技术，为企业提供全方位、智能化客户服务方案，轻松、快捷搭建集客户服务、营销推广等场景的一体化客户服务中心。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/email_otp_icon.svg" alt="email_OTP" />
                      </div>
                      <div class="text_wrap">
                        <h3>邮件验证码</h3>
                        <p>
                          用户在注册登录、密码找回以及身份验证时，<br />请求发送到邮箱的数字验证码或链接，<br />进行安全验证即可完成操作。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/voice_notification_icon.svg" alt="voice_notifications" />
                      </div>
                      <div class="text_wrap">
                        <h3>语音通知</h3>
                        <p>
                          通过语音外呼自动播报给用户，向企业用户和会员传递通知信息，节省人工成本，提升工作效率。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <span class="count">{{ currentSlide }} / {{ slideLength }}</span>
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cts">
      <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供<br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">开始使用</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { ref } from "vue";

// Import Swiper styles
export default {
  name: "ZhIndustryFintech",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const currentSlide = ref(1); // Initialize currentSlide with initial value 1
    const slideLength = ref(1); // Initialize slideLength with initial value 1
    const homeBannerSwiper = () => { };
    const onHomeBannerSlideChange = () => { };

    // For Number pagination
    const customerSliderSwiper = (swiper) => {
      slideLength.value = swiper.slides.length;
      swiper.on("slideChange", () => {
        currentSlide.value = swiper.realIndex + 1;
      });
    };

    const customerSliderSlideChange = () => { };

    const otherSayAboutUsSwiper = () => { };
    const otherSayAboutUsSlideChange = () => { };
    return {
      currentSlide,
      slideLength,
      homeBannerSwiper,
      onHomeBannerSlideChange,
      customerSliderSwiper,
      customerSliderSlideChange,
      otherSayAboutUsSwiper,
      otherSayAboutUsSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  methods: {
    leftSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const prevButton = currSlider.querySelector(".swiper-button-prev");
      if (prevButton) {
        prevButton.click();
      } else {
        console.error("Previous button not found");
      }
    },
    rightSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const nextButton = currSlider.querySelector(".swiper-button-next");
      if (nextButton) {
        nextButton.click();
      } else {
        console.error("Next button not found");
      }
    },
    moveSliderDefaultPagination() {
      const all_slider_equal_height_default_pagination =
        document.querySelectorAll(".slider_equal_height_default_pagination");
      console.log(all_slider_equal_height_default_pagination);
      all_slider_equal_height_default_pagination.forEach((slider) => {
        const pagination = slider.querySelector(".swiper-pagination");
        slider.appendChild(pagination);
      });
    },
  },
  mounted() {
    this.moveSliderDefaultPagination();
  },
};
</script>
