import { createWebHistory, createRouter } from "vue-router";

// English pages
import Home from "./components/pages/HomePage.vue";
import ProductMessage from "./components/pages/ProductMessage.vue";
import ProductVoice from "./components/pages/ProductVoice.vue";
import ProductConversation from "./components/pages/ProductConversation.vue";
import ProductFraudPrevention from "./components/pages/ProductFraudPrevention.vue";
import ProductGlobalNumber from "./components/pages/ProductGlobalNumber.vue";
import ProductCustomerEngagement from "./components/pages/ProductCustomerEngagement.vue";
import IndustryLogistics from "./components/pages/IndustryLogistics.vue";
import IndustryEcommerce from "./components/pages/IndustryEcommerce.vue";
import IndustryRetail from "./components/pages/IndustryRetail.vue";
import IndustryEducation from "./components/pages/IndustryEducation.vue";
import IndustryGaming from "./components/pages/IndustryGaming.vue";
import IndustryOnlineSocial from "./components/pages/IndustryOnlineSocial.vue";
import IndustryFintech from "./components/pages/IndustryFintech.vue";
import CompanyPage from "./components/pages/CompanyPage.vue";
import DeveloperPage from "./components/pages/DeveloperPage.vue";
import PricingPage from "./components/pages/PricingPage.vue";
import LoginPage from "./components/pages/LoginPage.vue";
import ForgetPassword from "./components/pages/ForgetPassword.vue";
import PrivacyPolicy from "./components/pages/PrivacyPolicy.vue";
import TermsAndServices from "./components/pages/TermsAndServices.vue";
import SignupPage from "./components/pages/SignupPage.vue";
// 404 page not found
import PageNotFound from "./components/pages/PageNotFound.vue";

// Chinese pages
import ZhHomePage from "./components/pages/ZhHomePage.vue";
import ZhProductMessage from "./components/pages/ZhProductMessage.vue";
import ZhProductVoice from "./components/pages/ZhProductVoice.vue";
import ZhProductConversation from "./components/pages/ZhProductConversation.vue";
import ZhProductFraudPrevention from "./components/pages/ZhProductFraudPrevention.vue";
import ZhProductGlobalNumber from "./components/pages/ZhProductGlobalNumber.vue";
import ZhProductCustomerEngagement from "./components/pages/ZhProductCustomerEngagement.vue";
import ZhIndustryLogistics from "./components/pages/ZhIndustryLogistics.vue";
import ZhIndustryEcommerce from "./components/pages/ZhIndustryEcommerce.vue";
import ZhIndustryRetail from "./components/pages/ZhIndustryRetail.vue";
import ZhIndustryEducation from "./components/pages/ZhIndustryEducation.vue";
import ZhIndustryGaming from "./components/pages/ZhIndustryGaming.vue";
import ZhIndustryOnlineSocial from "./components/pages/ZhIndustryOnlineSocial.vue";
import ZhIndustryFintech from "./components/pages/ZhIndustryFintech.vue";
import ZhCompanyPage from "./components/pages/ZhCompanyPage.vue";
import ZhDeveloperPage from "./components/pages/ZhDeveloperPage.vue";
import ZhPricingPage from "./components/pages/ZhPricingPage.vue";
import ZhLoginPage from "./components/pages/ZhLoginPage.vue";
import ZhForgetPassword from "./components/pages/ZhForgetPassword.vue";
import ZhPrivacyPolicy from "./components/pages/ZhPrivacyPolicy.vue";
import ZhTermsAndServices from "./components/pages/ZhTermsAndServices.vue";
import ZhSignUp from "./components/pages/ZhSignUp.vue";
// 404 page not found
import ZhPageNotFound from "./components/pages/ZhPageNotFound.vue";



const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
        meta: {
            title: 'Home Page' // Set the title for this route
        }
    },
    {
        name: 'ProductMessage',
        path: '/product-message',
        component: ProductMessage,
        meta: {
            title: 'Product Message' // Set the title for this route
        }
    },
    {
        name: 'ProductVoice',
        path: '/product-voice',
        component: ProductVoice,
        meta: {
            title: 'Product Voice' // Set the title for this route
        }
    },
    {
        name: 'ProductConversation',
        path: '/product-conversation',
        component: ProductConversation,
        meta: {
            title: 'Product Conversation' // Set the title for this route
        }
    },
    {
        name: 'ProductFraudPrevention',
        path: '/product-fraud-prevention',
        component: ProductFraudPrevention,
        meta: {
            title: 'Fraud Prevention' // Set the title for this route
        }
    },
    {
        name: 'ProductGlobalNumber',
        path: '/product-global-number',
        component: ProductGlobalNumber,
        meta: {
            title: 'Global Number' // Set the title for this route
        }
    },
    {
        name: 'ProductCustomerEngagement',
        path: '/product-customer-engagement',
        component: ProductCustomerEngagement,
        meta: {
            title: 'Customer Engagement' // Set the title for this route
        }
    },
    {
        name: 'IndustryLogistics',
        path: '/industry-logistics',
        component: IndustryLogistics,
        meta: {
            title: 'Industry | Logistics' // Set the title for this route
        }
    },
    {
        name: 'IndustryEcommerce',
        path: '/industry-e-commerce',
        component: IndustryEcommerce,
        meta: {
            title: 'Industry | E-commerce' // Set the title for this route
        }
    },
    {
        name: 'IndustryRetail',
        path: '/industry-retail',
        component: IndustryRetail,
        meta: {
            title: 'Industry | Retail' // Set the title for this route
        }
    },
    {
        name: 'IndustryEducation',
        path: '/industry-education',
        component: IndustryEducation,
        meta: {
            title: 'Industry | Education' // Set the title for this route
        }
    },
    {
        name: 'IndustryGaming',
        path: '/industry-gaming',
        component: IndustryGaming,
        meta: {
            title: 'Industry | Gaming' // Set the title for this route
        }
    },
    {
        name: 'IndustryOnlineSocial',
        path: '/industry-online-social',
        component: IndustryOnlineSocial,
        meta: {
            title: 'Industry | Online Social' // Set the title for this route
        }
    },
    {
        name: 'IndustryFintech',
        path: '/industry-fintech',
        component: IndustryFintech,
        meta: {
            title: 'Industry | Fintech' // Set the title for this route
        }
    },
    {
        name: 'CompanyPage',
        path: '/company',
        component: CompanyPage,
        meta: {
            title: 'Company' // Set the title for this route
        }
    },
    {
        name: 'DeveloperPage',
        path: '/developer-page',
        component: DeveloperPage,
        meta: {
            title: 'Developer' // Set the title for this route
        }
    },
    {
        name: 'PricingPage',
        path: '/pricing',
        component: PricingPage,
        meta: {
            title: 'Pricing' // Set the title for this route
        }
    },
    {
        name: 'LoginPage',
        path: '/login',
        component: LoginPage,
        meta: {
            title: 'Login' // Set the title for this route
        }
    },
    {
        name: 'ForgetPassword',
        path: '/forget-password',
        component: ForgetPassword,
        meta: {
            title: 'Forget Password' // Set the title for this route
        }
    },
    {
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
        meta: {
            title: 'Privacy Policy' // Set the title for this route
        }
    },
    {
        name: 'TermsAndServices',
        path: '/terms-services',
        component: TermsAndServices,
        meta: {
            title: 'Service Agreement' // Set the title for this route
        }
    },
    {
        name: 'SignupPage',
        path: '/sign-up',
        component: SignupPage,
        meta: {
            title: 'Sign Up' // Set the title for this route
        }
    },
    {
        name: 'pageNotFound',
        path: '/page-not-found',
        component: PageNotFound,
        meta: {
            title: 'Page not found' // Set the title for this route
        }
    },
    {
        name: 'ZhHomePage',
        path: '/zh',
        component: ZhHomePage,
        meta: {
            title: '首页' // Set the title for this route
        }
    },
    {
        name: 'ZhProductMessage',
        path: '/zh-product-message',
        component: ZhProductMessage,
        meta: {
            title: '产品中心 | 消息' // Set the title for this route
        }
    },
    {
        name: 'ZhProductVoice',
        path: '/zh-product-voice',
        component: ZhProductVoice,
        meta: {
            title: '产品中心 | 语音' // Set the title for this route
        }
    },
    {
        name: 'ZhProductConversation',
        path: '/zh-product-conversation',
        component: ZhProductConversation,
        meta: {
            title: '产品中心 | 互动对话' // Set the title for this route
        }
    },
    {
        name: 'ZhProductFraudPrevention',
        path: '/zh-product-fraud-prevention',
        component: ZhProductFraudPrevention,
        meta: {
            title: '产品中心 | 防欺诈策略' // Set the title for this route
        }
    },
    {
        name: 'ZhProductGlobalNumber',
        path: '/zh-product-global-number',
        component: ZhProductGlobalNumber,
        meta: {
            title: '产品中心 | 全球号码' // Set the title for this route
        }
    },
    {
        name: 'ZhProductCustomerEngagement',
        path: '/zh-product-customer-engagement',
        component: ZhProductCustomerEngagement,
        meta: {
            title: '产品中心 | 客户互动' // Set the title for this route
        }
    },
    {
        name: 'ZhIndustryLogistics',
        path: '/zh-industry-logistics',
        component: ZhIndustryLogistics,
        meta: {
            title: '行业 | 跨境物流' // Set the title for this route
        }
    },
    {
        name: 'ZhIndustryEcommerce',
        path: '/zh-industry-e-commerce',
        component: ZhIndustryEcommerce,
        meta: {
            title: '行业 | 跨境电商' // Set the title for this route
        }
    },
    {
        name: 'ZhIndustryRetail',
        path: '/zh-industry-retail',
        component: ZhIndustryRetail,
        meta: {
            title: '行业 | 实体零售' // Set the title for this route
        }
    },
    {
        name: 'ZhIndustryEducation',
        path: '/zh-industry-education',
        component: ZhIndustryEducation,
        meta: {
            title: '行业 | 在线教育' // Set the title for this route
        }
    },
    {
        name: 'ZhIndustryGaming',
        path: '/zh-industry-gaming',
        component: ZhIndustryGaming,
        meta: {
            title: '行业 | 出海游戏' // Set the title for this route
        }
    },
    {
        name: 'ZhIndustryOnlineSocial',
        path: '/zh-industry-online-social',
        component: ZhIndustryOnlineSocial,
        meta: {
            title: '行业 | 实体零售' // Set the title for this route
        }
    },
    {
        name: 'ZhIndustryFintech',
        path: '/zh-industry-fintech',
        component: ZhIndustryFintech,
        meta: {
            title: '行业 | 高新技术' // Set the title for this route
        }
    },
    {
        name: 'ZhCompanyPage',
        path: '/zh-company',
        component: ZhCompanyPage,
        meta: {
            title: '关于我们' // Set the title for this route
        }
    },
    {
        name: 'ZhDeveloperPage',
        path: '/zh-developer-page',
        component: ZhDeveloperPage,
        meta: {
            title: '开发文档' // Set the title for this route
        }
    },
    {
        name: 'ZhPricingPage',
        path: '/zh-pricing',
        component: ZhPricingPage,
        meta: {
            title: '价钱' // Set the title for this route
        }
    },
    {
        name: 'ZhLoginPage',
        path: '/zh-login',
        component: ZhLoginPage,
        meta: {
            title: '新闻动态' // Set the title for this route
        }
    },
    {
        name: 'ZhForgetPassword',
        path: '/zh-forget-password',
        component: ZhForgetPassword,
        meta: {
            title: '忘记密码' // Set the title for this route
        }
    },
    {
        name: 'ZhPrivacyPolicy',
        path: '/zh-privacy-policy',
        component: ZhPrivacyPolicy,
        meta: {
            title: '隐私政策' // Set the title for this route
        }
    },
    {
        name: 'ZhTermsAndServices',
        path: '/zh-terms-services',
        component: ZhTermsAndServices,
        meta: {
            title: '服务协议' // Set the title for this route
        }
    },
    {
        name: 'ZhSignUp',
        path: '/zh-sign-up',
        component: ZhSignUp,
        meta: {
            title: '登录' // Set the title for this route
        }
    },
    {
        name: 'zhPageNotFound',
        path: '/zh-page-not-found',
        component: ZhPageNotFound,
        meta: {
            title: '找不到网页' // Set the title for this route
        }
    },

]


const router = createRouter({
    history: createWebHistory(),
    routes
});


export default router;