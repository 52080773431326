<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="home_slider swiperSlider">
              <swiper :modules="modules" :slides-per-view="1" :space-between="50"
                :autoplay="{ delay: 5000, disableOnInteraction: false }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-7 d-flex">
                      <div class="inner">
                        <h1 class="hdng">
                          互联全球的国际 <br />
                          云通信平台
                        </h1>
                        <p class="cntnt">
                          携手1000+合作伙伴运营商，<br />帮助企业链接全球客户，更好、更快地走向世界。
                        </p>
                        <router-link to="/zh-sign-up" class="cmn_btn light">
                          立即体验
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <img src="../../assets/images/kv_visual_01.png" alt="" />
                    </div>
                  </div>
                </swiper-slide>
                <!-- slide 2 -->
                <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-7 d-flex align-items-center">
                      <div class="inner">
                        <h1 class="hdng">
                          借助越南TOP即时通信 <br />应用Zalo高效拓展越南
                          <br />市场
                        </h1>
                        <p class="cntnt">
                          牛信云是中国首家Zalo官方认证的商业解决方案提供
                          <br />商，通过 ZNS API，可为出海企业提供Zalo
                          通知服务，<br />精准触达越南7400万用户。
                        </p>
                        <router-link to="/zh-sign-up" class="cmn_btn light">
                          立即体验
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <img src="../../assets/images/home-slide6.png" alt="" />
                    </div>
                  </div>
                </swiper-slide>
                <!-- slide 3 -->
                <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-7 d-flex align-items-center">
                      <div class="inner">
                        <h1 class="hdng">
                          借助全球Top社媒 <br />WhatsApp Business，<br />高效营销拓客
                        </h1>
                        <p class="cntnt">
                          牛信云是WhatsApp首批Select等级的官方商业解决方案
                          <br />提供商，一键接入，直连全球25亿用户。
                        </p>
                        <router-link to="/zh-sign-up" class="cmn_btn light">
                          立即体验
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <img src="../../assets/images/home-slide5.png" alt="" />
                    </div>
                  </div>
                </swiper-slide>
                <!-- slide 4 -->
                <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-7 d-flex align-items-center">
                      <div class="inner">
                        <h1 class="hdng">
                          牛信云是国内首批 <br />Viber官方的商务消息传递合作伙伴
                        </h1>
                        <p class="cntnt">
                          (Official Business Messaging Partner of Viber
                          Business)
                        </p>
                        <p class="cntnt">助力企业品牌营销与增长</p>
                        <router-link to="/zh-sign-up" class="cmn_btn light">
                          立即体验
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <img src="../../assets/images/home-slide4.png" alt="" />
                    </div>
                  </div>
                </swiper-slide>
                <!-- slide 5 -->
                <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-7 d-flex align-items-center">
                      <div class="inner">
                        <img class="mb-2" src="../../assets/images/brand1.svg" alt="brand" />
                        <h1 class="hdng">
                          聚焦精品独立站 <br />新用户注册|免费试用7天
                        </h1>
                        <p class="cntnt">
                          SHOPYY独立站建站平台，为卖家提供稳定、便捷、专业
                          <br />的系统支撑和全方位的服务支持。助力跨境卖家品牌出海。
                        </p>
                        <a href="https://account.shopyy.com/register?ref=marketingteam&ref_channel=137"
                          class="cmn_btn light" target="_blank">
                          立即体验
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <img src="../../assets/images/home-slide3.png" alt="" />
                    </div>
                  </div>
                </swiper-slide>
                <!-- slide 6 -->
                <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-7 d-flex align-items-center">
                      <div class="inner">
                        <img class="mb-2" src="../../assets/images/brand2.svg" alt="brand" />
                        <h1 class="hdng">
                          全球用户首选的TikTok <br />电商数据洞察平台
                        </h1>
                        <p class="cntnt">
                          爆品挖掘、达人建联、视频创意、直播设计、广告优化、<br />竞对分析
                        </p>
                        <a href="https://www.kalodata.com/signup?tc=nxcloud1" class="cmn_btn light" target="_blank">
                          立即体验
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <img src="../../assets/images/home-slide2.png" alt="" />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="customer-section desktop">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">
              为全球用户提供更优质 <br />
              的服务体验
            </h3>
          </div>
        </div>

        <div class="row customerBoxes">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 cs_flex">
            <div class="icon_inner inner shift_right">
              <img src="../../assets/images/icon_01.svg" alt="" class="icon" />
              <h4 class="subHdng">消息</h4>
              <p class="desc">
                通过直连通道向海外用户发送信息，实现企业与全球用户的信息快速触达。
              </p>
              <router-link to="/zh-product-message">
                了解更多
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner">
              <img src="../../assets/images/icon_02.svg" alt="" class="icon" />
              <h4 class="subHdng">语音</h4>
              <p class="desc">通过语音电话的方式，向全球客户传递信息。</p>
              <router-link to="/zh-product-voice">
                了解更多
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner shift_right">
              <img src="../../assets/images/conversation.svg" alt="" class="icon" />
              <h4 class="subHdng">互动对话</h4>
              <p class="desc">随时随地进行安全不间断的双向对话沟通。</p>
              <router-link to="/zh-product-conversation">
                了解更多
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="inner d-flex justify-content-center mt0">
              <img src="../../assets/images/customer.png" alt="" class="heroImg" />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 cs_flex">
            <div class="icon_inner inner shift_left">
              <img src="../../assets/images/icon_04.svg" alt="" class="icon" />
              <h4 class="subHdng">防欺诈策略</h4>
              <p class="desc">防止恶意商业行为，保护客户帐号和信息安全。</p>
              <router-link to="/zh-product-fraud-prevention">
                了解更多
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner">
              <img src="../../assets/images/icon_05.svg" alt="" class="icon" />
              <h4 class="subHdng">全球号码</h4>
              <p class="desc">帮助企业用更低的成本构建全球通信网络。</p>
              <router-link to="/zh-product-global-number">
                了解更多
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner shift_left">
              <img src="../../assets/images/icon_06.svg" alt="" class="icon" />
              <h4 class="subHdng">客户互动</h4>
              <p class="desc">通过牛信云平台，提供一站式客户服务支持。</p>
              <router-link to="/zh-product-customer-engagement">
                了解更多
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="customer-section mobile">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">
              为全球用户提供更优质 <br />
              的服务体验
            </h3>
          </div>
        </div>

        <div class="row customerBoxes">
          <div class="col-12 cs_flex">
            <div class="customerSlider swiperSlider numberNav">
              <swiper :modules="modules" :slides-per-view="1" :autoplay="{ delay: 5000, disableOnInteraction: false }"
                :loop="true" navigation :pagination="{ clickable: true }" @swiper="customerSliderSwiper"
                @slideChange="customerSliderSlideChange">
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_right">
                    <img src="../../assets/images/icon_01.svg" alt="" class="icon" />
                    <h4 class="subHdng">消息</h4>
                    <p class="desc">
                      通过直连通道向海外用户发送信息，实现企业与全球用户的信息快速触达。
                    </p>
                    <router-link to="/zh-product-message">
                      了解更多
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner">
                    <img src="../../assets/images/icon_02.svg" alt="" class="icon" />
                    <h4 class="subHdng">语音</h4>
                    <p class="desc">通过语音电话的方式，向全球客户传递信息。</p>
                    <router-link to="/zh-product-voice">
                      了解更多
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_right">
                    <img src="../../assets/images/conversation.svg" alt="" class="icon" />
                    <h4 class="subHdng">互动对话</h4>
                    <p class="desc">随时随地进行安全不间断的双向对话沟通。</p>
                    <router-link to="/zh-product-conversation">
                      了解更多
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_left">
                    <img src="../../assets/images/icon_04.svg" alt="" class="icon" />
                    <h4 class="subHdng">防欺诈策略</h4>
                    <p class="desc">防止恶意商业行为，保护客户帐号和信息安全。</p>
                    <router-link to="/zh-product-fraud-prevention">
                      了解更多
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner">
                    <img src="../../assets/images/icon_05.svg" alt="" class="icon" />
                    <h4 class="subHdng">全球号码</h4>
                    <p class="desc">帮助企业用更低的成本构建全球通信网络。</p>
                    <router-link to="/zh-product-global-number">
                      了解更多
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_left">
                    <img src="../../assets/images/icon_06.svg" alt="" class="icon" />
                    <h4 class="subHdng">客户互动</h4>
                    <p class="desc">通过牛信云平台，提供一站式客户服务支持。</p>
                    <router-link to="/zh-product-customer-engagement">
                      了解更多
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#000000"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <span class="count">{{ currentSlide }} / {{ slideLength }}</span>
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="inner d-flex justify-content-center mt0">
              <img src="../../assets/images/customer.png" alt="" class="heroImg" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-section">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">行业合作伙伴</h3>
          </div>
          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap">
              <img src="../../assets/images/gsma_white.svg" class="img-fluid" alt="gsma" />
              <h6 class="archived-icons-desc">
                GSMA <br />
                企业会员单位
              </h6>
            </div>
          </div>
          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap">
              <img src="../../assets/images/whatsapp_white.svg" class="img-fluid" alt="whatsap" />
              <h6 class="archived-icons-desc">
                WhatsApp <br />
                官方商业解决 <br />
                方案提供商
              </h6>
            </div>
          </div>
          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap last-archived-details">
              <img src="../../assets/images/viber_white.svg" class="img-fluid" alt="call" />
              <h6 class="archived-icons-desc">
                Viber <br />
                官方的商务消息传 <br />
                递合作伙伴
              </h6>
            </div>
          </div>

          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap last-archived-details">
              <img src="../../assets/images/zalo_white.svg" class="img-fluid" alt="call" />
              <h6 class="archived-icons-desc">
                Zalo <br />
                官方商业合作伙伴
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section desktop">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">NXCLOUD Reviews</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img src="../../assets/images/coffee_order.svg" class="img-fluid" alt="card" />
                  <h4 class="archived-card-hdng">
                    订单转化率暴涨 <br />
                    300%
                  </h4>
                  <p class="archived-card-desc">
                    一家知名的国际咖啡连锁店。经过多年耕耘，其商业版图已扩展到了东南亚多个国家，现有近千家门店。为降低运营成本、提高订单转化，他们决定尝试其他促销渠道——开辟
                    WhatsApp 销售战线。
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">2倍</h5>
                      <p class="card-number-desc">客户留存率提高了</p>
                    </div>

                    <div class="archived-card-number">
                      <!-- <h5 class="card-number-hdng">300</h5>
                      <p class="card-number-desc">客户满意度度提升</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img src="../../assets/images/customer_satisfaction.svg" class="img-fluid" alt="slack" />
                  <h4 class="archived-card-hdng">客户满意度提升 <br />54%</h4>
                  <p class="archived-card-desc">
                    知名汽车品牌，产品远销中东、北非、欧洲等国际市场。接入牛信云SaaS系统NXLink，对海外客户进行一站式管理，并建立完善的客户服务流程，包括客户咨询、投诉、售后服务等各个环节，确保及时响应客户需求、有效解决问题。
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">33%</h5>
                      <p class="card-number-desc">销售量增长</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img src="../../assets/images/sms_delivery.svg" class="img-fluid" alt="hsbc" />
                  <h4 class="archived-card-hdng">短信到达率高达 <br />95.2%</h4>
                  <p class="archived-card-desc">
                    全球知名游戏平台，迄今累计发行超40款精品休闲游戏，已出海至全球多个国家。在用户登录、修改密码等环节，通过短信和语音验证码核验用户身份，以防脱机外挂、扫号器扫描账号等恶意行为，有效保护平台用户账号安全。
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">21%</h5>
                      <p class="card-number-desc">运营成本降低</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section mobile">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">NXCLOUD Reviews</h3>
          </div>

          <div class="otherSayAboutUs slider_equal_height_default_pagination swiperSlider">
            <swiper :modules="modules" :slides-per-view="1" :space-between="20"
              :autoplay="{ delay: 5000, disableOnInteraction: true }" :loop="true" navigation
              :pagination="{ clickable: true }" :equal-height="false" @swiper="otherSayAboutUsSwiper"
              @slideChange="otherSayAboutUsSlideChange">
              <swiper-slide class="item">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="archived-card-wrap">
                        <img src="../../assets/images/coffee_order.svg" class="img-fluid" alt="card" />
                        <h4 class="archived-card-hdng">
                          订单转化率暴涨 <br />
                          300%
                        </h4>
                        <p class="archived-card-desc">
                          一家知名的国际咖啡连锁店。经过多年耕耘，其商业版图已扩展到了东南亚多个国家，现有近千家门店。为降低运营成本、提高订单转化，他们决定尝试其他促销渠道——开辟
                          WhatsApp 销售战线。
                        </p>

                        <div class="archived-card-numbering-wrap">
                          <div class="archived-card-number">
                            <h5 class="card-number-hdng">2倍</h5>
                            <p class="card-number-desc">客户留存率提高了</p>
                          </div>

                          <div class="archived-card-number">
                            <!-- <h5 class="card-number-hdng">300</h5>
                      <p class="card-number-desc">客户满意度度提升</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="archived-card-wrap">
                        <img src="../../assets/images/customer_satisfaction.svg" class="img-fluid" alt="slack" />
                        <h4 class="archived-card-hdng">客户满意度提升 <br />54%</h4>
                        <p class="archived-card-desc">
                          知名汽车品牌，产品远销中东、北非、欧洲等国际市场。接入牛信云SaaS系统NXLink，对海外客户进行一站式管理，并建立完善的客户服务流程，包括客户咨询、投诉、售后服务等各个环节，确保及时响应客户需求、有效解决问题。
                        </p>

                        <div class="archived-card-numbering-wrap">
                          <div class="archived-card-number">
                            <h5 class="card-number-hdng">33%</h5>
                            <p class="card-number-desc">销售量增长</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="archived-card-wrap">
                        <img src="../../assets/images/sms_delivery.svg" class="img-fluid" alt="hsbc" />
                        <h4 class="archived-card-hdng">短信到达率高达 <br />95.2%</h4>
                        <p class="archived-card-desc">
                          全球知名游戏平台，迄今累计发行超40款精品休闲游戏，已出海至全球多个国家。在用户登录、修改密码等环节，通过短信和语音验证码核验用户身份，以防脱机外挂、扫号器扫描账号等恶意行为，有效保护平台用户账号安全。
                        </p>

                        <div class="archived-card-numbering-wrap">
                          <div class="archived-card-number">
                            <h5 class="card-number-hdng">21%</h5>
                            <p class="card-number-desc">运营成本降低</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="global-services-section">
      <div class="container">
        <div class="row row-global-services">
          <div class="col-md-5">
            <div class="global-services-figure">
              <img src="../../assets/images/Key-visual-2.png" class="img-fluid" alt="Key-visual-2" />
            </div>
          </div>

          <div class="col-md-7">
            <div class="global-services-wrap">
              <h3 class="archived-hdng Providing-global-hdng">
                将您的业务推向全球，<br />
                覆盖全球185+国家和地区
              </h3>

              <div class="archived-card-numbering-wrap">
                <div class="archived-card-number global-card-service">
                  <h5 class="card-number-hdng">35 亿+</h5>
                  <p class="card-number-desc global-services-desc">
                    <strong> 年消息收发总量 </strong>
                  </p>
                </div>

                <div class="archived-card-number global-card-service">
                  <h5 class="card-number-hdng">8 亿+</h5>
                  <p class="card-number-desc global-services-desc">
                    <strong> 年通话分钟总数 </strong>
                  </p>
                </div>
                <div class="archived-card-number global-card-service">
                  <h5 class="card-number-hdng">5000+</h5>
                  <p class="card-number-desc global-services-desc">
                    <strong> 服务客户</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { ref } from "vue";

// Import Swiper styles
export default {
  name: "HomePage",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const currentSlide = ref(1); // Initialize currentSlide with initial value 1
    const slideLength = ref(1); // Initialize slideLength with initial value 1
    const homeBannerSwiper = () => { };
    const onHomeBannerSlideChange = () => { };

    // For Number pagination
    const customerSliderSwiper = (swiper) => {
      slideLength.value = swiper.slides.length;
      swiper.on("slideChange", () => {
        currentSlide.value = swiper.realIndex + 1;
      });
    };

    const customerSliderSlideChange = () => { };

    const otherSayAboutUsSwiper = () => { };
    const otherSayAboutUsSlideChange = () => { };
    return {
      currentSlide,
      slideLength,
      homeBannerSwiper,
      onHomeBannerSlideChange,
      customerSliderSwiper,
      customerSliderSlideChange,
      otherSayAboutUsSwiper,
      otherSayAboutUsSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  methods: {
    leftSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const prevButton = currSlider.querySelector(".swiper-button-prev");
      if (prevButton) {
        prevButton.click();
      } else {
        console.error("Previous button not found");
      }
    },
    rightSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const nextButton = currSlider.querySelector(".swiper-button-next");
      if (nextButton) {
        nextButton.click();
      } else {
        console.error("Next button not found");
      }
    },
    moveSliderDefaultPagination() {
      const all_slider_equal_height_default_pagination =
        document.querySelectorAll(".slider_equal_height_default_pagination");
      console.log(all_slider_equal_height_default_pagination);
      all_slider_equal_height_default_pagination.forEach((slider) => {
        const pagination = slider.querySelector(".swiper-pagination");
        slider.appendChild(pagination);
      });
    },
  },
  mounted() {
    this.moveSliderDefaultPagination();
  },
};
</script>

<style>
.home_slider {
  position: relative;
}

/* Targeting swiper navigation buttons */
.home_slider .swiper-button-prev,
.home_slider .swiper-button-next {
  display: none !important;
}

.home_slider .swiper-pagination {
  position: relative;
  width: auto;
  background: #fff;
  border-radius: 30px;
  padding: 8px 10px;
  display: inline-flex;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.home_slider .swiper-pagination .swiper-pagination-bullet {
  position: relative;
  width: 10px;
  height: 10px;
  background: #9dc93c !important;
  opacity: 1;
}

.home_slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 10px;
}

.home_slider .custom_buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_slider .custom_buttons button {
  position: relative;
  background: transparent;
  border: none;
  width: 60px;
  height: 60px;
}

.home_slider .custom_buttons button.prev {
  left: -100px;
}

.home_slider .custom_buttons button.next {
  right: -100px;
  transform: rotate(180deg);
}

@media (max-width: 991.98px) {
  .home_slider .custom_buttons {
    display: none;
  }
}
</style>
