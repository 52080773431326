<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row row_sign_up main_sign_up">
              <div class="col-12 col-xl-6 col-md-12 d-flex col-sign-up">
                <div class="inner">
                  <h1 class="hdng lg_size">立即注册</h1>
                  <p class="cntnt"></p>
                </div>

                <section class="contact_sec">
                  <div class="form_wrap">
                    <form id="contactForm">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6">
                          <div class="form_field">
                            <label for="email"
                              >公司电子邮件 <span class="required">*</span>
                            </label>
                            <input
                              type="text"
                              class="form_control"
                              name="email"
                              id="email"
                              placeholder="例如：anthony@gmail.com"
                              v-model="signUpFormData.formData.email"
                              @input="checkUserEmail"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorEmail"
                              class="errorInput"
                            >
                              Please Enter Company Email
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6">
                          <div class="form_field">
                            <label>国家 <span class="required">*</span></label>
                            <input
                              class="form_control"
                              name="countryName"
                              type="hidden"
                              v-model="signUpFormData.formData.countryId"
                            />

                            <div class="ap_country_list">
                              <span @click="openCountryDD">{{
                                selectCountryText
                              }}</span>
                              <div
                                class="countryDD"
                                :class="{ show: isActive }"
                              >
                                <div
                                  class="c_name"
                                  v-for="country in countries"
                                  :key="country.code"
                                  @click="
                                    checkUserCountryId(
                                      country.code,
                                      country.s_name
                                    )
                                  "
                                >
                                  <span
                                    :class="
                                      'flag-icon flag-icon-' +
                                      (country.iso_2
                                        ? country.iso_2.toLowerCase()
                                        : '')
                                    "
                                  ></span>
                                  <span>
                                    {{ country.e_name }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              v-if="!signUpFormData.errors.showErrorCountry"
                              class="errorInput"
                            >
                              Please Select Country
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label>名字 <span class="required">*</span></label>
                            <input
                              type="text"
                              class="form_control"
                              placeholder="名字 "
                              v-model="signUpFormData.formData.firstName"
                              @input="checkUserFName"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorFirstName"
                              class="errorInput"
                            >
                              Please Enter First Name
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label>姓氏 <span class="required">*</span></label>
                            <input
                              type="text"
                              class="form_control"
                              placeholder="姓氏"
                              v-model="signUpFormData.formData.lastName"
                              @input="checkUserLName"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorLastName"
                              class="errorInput"
                            >
                              Please Enter Last Name
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form_field">
                            <label>公司名称</label>
                            <input
                              type="text"
                              class="form_control"
                              placeholder="例如: NXCLOUD"
                              v-model="signUpFormData.formData.companyAddress"
                              @input="checkUserCompAdr"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorCompanyWeb"
                              class="errorInput"
                            >
                              Please Enter Company Address
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form_field">
                            <label
                              for="check_privacy_policy"
                              class="checkbox_wrap"
                            >
                              <input
                                type="checkbox"
                                name="check_privacy_policy"
                                id="check_privacy_policy"
                                value="accepted"
                                v-model="termsCheck"
                              />
                              <span class="check_text">
                                我确认并同意遵守 NXCLOUD
                                <router-link to="/zh-privacy-policy">
                                  的隐私政策
                                </router-link>
                                和
                                <router-link to="/zh-terms-services">
                                  服务协议.
                                </router-link>
                              </span>
                            </label>
                            <div
                              v-if="!signUpFormData.errors.showErrorTermsCheck"
                              class="errorInput"
                            >
                              Please Check this Field
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="submit_wrap">
                            <div class="submitWrapper signUp">
                              <input
                                type="submit"
                                value="注册"
                                @click="submitSubLoginForm"
                              />
                              <!-- Loader -->
                              <div
                                v-if="signUpFormData.errors.isLoginSubmit"
                                class="loader"
                              >
                                <div class="tm-ring">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="signUpFormData.errors.messageAfterSubmit"
                              :class="{
                                error: signUpFormData.errors.messageAfterSubmit,
                              }"
                              class="messageAfterSubmit"
                            >
                              {{ signUpFormData.errors.messageAfterSubmit }}
                            </div>
                            <p class="info">
                              已经是现有用户？
                              <router-link to="/zh-login">
                                <u>在此登录</u>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>

              <div class="col-12 col-xl-6 col-md-12 mt-4 mt-md-0">
                <img src="../../assets/images/register_banner_2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import "flag-icon-css/css/flag-icons.css";
export default {
  name: "ZhSignUp",
  data() {
    return {
      termsCheck: [],
      countries: [],
      isActive: false,
      selectCountryText: "选择国家",
      signUpFormData: {
        errors: {
          showErrorEmail: true,
          showErrorCountry: true,
          showErrorFirstName: true,
          showErrorLastName: true,
          showErrorCompanyWeb: true,
          showErrorTermsCheck: true,
          isSignUpSubmit: false,
          messageAfterSubmit: "",
        },
        formData: {
          email: "",
          firstName: "",
          lastName: "",
          countryId: "",
          companyAddress: "",
        },
      },
    };
  },
  mounted() {
    axios
      .post(
        "https://testadmin.nxcloud.com:10001/user/basecode/getAllCountryList",
        {
          headers: {
            "Content-Language": "EN",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.countries = response.data.row;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    checkUserEmail() {
      if (!this.signUpFormData.formData.email.trim()) {
        this.signUpFormData.errors.showErrorEmail = false;
      } else {
        this.signUpFormData.errors.showErrorEmail = true;
      }
    },
    checkUserCountryId(code, name) {
      this.isActive = !this.isActive;
      this.signUpFormData.formData.countryId = code;
      this.selectCountryText = name;
      if (!this.signUpFormData.formData.countryId.trim()) {
        this.signUpFormData.errors.showErrorCountry = false;
      } else {
        this.signUpFormData.errors.showErrorCountry = true;
      }
    },
    checkUserFName() {
      if (!this.signUpFormData.formData.firstName.trim()) {
        this.signUpFormData.errors.showErrorFirstName = false;
      } else {
        this.signUpFormData.errors.showErrorFirstName = true;
      }
    },
    checkUserLName() {
      if (!this.signUpFormData.formData.lastName.trim()) {
        this.signUpFormData.errors.showErrorLastName = false;
      } else {
        this.signUpFormData.errors.showErrorLastName = true;
      }
    },
    checkUserCompAdr() {
      if (!this.signUpFormData.formData.companyAddress.trim()) {
        this.signUpFormData.errors.showErrorCompanyWeb = false;
      } else {
        this.signUpFormData.errors.showErrorCompanyWeb = true;
      }
    },
    submitSubLoginForm(event) {
      event.preventDefault();

      //  check if fields are empty
      if (!this.signUpFormData.formData.email.trim()) {
        this.signUpFormData.errors.showErrorEmail = false;
      }
      if (!this.signUpFormData.formData.countryId.trim()) {
        this.signUpFormData.errors.showErrorCountry = false;
      }
      if (!this.signUpFormData.formData.firstName.trim()) {
        this.signUpFormData.errors.showErrorFirstName = false;
      }
      if (!this.signUpFormData.formData.lastName.trim()) {
        this.signUpFormData.errors.showErrorLastName = false;
      }
      if (!this.signUpFormData.formData.companyAddress.trim()) {
        this.signUpFormData.errors.showErrorCompanyWeb = false;
        return;
      }
      if (!this.termsCheck[0]) {
        this.signUpFormData.errors.showErrorTermsCheck = false;
        return;
      }

      this.signUpFormData.errors.isLoginSubmit = true;

      // if(this.userFormData.formData.account != "" && this.userFormData.formData.password != ""){
      axios
        .post(
          "https://testdid.nxcloud.com/register/sendEmail",
          this.signUpFormData.formData,
          {
            headers: {
              "Content-Language": "EN",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.signUpFormData.errors.showErrorTermsCheck = true;
          this.signUpFormData.errors.isLoginSubmit = false;
          this.signUpFormData.errors.messageAfterSubmit = response.data.msg;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openCountryDD() {
      console.log("dsfds");
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style>
.ap_country_list {
  position: relative;
}

.ap_country_list > span {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background-color: var(--white-color);
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
  display: none;
  overflow-y: scroll;
}

.ap_country_list .countryDD.show {
  display: block;
}

.ap_country_list {
  position: relative;
}

.ap_country_list > span {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background: #fff;
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
}

.ap_country_list .countryDD .c_name {
  background: #fff;
  padding: 3px 15px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
}

.ap_country_list .countryDD .c_name:hover {
  background: #e9e9e9;
}

.ap_country_list .countryDD .c_name .flag-icon {
  margin-right: 15px;
}
.ap_country_list::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: transparent;
  z-index: 9;
  transform: rotate(45deg) translateY(-50%);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  right: 20px;
  top: 40%;
  pointer-events: none;
}
</style>
