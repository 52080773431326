<template>
    <section class="error-404 not-found">
        <div class="container">
            <div class="box">
                <div class="inner">
                    <h1>404</h1>
                    <h2>Oops! Page not found</h2>
                    <p>Sorry, the page you're looking for doesn't exist.</p>
                </div>
                <div class="btn-wrapper">
                    <router-link to="/" class="btn">
                        Go to Home
                    </router-link>
                </div>
            </div>
        </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success"
                >Get Started</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
    export default {
        name: "pageNotFound"
    }
</script>