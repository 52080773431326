<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">
                    全球云通信服 <br />
                    务创新者
                  </h1>

                  <p class="cntnt">在新加坡立足，全球扩张</p>
                  <!-- <a
                    href="./contact-page.html"
                    class="cmn_btn light"
                  >
                    Start now
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>  -->
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img src="../../assets/images/company_banner.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- company_desc -->
    <section class="company_desc pb_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>公司简介</h2>
          <p>
            牛信云是一家以技术驱动为核心的云通信公司，于2018年在新加坡成立，致力于通过短信、语音、DID号码、WhatsApp、Viber等通信与社媒产品，为企业提供覆盖用户全生命周期的身份验证、营销推广、客服对话、消息触达等一站式通信服务，帮助企业实现全球用户链接与营销增长。目前，牛信云全球总部坐落在新加坡，并在中国、马来西亚、印尼、美国等国家设立有分公司，旨在帮助更多企业走向世界！
          </p>
        </div>
        <div class="content_2 desktop">
          <h3>核心价值观</h3>
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>真 诚</h3>
                <div class="details">
                  <p>
                    简单正心，坦诚相待。人心归于正，坦诚、直接，公正无私，以达成目标为出发点，
                    有问题带着解决方案直接沟通，对事不对人
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Equal</a>
                    </li>
                    <li>
                      <a href="#">Fair</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Simple</a>
                    </li>
                    <li>
                      <a href="#">Reliable</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>专 业</h3>
                <div class="details">
                  <p>
                    独当一面，客户第一。术业有专攻，持续学习，专注于专业的修炼与精进；始终将客户放在首位，以客户利益为出发点，为客户提供持久的更优价值
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Customer focus</a>
                    </li>
                    <li>
                      <a href="#">Professional</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Attitude</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>坚 守</h3>
                <div class="details">
                  <p>
                    不忘初心，砥砺前行。看长远，走正道，敢于拼搏，坚韧抗压，自我驱动，迎难而上，具有百折不挠的精神
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Consistent</a>
                    </li>
                    <li>
                      <a href="#">Resilent</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Entrepreneurial</a>
                    </li>
                    <li>
                      <a href="#">Long-term perspective</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>协 作</h3>
                <div class="details">
                  <p>
                    相互信任，合作共赢。与关联人员建立并运用共同目标及协作规则；正确看待自身能力、理解并承认个体差异、相互包容、欣赏团队成员优点，优势互补形成团队合力；彼此信任，既坚持原则又善于妥协；创造或积极融入合作、双赢的工作氛围
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Common goals</a>
                    </li>
                    <li>
                      <a href="#">Trust</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Empathetic</a>
                    </li>
                    <li>
                      <a href="#">Cross functional</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>协 作</h3>
                <div class="details">
                  <p>
                    认真负责，实干担当。具备主人翁精神，做了再说，错了即改，踏实工作，敢于担当，追求成功，永不言败
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Practical</a>
                    </li>
                    <li>
                      <a href="#">Responsible</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Ownership</a>
                    </li>
                    <li>
                      <a href="#">Reflection</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>创 新</h3>
                <div class="details">
                  <p>
                    创新驱动，追求卓越。拒绝闭门造车、固步自封，要保持对新事物的好奇心，不断通过创新产品、服务、管理模式等，为企业带来价值
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Inspiring</a>
                    </li>
                    <li>
                      <a href="#">Open</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Innovative</a>
                    </li>
                    <li>
                      <a href="#">Sharing</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content_2 mobile">
          <h3>核心价值观</h3>
          <div class="row">
            <div class="coreValues swiperSlider numberNav slider_equal_height">
              <swiper :modules="modules" :slides-per-view="1" :space-between="20" :equal-height="true"
                :autoplay="{ delay: 5000, disableOnInteraction: true }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="coreValuesSliderSwiper"
                @slideChange="coreValuesSliderSlideChange">
                <swiper-slide class="item">
                  <div class="col-md-12">
                    <div class="company_profile_card">
                      <h3>真 诚</h3>
                      <div class="details">
                        <p>
                          简单正心，坦诚相待。人心归于正，坦诚、直接，公正无私，以达成目标为出发点，
                          有问题带着解决方案直接沟通，对事不对人
                        </p>

                        <ul class="tags">
                          <li>
                            <a href="#">Equal</a>
                          </li>
                          <li>
                            <a href="#">Fair</a>
                          </li>
                          <span class="empty_break"></span>
                          <li>
                            <a href="#">Simple</a>
                          </li>
                          <li>
                            <a href="#">Reliable</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-md-12">
                    <div class="company_profile_card">
                      <h3>专 业</h3>
                      <div class="details">
                        <p>
                          独当一面，客户第一。术业有专攻，持续学习，专注于专业的修炼与精进；始终将客户放在首位，以客户利益为出发点，为客户提供持久的更优价值
                        </p>

                        <ul class="tags">
                          <li>
                            <a href="#">Customer focus</a>
                          </li>
                          <li>
                            <a href="#">Professional</a>
                          </li>
                          <span class="empty_break"></span>
                          <li>
                            <a href="#">Attitude</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-md-12">
                    <div class="company_profile_card">
                      <h3>坚 守</h3>
                      <div class="details">
                        <p>
                          不忘初心，砥砺前行。看长远，走正道，敢于拼搏，坚韧抗压，自我驱动，迎难而上，具有百折不挠的精神
                        </p>

                        <ul class="tags">
                          <li>
                            <a href="#">Consistent</a>
                          </li>
                          <li>
                            <a href="#">Resilent</a>
                          </li>
                          <span class="empty_break"></span>
                          <li>
                            <a href="#">Entrepreneurial</a>
                          </li>
                          <li>
                            <a href="#">Long-term perspective</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-md-12">
                    <div class="company_profile_card">
                      <h3>协 作</h3>
                      <div class="details">
                        <p>
                          相互信任，合作共赢。与关联人员建立并运用共同目标及协作规则；正确看待自身能力、理解并承认个体差异、相互包容、欣赏团队成员优点，优势互补形成团队合力；彼此信任，既坚持原则又善于妥协；创造或积极融入合作、双赢的工作氛围
                        </p>

                        <ul class="tags">
                          <li>
                            <a href="#">Common goals</a>
                          </li>
                          <li>
                            <a href="#">Trust</a>
                          </li>
                          <span class="empty_break"></span>
                          <li>
                            <a href="#">Empathetic</a>
                          </li>
                          <li>
                            <a href="#">Cross functional</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-md-12">
                    <div class="company_profile_card">
                      <h3>协 作</h3>
                      <div class="details">
                        <p>
                          认真负责，实干担当。具备主人翁精神，做了再说，错了即改，踏实工作，敢于担当，追求成功，永不言败
                        </p>

                        <ul class="tags">
                          <li>
                            <a href="#">Practical</a>
                          </li>
                          <li>
                            <a href="#">Responsible</a>
                          </li>
                          <span class="empty_break"></span>
                          <li>
                            <a href="#">Ownership</a>
                          </li>
                          <li>
                            <a href="#">Reflection</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-md-12">
                    <div class="company_profile_card">
                      <h3>创 新</h3>
                      <div class="details">
                        <p>
                          创新驱动，追求卓越。拒绝闭门造车、固步自封，要保持对新事物的好奇心，不断通过创新产品、服务、管理模式等，为企业带来价值
                        </p>

                        <ul class="tags">
                          <li>
                            <a href="#">Inspiring</a>
                          </li>
                          <li>
                            <a href="#">Open</a>
                          </li>
                          <span class="empty_break"></span>
                          <li>
                            <a href="#">Innovative</a>
                          </li>
                          <li>
                            <a href="#">Sharing</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <span class="count">{{ currentSlide }} / {{ slideLength }}</span>
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="content hide_temp">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="box">
                <div class="img_wrap">
                  <img src="../../assets/images/vision.svg" alt="corporate_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Vision</h3>
                    <p>
                      We relentlessly champion innovation to to lead and
                      redefine the forefront of the global communication
                      industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="box">
                <div class="img_wrap">
                  <img src="../../assets/images/mission.svg" alt="corporate_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Mission</h3>
                    <p>
                      Empower businesses worldwide with transformative solutions
                      to revolutionize global markets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="box">
                <div class="img_wrap">
                  <img src="../../assets/images/goal.svg" alt="corporate_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Goal</h3>
                    <p>
                      Optimize and unify communication resources to drive
                      unparalleled global expansion for businesses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recognised_awards -->
    <section class="recognised_awards pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Recognised awards/achievements</h2>
        </div>
        <div class="content">
          <ul class="logos_wrap">
            <li>
              <a href="#">
                <img src="../../assets/images/award_logo.png" alt="award_logo" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../../assets/images/award_logo.png" alt="award_logo" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../../assets/images/award_logo.png" alt="award_logo" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../../assets/images/award_logo.png" alt="award_logo" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../../assets/images/award_logo.png" alt="award_logo" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- Worldwide pressence -->
    <section class="worldwide_sec pb_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Worldwide Presence</h2>
        </div>
        <div class="content">
          <div class="map_area">
            <div class="map_img_wrap">
              <img src="../../assets/images/map_1.png" alt="map" />
            </div>

            <div class="locations_wrap">
              <ul class="locations">
                <li v-for="(location, index) in locations" :key="index" class="location" @mouseover="showLocation(index)"
                  @mouseleave="hideLocation(index)" :class="[
                    location.countryClass,
                    { active: activeLocation === index },
                  ]">
                  <div class="lcmark">
                    <div class="icon">
                      <img src="../../assets/images/location_mark.png" alt="location_mark" />
                    </div>
                    <h4 class="country">{{ location.country }}</h4>
                  </div>
                  <div class="info">
                    <h5>{{ location.country }}</h5>
                    <a v-if="location.contactNumber" :href="'tel:' + location.contactNumber" class="tel"><span
                        class="strong">号码:</span>
                      {{ location.contactNumber }}</a>
                    <p v-if="location.address" @click="copyTextNoInput(location)">
                      <span class="strong">地址: </span>
                      {{ location.address }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- History -->
    <section class="history_sec pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>A brief history of NXCLOUD</h2>
        </div>
        <div class="content">
          <div class="history_wrapper">
            <div class="inner">
              <ul class="history_listing">
                <li :class="{ active: activeHistory === index }" v-for="(history, index) in histories" :key="index"
                  @mouseover="showHistory(index)">
                  <div class="left">
                    <button data-history="history_1">
                      <span>{{ history.year }}</span>
                    </button>
                  </div>
                  <div class="right">
                    <div class="text_wrap" v-for="item in history.content" :key="item">
                      <h3>{{ item.title }}</h3>
                      <p>{{ item.desc }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { ref } from "vue";

export default {
  name: "ZhCompanyPage",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const currentSlide = ref(1); // Initialize currentSlide with initial value 1
    const slideLength = ref(1); // Initialize slideLength with initial value 1

    // For Number pagination
    const coreValuesSliderSwiper = (swiper) => {
      slideLength.value = swiper.slides.length;
      swiper.on("slideChange", () => {
        currentSlide.value = swiper.realIndex + 1;
      });
    };

    const coreValuesSliderSlideChange = () => { };

    return {
      currentSlide,
      slideLength,
      coreValuesSliderSwiper,
      coreValuesSliderSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  data() {
    return {
      activeLocation: null,
      locations: [
        {
          country: "深圳，中国",
          countryClass: "shenzhen",
          contactNumber: "400-7800-900",
          address:
            " 中国广东省深圳市南山区粤海街道科技园社区科苑路15号科兴科学园A栋A1-601 ",
        },
        {
          country: "荷兰",
          countryClass: "netherlands",
          contactNumber: "",
          address: "",
        },
        {
          country: "香港",
          countryClass: "hongkong",
          contactNumber: "",
          address:
            "Room 803 8/F, Lai Cheong Factory Bldg., 479-479A Castle Peak Road, Cheung Sha Wan, Kowloon, Hong Kong",
        },
        {
          country: "菲律宾",
          countryClass: "philippines",
          contactNumber: "",
          address: "",
        },
        {
          country: "印度尼西亚",
          countryClass: "indonesia",
          contactNumber: "",
          address: "",
        },
        {
          country: "新加坡",
          countryClass: "singapore",
          contactNumber: "",
          address: "Funan 05-139 109 North Bridge Rd Singapore 179097",
        },
        {
          country: "马来西亚",
          countryClass: "malaysia",
          contactNumber: "",
          address: "",
        },
        {
          country: "越南",
          countryClass: "vietnam",
          contactNumber: "",
          address: "",
        },
        {
          country: "美国",
          countryClass: "california",
          contactNumber: "",
          address:
            "1601 McCarthy Blvd Milpitas 95035, California United States of America",
        },
      ],
      activeHistory: 0,
      histories: [
        {
          year: "2018年",
          content: [
            {
              title: "3月",
              desc: " 获得IMDA颁发的SBO牌照，在新加坡成立NXCLOUD，落成全球总部，并陆续在美国、印尼、马来西亚等地成立分公司和办事处，为全球企业提供融合通信云服务。 ",
            },
          ],
        },
        {
          year: "2019年",
          content: [
            {
              title: "6月",
              desc: "SaaS产品 AICC 上线。",
            },
          ],
        },
        {
          year: "2021年",
          content: [
            {
              title: "4月",
              desc: " 在中国深圳成立牛信网络科技有限公司，以“牛信云”为品牌名服务中国出海企业。 ",
            },
            {
              title: "5月",
              desc: " 正式加入GSMA协会，成为其企业会员单位。",
            },
          ],
        },
        {
          year: "2022年",
          content: [
            {
              title: "5月",
              desc: "成为Viber官方的商务消息传递合作伙伴。",
            },
            {
              title: "8月",
              desc: " 获得中国多方通信服务业务经营许可证；成为WhatsApp官方授权的商业解决方案提供商。 ",
            },
          ],
        },
        {
          year: "2023年",
          content: [
            {
              title: "1月",
              desc: "晋升成为Select等级WhatsApp官方商业解决方案提供商。",
            },
            {
              title: "4月",
              desc: " 召开SaaS产品全球发布会，形成以数字营销与智能呼叫两大功能系统的SaaS产品——NXLink。 ",
            },
            {
              title: "9月",
              desc: " 作为 Gold Partner 重磅亮相亚洲电信盛会ACC 2023，与全球通信行业从业者共探发展之路。 ",
            },
            {
              title: "12月",
              desc: "成为Zalo中国区唯一官方商业解决方案提供商。",
            },
          ],
        },
      ],
    };
  },
  methods: {
    showLocation(index) {
      this.activeLocation = index; // Set active location index when hovering
    },
    hideLocation() {
      this.activeLocation = null; // Reset active location index when mouse leaves
    },
    showHistory(index) {
      this.activeHistory = index; // Set active History index when hovering
    },
    copyTextNoInput(location) {
      let text = location.address;
      // console.log(text);
      let textCopied = document.createElement("textarea");
      textCopied.value = text;
      document.body.appendChild(textCopied);
      textCopied.select();
      document.execCommand("copy");
      document.body.removeChild(textCopied);
      alert("Address:\n" + text);
    },
    leftSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const prevButton = currSlider.querySelector(".swiper-button-prev");
      if (prevButton) {
        prevButton.click();
      } else {
        console.error("Previous button not found");
      }
    },
    rightSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const nextButton = currSlider.querySelector(".swiper-button-next");
      if (nextButton) {
        nextButton.click();
      } else {
        console.error("Next button not found");
      }
    },
  },
};
</script>
