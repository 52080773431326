<template>
  <main>
    <section class="terms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="terms-hdng">This NXCLOUD User Service Agreement</h1>
            <p class="terms-desc mb-0">Update date: 02-8- 2023</p>
            <p class="terms-desc term-days">Effective date: 02-8- 2023</p>
            <p class="terms-desc">
              Welcome to use the platform services provided by NXCLOUD ASIA PTE.
              LTD. (hereinafter referred to as the "NXCLOUD"). Please carefully
              read and abide by the NXCLOUD User Service Agreement (hereinafter
              referred to as the "Agreement"). Before accepting this agreement,
              please read the whole content of this agreement carefully,
              especially the clause of exemption or limitation of liability and
              the clause of choice of jurisdiction. Whether you actually read
              this Agreement or not, your confirmation of this Agreement or
              actual use of platform services by clicking on the web page
              indicates that you and NXCLOUD have reached an agreement on this
              Agreement and agree to accept all the contents of this Agreement.
              If you do not agree to any of the contents of this Agreement, or
              cannot accurately understand NXCLOUD's interpretation of the
              terms, please do not agree to or use the services under this
              Agreement. Otherwise, you agree to be bound by this Agreement by
              accepting the terms and conditions set forth below. This Agreement
              is signed by NXCLOUD with you. In addition to this Agreement,
              NXCLOUD has issued other rules and policies such as the NXCLOUD
              Developer Service Agreement and NXCLOUD Privacy Policy. For other
              rules and policies issued by the NXCLOUD platform, the
              corresponding rules and policies shall prevail, and for matters
              not mentioned in the corresponding rules and policies, the
              Agreement shall prevail.
            </p>

            <p class="terms-desc">
              <strong
                >Our website is limited to legal entities or other organizations
                that are legally registered and validly existing in accordance
                with the law of their place of domicile and capable of
                performing their obligations under this Agreement. If you do not
                qualify, please do not continue to use our services, and we have
                the right to terminate the services provided to you at any
                time.</strong
              >
            </p>

            <h2 class="terms-list-hdng">Index</h2>

            <ul class="index-list">
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#1"
                  >Article 1 Definitions</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#2"
                  >Article 2 Service Contents</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#3"
                  >Article 3 Your Rights and Obligations</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#4"
                  >Article 4 Rights and obligations of the Platform</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#5"
                  >Article 5 Confidentiality Clause</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#6"
                  >Article 6 Intellectual Property Rights</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#7"
                  >Article 7 Export Control and Sanctions Laws and
                  Regulations</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#8"
                  >Article 8 Liability for Breach of Contract</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#9"
                  >Article 9 Disclaimer</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#10"
                  >Article 10 Termination and Assignment of Agreement</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#11"
                  >Article 11 Law Application and Dispute Settlement</a
                >
              </li>
              <li>
                <a href="https://www.nxcloud.com/english/userAgreement/#12"
                  >Article 12 Entry into Force and Interpretation of this
                  Agreement</a
                >
              </li>
            </ul>

            <h2 class="terms-list-hdng">Article 1 Definitions</h2>
            <ul class="article">
              <li>
                1.1 NXCLOUD Platform: NXCLOUD provides a platform for developers
                to integrate communication capabilities.
                <strong class="terms-link">
                  <a href="https://www.nxcloud.com/english/"
                    >(https://www.nxcloud.com/english)
                  </a>
                </strong>
              </li>
              <li>
                1.2 You or Customer: A legal person or other organization
                legally registered and validly existing under the law of its
                domicile and capable of performing its obligations under this
                Agreement.
              </li>

              <li>
                1.3 Your end user: refers to the end user who uses your products
                and/or services.
              </li>
              <li>
                1.4 NXCLOUD service: a general term for the services developed
                and operated by NXCLOUD that provide data analysis, statistics
              </li>
              <li>
                1.5 NXCLOUD account: refers to the NXCLOUD account used to
                receive NXCLOUD services after logging in through the NXCLOUD
                accou
              </li>
            </ul>

            <h3 class="terms-list-hdng">Article 2 Service Contents</h3>
            <p class="terms-desc">
              We reserve the right to revise this clause at any time and will
              publish the revised text on this page after revision. Therefore,
              please check this page frequently. If you continue to use our
              services, you will be deemed to accept our changes to this
              clause.Update date: 02-8- 2023
            </p>

            <h3 class="terms-list-hdng">
              Article 3 Your Rights and Obligations
            </h3>

            <ul class="article article-Registration">
              <li>3.1 Registration and use</li>
              <li>
                3.1.1 You confirm that, when you complete the registration
                procedure or actually use the Service in a manner permitted by
                other platforms, you shall be a legal person or other
                organization with full capacity for civil rights and appropriate
                capacity for civil conduct. If you do not have the
                aforementioned qualifications, please do not use the service,
                and NXCLOUD has the right to cancel (permanently freeze) your
                account. If you register or actually use the Services on behalf
                of a company or other legal entity, you represent and warrant
                that you have the right to bind the company or legal entity to
                the terms of this Agreement.
              </li>
              <li>
                3.1.2 You shall submit the registration information as required
                by the Platform, and ensure that the registration information
                submitted is true and valid. In case of any change in your
                relevant registration information, you shall modify the relevant
                registration information within one working day after the
                change. Due to your false registration information, inaccurate
                information submitted or not updated in a timely manner, NXCLOUD
                has the right to stop your services, resulting in any loss or
                liability by you.
              </li>
              <li>
                3.1.3 You undertake that the account name, logo, profile and
                other information registered by you shall not contain any
                illegal or unfavorable information, shall not impersonate
                others, shall not register for others without permission, shall
                not register accounts in a way that may mislead other users, and
                shall not use any user name that may infringe upon the rights
                and interests of others (including but not limited to suspected
                infringement of trademark rights or reputation rights);
                otherwise, NXCLOUD has the right to refuse to register or stop
                the service and take back the account number, and you shall bear
                the losses arising therefrom.
              </li>
            </ul>

            <ul class="article">
              <li>
                3.1. 4 You understand and agree that the ownership of the
                registered account belongs to NXCLOUD. After the registration is
                completed, you will only obtain the right to use the account.
                Otherwise, NXCLOUD has the right to withdraw the account
                immediately without notice, and you shall bear the losses
                arising from the emptying or loss of all the data, information,
                etc. caused by your use of the Service
              </li>
              <li>
                3.1.5 After your successful registration, NXCLOUD will confirm
                your identity based on your account and password. You shall take
                care of your terminal and your account and password and shall be
                solely responsible for all activities carried out with this
                account and password. You undertake to immediately notify the
                Platform of any unauthorized use of your password or account, or
                of any other security issue, and you agree and confirm that
                NXCLOUD is not liable for any direct or indirect loss or damage
                caused by the above circumstances. Your account and password may
                not be transferred, bestowed or inherited in any way (except for
                property rights and interests related to the account), unless
                otherwise provided for by law or judicature and with the consent
                of NXCLOUD.
              </li>
              <li>
                3.1.6 You understand and agree that if you have not accessed
                your account for more than 12 consecutive months, NXCLOUD has
                the right to recover the account for the purpose of website
                optimization management, and you shall bear the relevant issues
                and responsibilities.
              </li>
              <li>
                3.1.7 After the acco unt is withdrawn or cancelled in accordance
                with this Agreement, NXCLOUD has the right to deal with the
                relevant contents and information of the account by means of
                including but not limited to deletion, and does not have to bear
                any liability to the user.
              </li>
              <li>
                3.1.8 In the event of amicable termination of the agreement,
                NXCLOUD will refund the balance of the account to your original
                account.
              </li>
              <li>3.2 Account security</li>
              <li>
                3.2.1 Since your NXCLOUD account is associated with your
                personal information, business information and NXCLOUD business
                information, your NXCLOUD account is for your personal use only.
                Without the consent of NXCLOUD, you directly or indirectly
                authorize a third party to use your NXCLOUD account or access
                information under your account is invalid. If NXCLOUD determines
                that the use of your NXCLOUD account may endanger the security
                of your account and/or the security of NXCLOUD information
                according to the default determination procedures and standards
                agreed in NXCLOUD platform rules, NXCLOUD may refuse to provide
                the corresponding services or terminate this Agreement. If you
                choose a third party account to log into NXCLOUD, the third
                party account will be associated with your NXCLOUD account, and
                NXCLOUD will use your information (such as a picture) based on
                the information provided by a third party authorized by you.
                Your subsequent use of this account is subject to this
                Agreement.
              </li>
              <li>
                3.2.2 You shall be responsible for the confidentiality of your
                NXCLOUD account and password, and shall be responsible for all
                activities (including but not limited to information disclosure,
                release of information, online click consent or submission of
                various rule agreements or release of information) that occur
                under such login name and password. Make sure you leave the site
                at the end of each surfing session with the correct steps.
                NXCLOUD cannot and will not be liable for any loss or damage
                caused by your failure to comply with the provisions of this
                subsection.
              </li>
              <li>
                3.2.3 If you discover that someone else has embezzled your
                account number and password, or any other circumstances without
                your legal authorization, you shall immediately notify the
                Platform in an effective manner and provide the necessary
                information (such as customer information, statements,
                supporting materials and claims, etc., so that the Platform can
                verify identity and events). Upon receipt of your valid notice
                and verification of identity, the Platform will handle the
                matter in accordance with laws, regulations and service rules.
                You will be responsible for any liability and consequences
                arising from the Platform's handling of this Article.
              </li>
              <li>
                3.2.4 If the information provided by you is defective, resulting
                in the inability of NXCLOUD to verify your identity or judge
                your needs, resulting in the failure of the platform to deal
                with the same in a timely manner, the losses caused to you shall
                be borne by you. At the same time, you understand that the
                handling of your request by the Platform requires a reasonable
                period of time and NXCLOUD will not be liable for any loss that
                you have incurred before the Platform takes action and for any
                loss caused after the Platform takes action for reasons not
                attributable to the Platform.
              </li>
              <li>
                3.2.5 You understand and agree that NXCLOUD has the right to
                know the true background and purpose of your use of the products
                and services on this Web Site, and has the right to require you
                to provide true, complete and accurate information. If the
                Platform has reasonable grounds to suspect that the information
                you provide is untrue, that you conduct false transactions, or
                that your actions violate the rules of the Platform's Web Site,
                the Platform has the right to temporarily or permanently
                restrict the use of all products and/or services under your
                account, or all functions of such products and/or services
              </li>

              <li>
                3.2.6 You understand and agree that NXCLOUD has the right to
                temporarily suspend or restrict some or all of the fund payment
                functions under your account based on the needs of operation and
                transaction security. The platform will notify you by mail,
                internal mail, text message or telephone, and you shall pay
                attention to it in a timely manner and follow up with complaints
                according to the procedures.
              </li>
              <li>
                3.2.7 You shall take necessary and effective security protection
                measures for the computer information systems and equipment used
                by you. You shall be responsible for any damage to your rights
                and interests caused by your failure to take the aforesaid
                measures.
              </li>
              <li>
                3.2.8 In order to provide you with effective services, the
                NXCLOUD platform will utilize resources such as the processor
                and bandwidth of your terminal. The platform may be used in the
                process of data traffic costs, users need to understand the
                relevant charges from the operator, and bear the relevant costs.
              </li>
              <li>3.3 Account cancellation</li>
              <li>
                3.3.1 If you violate national or local laws and regulations or
                the Terms of Service, NXCLOUD has the right to suspend or
                terminate the provision of part or all of the services to you
                until the cancellation of the platform account.
              </li>
              <li>3.3.2 Logout of login</li>

              <li>
                (1) You understand and agree that if you have not accessed the
                Website for 12 consecutive months, and there is no unexpired
                service under your account, NXCLOUD has the right to cancel your
                login, and you will no longer be able to use the logout to
                access the Platform Website.
              </li>

              <li>
                (2) You agree and authorize NXCLOUD to disclose such information
                within the scope of NXCLOUD's website if you commit fraud,
                publish or sell fake or inferior goods, infringe upon the
                legitimate rights and interests of others or commit other
                serious violations of the rules of NXCLOUD's website, Your login
                name may be cancelled. After being cancelled, you will no longer
                be able to access the platform's website and the platform's
                website services will be terminated simultaneously.
              </li>
              <li>
                3.3.3 You guarantee that you will not take any acts that affect,
                damage or may affect or damage the legitimate rights and
                interests of the websites of NXCLOUD and its affiliated
                companies; the acts or ways that affect or damage the legitimate
                rights and interests of the platform websites include but are
                not limited to:
              </li>
              <li>
                (1) violating any service agreement/clause, management rules,
                trading rules or other standard contents published by NXCLOUD;
              </li>
              <li>
                (2) Undermining or attempting to undermine the fair trading
                environment or normal trading order of NXCLOUD;
              </li>
              <li>
                (3) Any intention to express or map to NXCLOUD ASIA PTE. LTD. by
                using or mapping the name, icon or brand of NXCLOUD, or any
                intention to mislead others, or to use any Chinese or English
                (full name or abbreviation), number or domain name;
              </li>

              <li>
                (4) According to its own reasonable judgment, NXCLOUD believes
                that it may be any other circumstance that is of the same nature
                as or generates similar risks as above.
              </li>
              <li>
                3.3.4 If you commit any of the acts specified in Item 3.3.3 or
                any other act in violation of laws, administrative regulations
                or the relevant rules of the NXCLOUD Platform, you accept and
                acknowledge that the Platform has the right to terminate the
                provision of services to you in the event that you violate the
                aforesaid agreement, that is, you will no longer be able to log
                onto the Platform Website, and the services of all Platform
                Websites will be terminated at the same time.
              </li>
            </ul>

            <ul class="article article-Registration">
              <li>3.4 Specifications for use</li>
              <li>
                3.4.1 You shall bear legal liability for your own acts and the
                rights and obligations between you and your customers in the
                process of using the NXCLOUD service, and you shall carry out
                the application operation activities in accordance with the law
                and independently bear the corresponding legal liability. You
                agree to indemnify NXCLOUD and its affiliates and partners
                against any claims, claims or losses arising from or incurred by
                any third party in violation of this Agreement or the relevant
                Terms of Service.
              </li>
              <li>
                3.4.2 NXCLOUD may unilaterally adjust or revise the platform
                rules at any time according to its own operation arrangements or
                the requirements of laws and regulations.
              </li>
              <li>
                (1) NXCLOUD has the right to notify you of the platform rules by
                himself or by one or more of the following means: telephone,
                text message, e-mail, self-service system, intra-station
                message, website announcement, etc., and you shall timely check
                the relevant contents.
              </li>
              <li>
                (2) The adjusted and revised Bull Credit Cloud Rules shall come
                into force as of the effective date specified in the Notice and
                you shall abide by them. After the adjustment of the rules of
                NXCLOUD, the access standards and promotion standards may
                change, and you shall not request the platform to provide
                services for you on the grounds of the old standards.
              </li>
              <li>
                (3) If you do not agree to the above adjustments or amendments,
                you shall immediately cease to use the services provided by
                NXCLOUD and notify NXCLOUD in writing to terminate this
                Agreement. You are deemed to have accepted and accepted the
                rules relating to NXCLOUD if you continue to use it in any way.
              </li>
              <li>
                3.4.3 You fully understand and agree that you are responsible
                for all actions under your account, including any content you
                post and any consequences arising therefrom. You shall judge for
                yourselves the content of the Service and bear all risks arising
                from the use of the content, including risks arising from
                reliance on the correctness, completeness or practicality of the
                content. NXCLOUDping cannot and will not be liable for any loss
                or damage caused by the foregoing risks.
              </li>
              <li>
                3.4.4 You understand and agree that NXCLOUDping has been
                committed to providing users with a civilized, healthy,
                standardized and orderly network environment, and you shall not
                make use of this platform and services to produce, upload,
                reproduce, publish, transmit, disseminate or reproduce any
                content that may interfere with the normal operation of this
                service, infringe upon the legitimate rights and interests of
                other entities, or violate the laws and regulations of the
                State, including but not limited to:
              </li>
              <li>
                (1) opposing the basic principles determined in the
                Constitution;
              </li>
              <li>
                (2) endangering national security, divulging state secrets,
                subverting state power, or undermining national unity;
              </li>
              <li>(3) damage the honor or interests of the State;</li>
              <li>
                (4) distorting, vilifying, desecrating or denying the deeds and
                spirit of heroes and martyrs, or infringing upon the names,
                portraits, reputation or honors of heroes and martyrs by
                insulting, slandering or other means;
              </li>
              <li>
                (5) advocating terrorism or extremism or inciting the
                implementation of terrorist activities or extremist activities;
              </li>
              <li>
                (6) inciting ethnic hatred or discrimination, or undermining
                ethnic solidarity;
              </li>
              <li>
                (7) destroying the religious policies of the State, or
                propagating evil cults or feudal superstitions;
              </li>
              <li>
                (8) Spreading rumors, disturbing social order or destroying
                social stability;
              </li>
              <li>
                (9) disseminating obscenity, pornography, gambling, violence or
                terror or instigating crimes;
              </li>
              <li>
                (10) insulting or defaming others, or infringing upon the
                reputation, privacy or other legitimate rights and interests of
                others;
              </li>
              <li>
                (11) using an exaggerated title with the content seriously
                inconsistent with the title;
              </li>
              <li>(12) Hype scandals, scandals and misdeeds;</li>
              <li>
                (13) Making improper comments on natural disasters, major
                accidents and other disasters;
              </li>
              <li>
                (14) Sexual suggestion, sexual provocation or other sexual
                association easily occurs;
              </li>
              <li>
                (15) Showing bloodshed, horror, cruelty or causing physical or
                mental discomfort;
              </li>
              <li>
                (16) inciting crowd discrimination, regional discrimination,
                etc.;
              </li>
              <li>(17) Advocating vulgar, vulgar or kitsch contents;</li>
              <li>
                (18) those that may cause minors to imitate unsafe acts or act
                against social morality, or induce minors to have bad habits,
                etc.;
              </li>
              <li>
                (19) Contents infringing upon the legitimate rights and
                interests of minors or damaging the physical and mental health
                of minors;
              </li>
              <li>
                (20) inciting illegal assemblies, associations, processions,
                demonstrations or assembling people to disturb public order;
              </li>
              <li>
                (21) Conducting activities in the name of illegal
                non-governmental organizations;
              </li>
              <li>
                (22) violating the "seven bottom lines" of the bottom line of
                laws and regulations, the bottom line of the socialist system,
                the bottom line of national interests, the bottom line of the
                legitimate rights and interests of citizens, the bottom line of
                the social public order, the bottom line of moral ethics and the
                bottom line of the authenticity of information;
              </li>
              <li>
                (23) other contents prohibited by laws and administrative
                regulations or adversely affecting the network ecology.
              </li>
              <li>
                In the process of providing you with services, if any clues or
                risk information on illegal or criminal activities such as fraud
                or impersonation are discovered , NXCLOUD has the right to
                transfer the case to the public security, finance,
                telecommunications, cyberspace and other competent departments
                in accordance with the relevant provisions of the State. You
                need to ensure that you are legally registered and validly
                existing under the law of your domicile and are able to perform
                your obligations under this Agreement.
              </li>

              <li>
                3.4.5 When you register your account or use this service, if you
                find that your account may be involved in fraud, false
                registration or other abnormal circumstances or risks, NXCLOUD
                has the right to re-verify your account, and may, according to
                the risk situation, take measures such as rectification within a
                time limit, restriction of functions, suspension of use, closure
                of account, prohibition of re-registration and other measures
                specified in this Agreement.
              </li>

              <li>
                3.4.6 You understand and agree that NXCLOUD Platform will make
                commercially reasonable efforts to secure your data storage on
                this Platform and Services, but NXCLOUD Platform does not fully
                guarantee this, including but not limited to:
              </li>

              <li>
                (1) NXCLOUD Platform is not responsible for the deletion or
                storage failure of relevant data in the platform and services;
              </li>

              <li>
                (2) The NXCLOUD Platform has the right to determine the maximum
                storage period for the data of a single user in the software and
                services according to the actual situation, and allocate the
                maximum storage space for the data on the server.
              </li>

              <li>
                (3) If you cease to use the Platform and the Services or the
                Services are terminated or cancelled, the NXCLOUDCloud Platform
                may permanently delete your data from the Server. Tencent is not
                obligated to return any data to you after the service is
                stopped, terminated or cancelled.
              </li>

              <li>
                (4) When you register your account or use the Service, if it is
                found that your account may be involved in fraud, false
                registration or other abnormal circumstances or risks, the
                NXCLOUD Platform has the right to re-verify your account, and
                may, in light of the risks, take measures such as rectification
                within a prescribed time limit, restriction of functions,
                suspension of use, closure of account, prohibition of
                re-registration and other measures specified in this Agreement.
              </li>

              <li>
                (5) In the process of providing you with services, if any clues
                or risk information on illegal or criminal activities such as
                fraud or impersonation are discovered, the NXCLOUD Platform has
                the right to transfer the case to the public security, finance,
                telecommunications, cyberspace and other competent authorities
                in accordance with the relevant provisions of the State.
              </li>
              <li></li>
            </ul>

            <ul class="article">
              <li>
                You undertake that your end users are aware of the collection,
                use and disclosure of personal information by us in accordance
                with this Agreement and the Privacy Policy. If your end user
                requests you for personal information related to NXCLOUD, you
                need to verify the identity of the personal information subject
                and the legal authenticity of the request, and contact NXCLOUD
                in a timely manner after deciding to respond to the request.
                After NXCLOUD verifies your identity and the true legality of
                your request, we will actively cooperate, respond to the
                relevant requests of the subject of personal information and
                timely reply to you. For the claims or complaints caused by your
                unilateral failure to fulfill the relevant obligations requested
                by the subject of personal information, you need to bear full
                and separate liability for the handling of the complaints or
                claims and the settlement of disputes, and compensate us for all
                the losses that may be incurred therefrom.
              </li>

              <li>3.5 Third-party products or services</li>

              <li>
                3.5.1 If you acquire and use any product or service provided by
                a third party (including but not limited to the product or
                service provided by a third party such as a service provider in
                the platform service market) through NXCLOUD, you shall assess
                on your own whether such product or service meets your
                requirements.
              </li>

              <li>
                3.5.2 The launch of a third-party product or service may require
                you to enter into a separate agreement with a third party. A
                separate agreement may be presented in electronic form or in
                separate paper form, and you may decide whether to accept the
                agreement and use the product or service according to your own
                situation.
              </li>

              <li>
                3.5.3 Any dispute arising from the use of a third party's
                product or service shall be settled by you and the third party,
                and the financial compensation involved shall be borne by you.
              </li>
            </ul>

            <h3 class="terms-list-hdng">
              Article 4 Rights and obligations of the NXCLOUD
            </h3>
            <ul class="article">
              <li>
                4.1 If NXCLOUD needs to suspend services or require you to
                cooperate in case of network or system maintenance or upgrading,
                it shall inform you in advance;
              </li>

              <li>
                4.2 Bull letter cloud to provide you with statistics to achieve
                inquiries, bills, detailed phone bills, SMS sending status, call
                status and other functional services;
              </li>

              <li>
                4.3 NXCLOUD has the right to modify and upgrade the platform and
                other operations, and has the right to formulate and modify the
                platform and service specifications. Once the specifications are
                published on the websites of NXCLOUD and its affiliated
                companies, they form part of this Agreement and you shall abide
                by them. Otherwise, you shall stop using this Service and this
                Agreement shall be automatically terminated.
              </li>

              <li>
                4.4 NXCLOUD has the right to inspect and supervise your services
                for the purpose of preventing, detecting and investigating
                fraud, endangering security, illegally or in violation of
                agreements, policies or rules with you or your affiliates.
                NXCLOUD reserves the right to suspend or terminate the services
                provided to you at its sole discretion whether or not you and
                your customers use the Services to engage in improper activities
                based on the complaints of the end users or third parties and
                the supervision and inspection of the Services by NXCLOUD.
              </li>

              <li>
                4.5 NXCLOUD has the right to change part or all of its service
                functions when necessary. If the change of functions may cause
                the interruption or termination of daily services, NXCLOUD shall
                notify you and complete the repair of basic functions as soon as
                possible. You can choose to continue using the adjusted service
                features or to stop using platform services.
              </li>

              <li></li>

              <li>
                4.6 NXCLOUD has the right to adjust the standards of platform
                services according to the adjustment of laws and regulations,
                the orders of administrative law enforcement organs and the
                changes of social ethics.
              </li>

              <li></li>

              <li>
                4.7 To the maximum extent permitted by applicable law, the
                Platform shall have the right to analyze the entire user
                database of the Platform and use the user database for other
                purposes, including but not limited to the analysis of user
                behavior and a series of user data mining and in-depth analysis
                of data.
              </li>

              <li></li>

              <li>
                4.8 The platform has the right to change some or all of its
                service functions when necessary. If a change of function may
                cause the interruption or termination of routine services,
                NXCLOUD shall notify you one month in advance of the change and
                complete the repair of basic functions as soon as possible. You
                can choose to continue using the adjusted service features or to
                stop using platform services.
              </li>

              <li></li>

              <li>
                4.9 Your application and the business you are engaged in shall
                not constitute any actual or potential damage or conflict of
                interest to NXCLOUD and its affiliates, otherwise NXCLOUD has
                the right to immediately notify you and terminate all services
                provided by the platform to users without any legal liability
                and cost compensation, and NXCLOUD reserves the right to pursue
                your corresponding liability.
              </li>

              <li></li>
            </ul>

            <ul class="article article-Registration">
              <li>4.10 Fee-Based Services</li>

              <li>
                4.10.1 NXCLOUD will provide users with free or paid services
                related to this service according to the needs of business
                operation. The Platform has the right to adjust part or all of
                the services into charging services, and has the right to
                formulate charging standards, charging methods and other rules.
                You can accept or refuse to pay for the service. If you choose
                to use the service, you will be charged. If you refuse to pay,
                the platform has the right to stop providing services to you.
              </li>

              <li>
                4.10.2 The specific content of the Service that you are entitled
                to may vary depending on such factors as the specific service
                category you choose, and the specific content published on the
                relevant service page and actually provided shall prevail.
              </li>

              <li>
                4.10.3 You understand and agree that NXCLOUD has the right to
                adjust the Service and may affect the interests that you have
                enjoyed or are enjoying, and you agree to use the NXCLOUD
                Platform in accordance with the adjusted contents without any
                liability.
              </li>
            </ul>

            <h3 class="terms-list-hdng">Article 5 Confidentiality Clause</h3>

            <ul class="article">
              <li>
                5.1 For the purpose of this Agreement, "Confidential
                Information" refers to the business secrets, computer programs,
                design techniques, ideas, know-how, processes, data, business
                and product development plans, customer information and other
                information related to the business of the Disclosing Party,
                which are obtained by one party (hereinafter referred to as the
                "Receiving Party") from the other party (hereinafter referred to
                as the "Disclosing Party"), known to the other party, or jointly
                created by the two parties through the performance of this
                Agreement and are inseparable. Regardless of the form or medium
                in which the said information and materials are presented,
                whether or not the disclosing party indicates their
                confidentiality orally, graphically or in writing at the time of
                disclosure.
              </li>

              <li>
                5.2 Both the recipient and the disclosing party shall take
                appropriate measures to properly preserve the confidential
                information provided by the other party, and the degree of
                prudence of such measures shall not be less than that of the
                confidentiality of their own information. The Recipient and the
                Disclosing Party may use the Confidential Information only for
                the purposes or purposes relating to this Agreement.
              </li>

              <li>
                5.3 The Parties undertake that the Confidential Information
                shall be available only to the responsible persons and employees
                of their respective Parties engaged in such business. Before
                such persons become aware of the Confidential Information, they
                shall be reminded of the confidentiality of the Confidential
                Information and of their obligations, and shall demonstrate, in
                a demonstrable manner, that such persons actually undertake the
                confidentiality obligations under this Agreement.
              </li>

              <li>
                5.4 If necessary, Licensee shall return or destroy all documents
                or other information containing Confidential Information as
                directed by Discloser.
              </li>

              <li>
                5.5 The aforesaid restrictive clauses of this Article shall not
                apply to the following circumstances:
              </li>

              <li>
                (1) The Confidential Information is legally owned by the
                Licensee at or before the signing of this Agreement;
              </li>
              <li>
                (2) The confidential information has been made public or can be
                obtained from the public domain when it is notified to the
                recipient;
              </li>
              <li>
                (3) Confidential information is obtained by the recipient from a
                third party with whom it has no obligation to keep confidential
                or not to disclose;
              </li>
              <li>
                (4) the confidential information has been disclosed or can be
                obtained from the public domain, provided that it does not
                violate the responsibilities stipulated in this Agreement;
              </li>
              <li>
                (5) The Confidential Information is independently developed by
                the Receiving Party or its affiliates or subsidiaries and does
                not benefit from the information obtained by the Notifying Party
                or its affiliates or subsidiaries;
              </li>
              <li>
                (6) If Licensee discloses confidential information in response
                to a court or other legal or administrative authority's request
                for disclosure of such information (by oral questioning,
                questioning, requesting information or documents, summons, civil
                or criminal investigation or other proceedings), Licensee shall
                immediately notify the Disclosing Party of the occurrence of
                such situation and make necessary explanations.
              </li>

              <li>
                5.6 The Recipient and the Disclosing Party are also responsible
                for the confidentiality of the specific contents of this
                Agreement.
              </li>

              <li>
                5.7 The transmission or exchange of documents such as
                communications, notices, notifications, etc. between the
                Recipient and the Disclosing Party for the purpose of performing
                this Agreement shall be properly kept by the Parties and shall
                not be used for any purpose detrimental to the business of the
                Parties. Neither party may slander the other party, nor may it
                make any unfavorable remarks in public for the purpose of
                attacking the other party.
              </li>
            </ul>

            <h3 class="terms-list-hdng">
              Article 6 Intellectual Property Rights
            </h3>
            <ul class="article">
              <li>
                6.1 NXCLOUD or other right holders lawfully own the intellectual
                property rights of all contents on the platform, including but
                not limited to works, pictures, archives, information,
                materials, website structure, website picture arrangement and
                page design. No one may use, modify, reproduce, publicly
                disseminate, change, distribute, distribute or publicly publish
                the Platform programs or content without the written consent of
                NXCLOUD or other right holders.
              </li>

              <li>
                6.2 We agree that you use our name, trade name, trademark, logo,
                domain name and other business logos in the process of business
                promotion of your partner; you agree that we use your name,
                trade name, trademark, logo, domain name and other business
                logos in the process of business promotion of our partner and
                promote your cooperation as a customer case. Under no
                circumstances shall we engage in any matter that may affect our
                cooperation or infringe upon the reputation, intellectual
                property, privacy or other legitimate rights and interests of
                the other party.
              </li>

              <li>
                6.3. You shall not delete, damage, conceal or modify the
                copyright notices, trademarks or other proprietary marks of
                NXCLOUD or its related parties on the products.
              </li>

              <li>
                6.4 The systems, codes, data, brands, etc. involved in the
                project cooperation under this Agreement shall be owned by both
                parties in accordance with the law. Without the written consent
                of the possessor, the other party shall not use, modify, copy,
                publicly disseminate or distribute such systems, codes, data,
                brands, etc. without the consent of the right possessor.
                Otherwise, the right possessor has the right to immediately
                terminate this Agreement and require the other party to
                compensate for all losses (including but not limited to
                investigation and evidence collection fees, notarization fees,
                attorney's fees, etc.).
              </li>

              <li>
                6.5 We have all rights to the operation data of NXCLOUD
                Platform, including but not limited to login information,
                operation record, service order and other data. Without our
                written consent, you may not store, use or authorize others to
                use the aforesaid data.
              </li>

              <li>
                6.6 You agree and authorize us to enjoy worldwide the right to
                permanent, free, non-exclusive and permitted sub-licensing of
                the data used and collected during the provision of services to
                you, including but not limited to our use of the aforesaid data
                for macro analysis, and the publication or sharing of the
                analysis results to third parties.
              </li>
            </ul>

            <h3 class="terms-list-hdng">
              Article 7 Export Control and Sanctions Laws and Regulations
            </h3>
            <p class="terms-desc">
              Each Party undertakes to comply with all applicable economic and
              trade sanctions and export control laws and regulations, including
              all sanctions resolutions, laws and regulations and export control
              laws and regulations (to the extent applicable to that Party in
              the foregoing documents) formulated and implemented by the United
              Nations Security Council, China, the United States and any other
              country (hereinafter referred to as "applicable export control
              laws"). You undertake not to use the products or services provided
              by NXCLOUD Platform for purposes prohibited by applicable export
              control laws. Without the permission of the relevant competent
              authority, you and the individuals or entities you authorize to
              use the products or services provided by NXCLOUD Platform will not
              provide controlled technology, software or services through the
              products or services provided by NXCLOUD Platform to the
              individuals or entities sanctioned or designated by the applicable
              export control laws, or otherwise violate the applicable export
              control laws by any means.
            </p>

            <h3 class="terms-list-hdng">
              Article 8 Liability for Breach of Contract
            </h3>
            <ul class="article article-Registration">
              <li>
                8.1 You shall indemnify us against all losses (including but not
                limited to litigation fees, attorney's fees, settlement fees and
                damages) arising out of your use of the services provided by our
                Platform in violation of this Agreement, including but not
                limited to the use of any third party's intellectual property
                rights, or the use of any material or information containing
                obscenity, libel, defamation, rumor, racism or any infringement
                upon the right to name, reputation or privacy of any other
                person, resulting in a claim, lawsuit or other proceedings filed
                by a third party against us.
              </li>

              <li>
                8.2 If, as a result of your breach of any of the above
                undertakings, your end user claims any form of claim or claim
                against us, or if we are involved in a legal or administrative
                proceeding, you will be responsible for the full settlement of
                the claim or claim and for the defense of us, or for the
                cooperative defense at our request, in order to protect our
                interests and compensate us for any losses that we may incur,
                including but not limited to litigation fees, attorney's fees,
                settlement fees, and damages.
              </li>

              <li>
                8.3 If we find that you have breached the contract, we have the
                right to delete the relevant information without prior notice,
                terminate or suspend the services provided to you and require
                you to immediately stop using or copying the relevant documents.
              </li>

              <li>
                8.4 In the event of any of the following, we have the right to
                terminate this Agreement or suspend our services and require you
                to immediately delete the algorithms, models and data involved
                or to terminate any infringement of the rights and interests of
                others: You access our platform in violation of laws and
                regulations, upload, publish information or misuse our
                algorithms, models and data;
              </li>

              <li>
                Your actions infringe upon the privacy, trade secrets or other
                user rights.
              </li>

              <li>
                In the event of any of the above, you shall be liable in your
                own name and indemnify any other user or any third party for any
                loss suffered.
              </li>

              <li>
                8.5 We reserve the right to review your conduct in accordance
                with professional or non-professional standards, and you agree
                and irrevocably acknowledge the results of our review and the
                evidence collected. We do not exempt you from legal liability
                after we have dealt with your breach of contract in accordance
                with this Agreement or related agreements.
              </li>

              <li>
                8.6 You shall be liable for your own actions during the use of
                NXCLOUD Services. The forms of your legal liability include but
                are not limited to: compensating the infringed party, and
                compensating NXCLOUD for the same amount of damages after he
                first bears the administrative penalty or tort liability caused
                by your conduct. Under no circumstances shall NXCLOUD be liable
                to you or any third party for any indirect, consequential,
                incidental, special or punitive damages arising out of this
                Agreement.
              </li>
            </ul>

            <h3 class="terms-list-hdng">
              Article 10 Termination and Assignment of Agreement
            </h3>
            <ul class="article article-Registration">
              <li>10.1 Termination of Agreement</li>
              <li>
                10.1.1 We will terminate services provided to you when you cease
                and cease to use our developer services or when we are unable to
                contact you within a reasonable time through the contact
                information you provide.
              </li>
              <li>
                10.1.2 We have the right to terminate this Agreement or to
                suspend our services and to require you to immediately cease any
                violations of the laws and regulations in which you are acting.
              </li>
              <li>
                10.1.3 In the event of a force majeure event which renders us or
                either of you unable to continue to perform the relevant
                obligations, we will terminate the provision of services to you.
                12.1.4 We will terminate the services provided to you in the
                event that either party is suspended, cancelled or revoked of
                its business license, goes bankrupt, or its financial position
                deteriorates so that it is unable to fully perform its
                obligations under this Agreement.
              </li>
              <li>
                10.1.5 Upon termination of this Agreement, we shall have the
                right to decide whether to retain any information you may retain
                on our Platform. You do not have the right to request us to
                retain or forward any information in your account to you and
                third parties.
              </li>
              <li>
                10.1.6 The unsettled fees payable by you under this Agreement
                shall immediately expire upon the termination of this Agreement.
                The fees payable by you shall also include other fees (such as
                attorney's fees) incurred for recovering the unsettled fees from
                you, which may be deducted from your account or by other means
                of settlement, and the insufficient part shall be made up by
                you.
              </li>
              <li>10.2 Assignment of Agreement</li>
              <li>
                10.2.1 We reserve the right to assign all rights and obligations
                under this Agreement to our associated parties. In the event of
                a transfer, we will notify you via website bulletin, site
                notice, email and other means.
              </li>
              <li>
                10.2.2 You may not assign all or any of your rights or
                obligations under this Agreement without our prior written
                consent.
              </li>
            </ul>

            <h3 class="terms-list-hdng">
              Article 12 Entry into Force and Interpretation of this Agreement
            </h3>
            <ul class="article article-Registration">
              <li>
                12.1 The contents of this Agreement shall include the text of
                this Agreement and all relevant rules that have been issued or
                may be issued in the future. The Platform reserves the right to
                amend the terms of this Agreement as necessary. NXCLOUD reserves
                the right to interpret this Agreement. You can read the terms of
                the agreement in the latest version of this platform. If you
                continue to use the services provided by this platform after the
                terms of this Agreement are changed, you will be deemed to have
                accepted the amended Agreement. If you do not accept the revised
                agreement, you should stop using the services provided by this
                platform. Failure by the Parties to exercise or enforce any
                right or provision of this Agreement shall not constitute a
                waiver of such right.
              </li>

              <li>
                12.2 Notifications by NXCLOUD to the User under this Agreement
                may be made by way of webpage announcements, e-mails, text
                messages on mobile phones or regular correspondence; such
                notifications shall be deemed to have been served on the
                Recipient on the date of delivery.
              </li>

              <li>
                12.3 This Agreement is made and executed in Chinese and English.
                In case of any discrepancy between Chinese and English, the
                Chinese version shall prevail.
              </li>
            </ul>

            <h3 class="terms-list-hdng">Contact us:</h3>
            <p class="terms-desc">
              If you have any questions, comments or suggestions regarding this
              privacy policy, you can contact us by email:<a
                href="mailto:info@nxcloud.com"
              >
                info@nxcloud.com.</a
              >, telephone: 400-7800-900, we will respond to your request within
              15 business days.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>    

<script>
export default {
  name: "ZhTermsAndServices",
};
</script>
   
