<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">跨境电商解决方案</h1>
                  <p class="cntnt">
                    为跨境卖家提供从用户拉新到售后服务再到二次复购的一站式解决方案
                  </p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img src="../../assets/images/industry/ecommerce_banner.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benifits -->
    <section class="benifits">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>
            为跨境电商企业定制完整的<br />
            行业解决方案
          </h2>
        </div>
        <div class="content desktop">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/enhanced_marketing.svg" alt="benifit_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>提升营销效果</h3>
                    <p>
                      多渠道触达目标消费者，进行公司产品
                      与服务宣传，以相对较低的成本完成店
                      铺拉新与获客，同时打造企业私域流 量，促进用户复购。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/improve_quality_of_service.svg" alt="benifit_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>提升服务质量</h3>
                    <p>
                      为卖家与消费者建立便捷高效的沟通桥
                      梁，方便及时处理询单、物流通知、退
                      换货、客诉等问题，提升服务质量和客 户留存。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/account_protection.svg" alt="benifit_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>强化帐号安全</h3>
                    <p>
                      集成多种有效的用户验证方式，以用户
                      移动号码和邮箱为载体，进行店铺的快
                      速登录与身份验证，增强购物信息安 全。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content mobile">
          <div class="row justify-content-center">
            <div class="ecommerceSolutionsSlider swiperSlider slider_equal_height_no_bg_default_pagination">
              <swiper :modules="modules" :slides-per-view="1" :space-between="20" :equal-height="true"
                :autoplay="{ delay: 5000, disableOnInteraction: true }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="industrySlider" @slideChange="industrySliderSlideChange">
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/enhanced_marketing.svg" alt="benifit_img" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>提升营销效果</h3>
                          <p>
                            多渠道触达目标消费者，进行公司产品
                            与服务宣传，以相对较低的成本完成店
                            铺拉新与获客，同时打造企业私域流 量，促进用户复购。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/improve_quality_of_service.svg" alt="benifit_img" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>提升服务质量</h3>
                          <p>
                            为卖家与消费者建立便捷高效的沟通桥
                            梁，方便及时处理询单、物流通知、退
                            换货、客诉等问题，提升服务质量和客 户留存。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/account_protection.svg" alt="benifit_img" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>强化帐号安全</h3>
                          <p>
                            集成多种有效的用户验证方式，以用户
                            移动号码和邮箱为载体，进行店铺的快
                            速登录与身份验证，增强购物信息安 全。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new col_3_1 pt_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>客户全旅程服务</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-4">
              <div class="content_box step step_1">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/registration_login.svg"
                    alt="registration_login" />
                </div>
                <div class="text_wrap">
                  <h3>1. 注册登录</h3>
                  <div class="tags_wrap">
                    <router-link :to="{
                      path: '/zh-product-message',
                      query: { id: 'smsOtp' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="smsOtp"
                        data-url="../zh-product-message">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/sms_otp.svg" alt="icon" />
                        </i>
                        <span class="text">短信验证码</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-voice',
                      query: { id: 'voiceOtp' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="voiceOtp"
                        data-url="../zh-product-voice">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/voice_otp.svg" alt="icon" />
                        </i>
                        <span class="text">短信验证码</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'whatsappBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="icon" />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-fraud-prevention',
                      query: { id: 'OTA' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="OTA"
                        data-url="../zh-product-fraud-prevention">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/ota_icon.svg" alt="icon" />
                        </i>
                        <span class="text">OTA</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_2">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/user_recruitment.svg" alt="user_recruitment" />
                </div>
                <div class="text_wrap">
                  <h3>2. 用户拉新</h3>
                  <div class="tags_wrap">
                    <router-link :to="{
                      path: '/zh-product-message',
                      query: { id: 'marketingOtp' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="marketingOtp"
                        data-url="../zh-product-message">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/marketing_sms.svg" alt="icon" />
                        </i>
                        <span class="text">营销短信</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'whatsappBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="icon" />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'viberBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/viber_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'zaloBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="zaloBusiness"
                        data-url="../zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/zalo_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-customer-engagement',
                      query: { id: 'NXLink' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="NXLink"
                        data-url="../zh-product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/nx_link.svg" alt="icon" />
                        </i>
                        <span class="text">NXLink</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_3">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/customer_service.svg" alt="customer_service" />
                </div>
                <div class="text_wrap">
                  <h3>3. 客户服务</h3>
                  <div class="tags_wrap">
                    <router-link :to="{
                      path: '/zh-product-conversation',
                      query: { id: 'whatsappBusiness' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="./zh-product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="icon" />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link :to="{
                      path: '/zh-product-customer-engagement',
                      query: { id: 'NXLink' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="NXLink"
                        data-url="../zh-product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/nx_link.svg" alt="icon" />
                        </i>
                        <span class="text">NXLink</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{
                      path: '/zh-product-customer-engagement',
                      query: { id: 'AICC' },
                    }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/aicc_icon.svg" alt="icon" />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <router-link to="/zh-product-global-number" class="tag icon_item">
                      <i class="icon">
                        <img src="../../assets/images/customer_journey_section/global_number.svg" alt="icon" />
                      </i>
                      <span class="text">全球虚拟号码</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new pt_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-12">
              <div class="img_box text-center">
                <img src="../../assets/images/industry_ecomerce_journey.svg" alt="industry_ecomerce_journey" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row justify-content-end">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 1</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 2</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 3</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row flex-row-reverse">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 6</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 7</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recommended products -->
    <section class="product_sec pt_100 pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>产品推荐</h2>
        </div>
        <div class="content desktop">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/NXLing.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>NXLink</h3>
                  <p>
                    集成全球短信、语音、WhatsApp等通信能力，可便捷开展对话式客户服务、客户管理，构建企业私域流量，精准解决售后、复购问题。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/Sms-A2P.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>SMS A2P, P2A</h3>
                  <p>
                    提供海外本地固话以及移动号码，具备呼入与呼出的语音通信能力以及上下行短信通知能力，可在任何地方呼叫和接听各国电话。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/Marketing-sms.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>营销短信</h3>
                  <p>
                    通过短信向消费者发送特价提示、优惠券、促销活动等营销类信息，可附带网站或平台短链接，是一种可实现精准获客的营销方式。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/Voice-otp.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>语音验证码</h3>
                  <p>
                    以电话语音播报的方式向用户发送随机数字验证码，验证用户身份，可有效保障用户帐号信息安全，同时有效防止平台被薅羊毛。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content mobile">
          <div class="row justify-content-center">
            <div class="customerSlider swiperSlider numberNav slider_equal_height">
              <swiper :modules="modules" :slides-per-view="1" :space-between="50"
                :autoplay="{ delay: 5000, disableOnInteraction: false }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="customerSliderSwiper" @slideChange="customerSliderSlideChange">
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/NXLing.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>NXLink</h3>
                        <p>
                          集成全球短信、语音、WhatsApp等通信能力，可便捷开展对话式客户服务、客户管理，构建企业私域流量，精准解决售后、复购问题。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/Sms-A2P.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>SMS A2P, P2A</h3>
                        <p>
                          提供海外本地固话以及移动号码，具备呼入与呼出的语音通信能力以及上下行短信通知能力，可在任何地方呼叫和接听各国电话。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/Marketing-sms.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>营销短信</h3>
                        <p>
                          通过短信向消费者发送特价提示、优惠券、促销活动等营销类信息，可附带网站或平台短链接，是一种可实现精准获客的营销方式。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/Voice-otp.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>语音验证码</h3>
                        <p>
                          以电话语音播报的方式向用户发送随机数字验证码，验证用户身份，可有效保障用户帐号信息安全，同时有效防止平台被薅羊毛。
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <span class="count">{{ currentSlide }} / {{ slideLength }}</span>
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供<br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">开始使用</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { ref } from "vue";

// Import Swiper styles
export default {
  name: "ZhIndustryEcommerce",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const currentSlide = ref(1); // Initialize currentSlide with initial value 1
    const slideLength = ref(1); // Initialize slideLength with initial value 1
    const homeBannerSwiper = () => { };
    const onHomeBannerSlideChange = () => { };

    // For Number pagination
    const customerSliderSwiper = (swiper) => {
      slideLength.value = swiper.slides.length;
      swiper.on("slideChange", () => {
        currentSlide.value = swiper.realIndex + 1;
      });
    };

    const customerSliderSlideChange = () => { };

    const otherSayAboutUsSwiper = () => { };
    const otherSayAboutUsSlideChange = () => { };
    return {
      currentSlide,
      slideLength,
      homeBannerSwiper,
      onHomeBannerSlideChange,
      customerSliderSwiper,
      customerSliderSlideChange,
      otherSayAboutUsSwiper,
      otherSayAboutUsSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  methods: {
    leftSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const prevButton = currSlider.querySelector(".swiper-button-prev");
      if (prevButton) {
        prevButton.click();
      } else {
        console.error("Previous button not found");
      }
    },
    rightSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const nextButton = currSlider.querySelector(".swiper-button-next");
      if (nextButton) {
        nextButton.click();
      } else {
        console.error("Next button not found");
      }
    },
    moveSliderDefaultPagination() {
      const all_slider_equal_height_default_pagination =
        document.querySelectorAll(".slider_equal_height_default_pagination");
      console.log(all_slider_equal_height_default_pagination);
      all_slider_equal_height_default_pagination.forEach((slider) => {
        const pagination = slider.querySelector(".swiper-pagination");
        slider.appendChild(pagination);
      });
    },
  },
  mounted() {
    this.moveSliderDefaultPagination();
  },
};
</script>
