<template>
  <!-- header start -->

  <!-- ********************* -->
  <!-- Desktop -->
  <!-- ********************* -->
  <header class="desktop">
    <div class="container">
      <div class="header_inner_wrap">
        <div class="inner">
          <router-link to="/" class="logo">
            <img src="../assets/images/logo.svg" alt="NXCLOUD" />
          </router-link>
          <div class="navigation_wrap">
            <div class="inner">
              <nav>
                <ul>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="#">Products</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap">
                      <ul>
                        <li>
                          <a href="#" class="hdng"> Communication </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/product-message">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/message_icon.svg"
                                        alt="chat"
                                      />
                                    </i>
                                    <span class="text">Message</span>
                                  </router-link>
                                </div>

                                <router-link
                                  :to="{
                                    path: '/product-message',
                                    query: { id: 'smsOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="smsOtp"
                                    data-url="../product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_01.svg"
                                        class="tm_hdr_icon"
                                      />
                                      SMS OTP
                                    </h4>
                                    <p>One-time password via SMS</p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/product-message',
                                    query: { id: 'marketingOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="marketingOtp"
                                    data-url="product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_02.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Marketing SMS
                                    </h4>
                                    <p>Promote to your customers</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/product-message',
                                    query: { id: 'notificationOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="notificationOtp"
                                    data-url="../product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_09.svg"
                                        class="tm_hdr_icon"
                                      />
                                      SMS Notification
                                    </h4>
                                    <p>Update your customers</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/product-message',
                                    query: { id: 'emailOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="emailOtp"
                                    data-url="../product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_05.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Email OTP
                                    </h4>
                                    <p>One-time password via Email</p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/product-voice">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/voice_icon.svg"
                                        alt="Voices"
                                      />
                                    </i>
                                    <span class="text">Voice</span>
                                  </router-link>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/product-voice',
                                    query: { id: 'voiceOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="voiceOtp"
                                    data-url="../product-voice"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_06.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Voice OTP
                                    </h4>
                                    <p>One-time password via call</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/product-voice',
                                    query: { id: 'voiceNotification' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="voiceNotification"
                                    data-url="../product-voice"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_03.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Voice Notification
                                    </h4>
                                    <p>Voice-recorded call update</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/product-voice',
                                    query: { id: 'sipTrunk' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="sipTrunk"
                                    data-url="../product-voice"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_07.svg"
                                        class="tm_hdr_icon"
                                      />
                                      SIP Trunk
                                    </h4>
                                    <p>Converse via IP call</p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/product-conversation">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/conversation_icon.svg"
                                        alt="Conversation"
                                      />
                                    </i>
                                    <span class="text">Conversation</span>
                                  </router-link>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/product-conversation',
                                    query: { id: 'superMessaging' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="superMessaging"
                                    data-url="../product-conversation"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_08.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Super Messaging
                                    </h4>
                                    <p>Integrate once for all channels</p>
                                  </div>
                                </router-link>
                                <div class="businesses">
                                  <ul>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-conversation',
                                          query: { id: 'whatsappBusiness' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="whatsappBusiness"
                                          data-url="../product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/whatsapp_clr_icon.svg"
                                              class="tm_hdr_icon"
                                              alt="whatsapp"
                                            />
                                            WhatsApp Business
                                          </h4>
                                          <!-- <p>WhatsApp Business Partner</p> -->
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-conversation',
                                          query: { id: 'viberBusiness' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="viberBusiness"
                                          data-url="../product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/viber_clr_icon.svg"
                                              class="tm_hdr_icon"
                                              alt="viber"
                                            />
                                            Viber Business
                                          </h4>
                                          <!-- <p>Viber Business Partner</p> -->
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-conversation',
                                          query: { id: 'zaloBusiness' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="zaloBusiness"
                                          data-url="../product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/zalo_clr_icon.svg"
                                              class="tm_hdr_icon"
                                              alt="zalo"
                                            />
                                            Zalo Business
                                          </h4>
                                          <!-- <p>Zalo Business Partner</p> -->
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="#" class="hdng"> Application </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/product-fraud-prevention">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/fraud_icon.svg"
                                        alt="fraud"
                                      />
                                    </i>
                                    <span class="text"
                                      >Fraud <br />
                                      Prevention</span
                                    >
                                  </router-link>
                                  <p class="desc">Safeguard your business</p>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/product-fraud-prevention',
                                    query: { id: 'FraudProtector' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="FraudProtector"
                                    data-url="../product-fraud-prevention"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/Fraud_Protector.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Fraud Protector
                                    </h4>
                                    <p>Real-time fraud protection</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/product-fraud-prevention',
                                    query: { id: 'OTA' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="OTA"
                                    data-url="../product-fraud-prevention"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_12.svg"
                                        class="tm_hdr_icon"
                                      />
                                      OTA
                                    </h4>
                                    <p>One-tap silent authentication</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/product-fraud-prevention',
                                    query: { id: 'numberCheck' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="numberCheck"
                                    data-url="../product-fraud-prevention"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_11.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Number Check
                                    </h4>
                                    <p>Targeting wanted consumers</p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/product-global-number">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/hash_icon.svg"
                                        alt="global"
                                      />
                                    </i>
                                    <span class="text"
                                      >Global <br />
                                      Number</span
                                    >
                                  </router-link>
                                  <p class="desc">Dedicated local numbers</p>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/product-global-number',
                                    query: { id: 'privacyNumber' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="privacyNumber"
                                    data-url="../product-global-number"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_10.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Privacy Number
                                    </h4>
                                    <p>Privacy via msked numbers</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/product-global-number',
                                    query: { id: 'globalNumber' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="globalNumber"
                                    data-url="../product-global-number"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_13.svg"
                                        class="tm_hdr_icon"
                                      />
                                      SMS A2P, P2A
                                    </h4>
                                    <p>2-way SMS via local numbers</p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link
                                    to="/product-customer-engagement"
                                  >
                                    <i class="icon">
                                      <img
                                        src="../assets/images/support_icon.svg"
                                        alt="customer_engaging"
                                      />
                                    </i>
                                    <span class="text"
                                      >Customer <br />
                                      Engagement</span
                                    >
                                  </router-link>
                                  <p class="desc">Omnichannels Web service</p>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/product-customer-engagement',
                                    query: { id: 'NXLink' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="NXLink"
                                    data-url="../product-customer-engagement"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_14.svg"
                                        class="tm_hdr_icon"
                                      />
                                      NXLink
                                    </h4>
                                    <!-- <p>Multi-channel sales engagement</p> -->
                                    <p class="dashIcon">
                                      - Effective web based and sales engagement
                                    </p>
                                    <p class="dashIcon" style="margin-top: 5px">
                                      - Empower your business with AI-voice
                                      engagement
                                    </p>
                                    <!-- <ul class="list_style_dots">
                                      <li>
                                        <p>
                                          Effective web based and multi-channel
                                          sales engagement
                                        </p>
                                      </li>
                                      <li>
                                        <p style="margin-top: 5px">
                                          Empower your business with AI-voice
                                          driven customer engagement
                                        </p>
                                      </li>
                                    </ul> -->
                                  </div>
                                </router-link>
                                <!-- <router-link
                                  :to="{
                                    path: '/product-customer-engagement',
                                    query: { id: 'AICC' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="AICC"
                                    data-url="../product-customer-engagement"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_15.svg"
                                        class="tm_hdr_icon"
                                      />
                                      AICC
                                    </h4>
                                    <p>AI-driven voice engagement</p>
                                  </div>
                                </router-link> -->
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="#">Industry</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap md">
                      <ul>
                        <li>
                          <a href="#" class="hdng">Application</a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <ul class="list_with_icon">
                                  <li
                                    :class="{
                                      active: menuActive(
                                        '/industry-e-commerce'
                                      ),
                                    }"
                                  >
                                    <router-link to="/industry-e-commerce">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/E-commerce.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">E-commerce</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-education'),
                                    }"
                                  >
                                    <router-link to="/industry-education">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Education.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text"> Education</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-fintech'),
                                    }"
                                  >
                                    <router-link to="/industry-fintech">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Fintech.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Fintech</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-gaming'),
                                    }"
                                  >
                                    <router-link to="/industry-gaming">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Gaming.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Gaming</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-logistics'),
                                    }"
                                  >
                                    <router-link to="/industry-logistics">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Logistics.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Logistics</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive(
                                        '/industry-online-social'
                                      ),
                                    }"
                                  >
                                    <router-link to="/industry-online-social">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Live_Stream.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Online Social</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-retail'),
                                    }"
                                  >
                                    <router-link to="/industry-retail">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/O2O_Solutions.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Retail</span>
                                      </div>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/document-en">Developer</router-link>
                    </span>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/pricing">Pricing</router-link>
                    </span>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/company">Company</router-link>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="right">
            <router-link to="/login" class="text_btn">Login</router-link>
            <router-link to="/sign-up" class="primary_btn"
              >Get Started</router-link
            >
            <div class="lang">
              <select
                name="lang"
                id="lang"
                @change="languageChangeFun(language)"
                v-model="language"
              >
                <option value="EN" selected>EN</option>
                <option value="CN">中文</option>
              </select>
            </div>
            <button class="toggle-menu"></button>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- ********************* -->
  <!-- Mobile -->
  <!-- ********************* -->
  <header class="mobile">
    <div class="container">
      <div class="header_inner_wrap">
        <div class="inner">
          <router-link to="/" class="logo">
            <img src="../assets/images/logo.svg" alt="NXCLOUD" />
          </router-link>
          <div class="navigation_wrap">
            <div class="inner">
              <nav>
                <ul>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="javascript:;" class="submenu_aWrap">Products</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap">
                      <ul>
                        <li>
                          <a href="javascript:;" class="hdng">
                            Communication
                          </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/product-message">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/message_icon.svg"
                                          alt="chat"
                                        />
                                      </i>
                                      <span class="text">Message</span>
                                    </router-link>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>

                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-message',
                                          query: { id: 'smsOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="smsOtp"
                                          data-url="../product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_01.svg"
                                              class="tm_hdr_icon"
                                            />
                                            SMS OTP
                                          </h4>
                                          <p>Send one-time password</p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-message',
                                          query: { id: 'marketingOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="marketingOtp"
                                          data-url="product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_02.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Marketing SMS
                                          </h4>
                                          <p>
                                            Promotion of brands and <br />
                                            products
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-message',
                                          query: { id: 'notificationOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="notificationOtp"
                                          data-url="../product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_09.svg"
                                              class="tm_hdr_icon"
                                            />
                                            SMS Notification
                                          </h4>
                                          <p>
                                            Notify and update your customers
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-message',
                                          query: { id: 'emailOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="emailOtp"
                                          data-url="../product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_05.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Email OTP
                                          </h4>
                                          <p>Send one-time password</p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/product-voice">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/voice_icon.svg"
                                          alt="Voices"
                                        />
                                      </i>
                                      <span class="text">Voice</span>
                                    </router-link>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-voice',
                                          query: { id: 'voiceOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="voiceOtp"
                                          data-url="../product-voice"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_06.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Voice OTP
                                          </h4>
                                          <p>
                                            Send one-time password via phone
                                            call
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-voice',
                                          query: { id: 'voiceNotification' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="voiceNotification"
                                          data-url="../product-voice"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_03.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Voice Notification
                                          </h4>
                                          <p>
                                            Send updates via voice-recorded
                                            notifications
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-voice',
                                          query: { id: 'sipTrunk' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="sipTrunk"
                                          data-url="../product-voice"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_07.svg"
                                              class="tm_hdr_icon"
                                            />
                                            SIP Trunk
                                          </h4>
                                          <p>
                                            Send and receive calls via Internet
                                            Protocol
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/product-conversation">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/conversation_icon.svg"
                                          alt="Conversation"
                                        />
                                      </i>
                                      <span class="text">Conversation</span>
                                    </router-link>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul full_width">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-conversation',
                                          query: { id: 'superMessaging' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="superMessaging"
                                          data-url="../product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_08.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Super Messaging
                                          </h4>
                                          <p>
                                            Integrate ONCE for access to SMS,
                                            Voice, WhatsApp, Viber, Zalo and
                                            more!
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <div class="businesses">
                                        <ul>
                                          <li>
                                            <router-link
                                              :to="{
                                                path: '/product-conversation',
                                                query: {
                                                  id: 'whatsappBusiness',
                                                },
                                              }"
                                            >
                                              <div
                                                class="text_wrap tabClickLink"
                                                data-tabclick="whatsappBusiness"
                                                data-url="../product-conversation"
                                              >
                                                <h4>
                                                  <img
                                                    src="../assets/images/whatsapp_clr_icon.svg"
                                                    class="tm_hdr_icon"
                                                    alt="whatsapp"
                                                  />
                                                  WhatsApp Business
                                                </h4>
                                                <p>WhatsApp Business Partner</p>
                                              </div>
                                            </router-link>
                                          </li>
                                          <li>
                                            <router-link
                                              :to="{
                                                path: '/product-conversation',
                                                query: { id: 'viberBusiness' },
                                              }"
                                            >
                                              <div
                                                class="text_wrap tabClickLink"
                                                data-tabclick="viberBusiness"
                                                data-url="../product-conversation"
                                              >
                                                <h4>
                                                  <img
                                                    src="../assets/images/viber_clr_icon.svg"
                                                    class="tm_hdr_icon"
                                                    alt="viber"
                                                  />
                                                  Viber Business
                                                </h4>
                                                <p>Viber Business Partner</p>
                                              </div>
                                            </router-link>
                                          </li>
                                          <li>
                                            <router-link
                                              :to="{
                                                path: '/product-conversation',
                                                query: { id: 'zaloBusiness' },
                                              }"
                                            >
                                              <div
                                                class="text_wrap tabClickLink"
                                                data-tabclick="zaloBusiness"
                                                data-url="../product-conversation"
                                              >
                                                <h4>
                                                  <img
                                                    src="../assets/images/zalo_clr_icon.svg"
                                                    class="tm_hdr_icon"
                                                    alt="zalo"
                                                  />
                                                  Zalo Business
                                                </h4>
                                                <p>Zalo Business Partner</p>
                                              </div>
                                            </router-link>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="javascript:;" class="hdng"> Application </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/product-fraud-prevention">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/fraud_icon.svg"
                                          alt="fraud"
                                        />
                                      </i>
                                      <span class="text"
                                        >Fraud <br />
                                        Prevention</span
                                      >
                                    </router-link>
                                    <p class="desc">
                                      Safeguard your business and customers from
                                      malicious activities
                                    </p>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-fraud-prevention',
                                          query: { id: 'numberCheck' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="numberCheck"
                                          data-url="../product-fraud-prevention"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_11.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Number Check
                                          </h4>
                                          <p>
                                            Ensure acquiring and targeting the
                                            wanted consumers
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-fraud-prevention',
                                          query: { id: 'OTA' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="OTA"
                                          data-url="../product-fraud-prevention"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_12.svg"
                                              class="tm_hdr_icon"
                                            />
                                            OTA
                                          </h4>
                                          <p>
                                            Instant and secure access via
                                            one-tap login
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/product-global-number">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/hash_icon.svg"
                                          alt="global"
                                        />
                                      </i>
                                      <span class="text"
                                        >Global <br />
                                        Number</span
                                      >
                                    </router-link>
                                    <p class="desc">
                                      Dedicated local numbers for two-way voice
                                      and SMS
                                    </p>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-global-number',
                                          query: { id: 'privacyNumber' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="privacyNumber"
                                          data-url="../product-global-number"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_10.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Privacy Number
                                          </h4>
                                          <p>
                                            Protect confidentiality and privacy
                                            with masked phone numbers
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-global-number',
                                          query: { id: 'globalNumber' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="globalNumber"
                                          data-url="../product-global-number"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_13.svg"
                                              class="tm_hdr_icon"
                                            />
                                            SMS A2P, P2A
                                          </h4>
                                          <p>
                                            Place and receive global calls with
                                            A2P SMS included at no cost
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link
                                      to="/product-customer-engagement"
                                    >
                                      <i class="icon">
                                        <img
                                          src="../assets/images/support_icon.svg"
                                          alt="customer_engaging"
                                        />
                                      </i>
                                      <span class="text"
                                        >Customer <br />
                                        Engagement</span
                                      >
                                    </router-link>
                                    <p class="desc">
                                      Modules for productive and omnichannels
                                      customer engagement
                                    </p>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-customer-engagement',
                                          query: { id: 'NXLink' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="NXLink"
                                          data-url="../product-customer-engagement"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_14.svg"
                                              class="tm_hdr_icon"
                                            />
                                            NXLink
                                          </h4>
                                          <p>
                                            Effective web based and
                                            multi-channel sales engagement
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/product-customer-engagement',
                                          query: { id: 'AICC' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="AICC"
                                          data-url="../product-customer-engagement"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_15.svg"
                                              class="tm_hdr_icon"
                                            />
                                            AICC
                                          </h4>
                                          <p>
                                            Empower your business with AI-driven
                                            customer engagement
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="javascript:;" class="submenu_aWrap">Industry</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap md">
                      <ul>
                        <li>
                          <a href="javascript:;" class="hdng">Application</a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <ul class="list_with_icon">
                                  <li
                                    :class="{
                                      active: menuActive(
                                        '/industry-e-commerce'
                                      ),
                                    }"
                                  >
                                    <router-link to="/industry-e-commerce">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/E-commerce.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">E-commerce</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-education'),
                                    }"
                                  >
                                    <router-link to="/industry-education">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Education.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text"> Education</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-fintech'),
                                    }"
                                  >
                                    <router-link to="/industry-fintech">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Fintech.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Fintech</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-gaming'),
                                    }"
                                  >
                                    <router-link to="/industry-gaming">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Gaming.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Gaming</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-logistics'),
                                    }"
                                  >
                                    <router-link to="/industry-logistics">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Logistics.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Logistics</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive(
                                        '/industry-online-social'
                                      ),
                                    }"
                                  >
                                    <router-link to="/industry-online-social">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Live_Stream.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Online Social</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li
                                    :class="{
                                      active: menuActive('/industry-retail'),
                                    }"
                                  >
                                    <router-link to="/industry-retail">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/O2O_Solutions.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">Retail</span>
                                      </div>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/document-en">Developer</router-link>
                    </span>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/pricing">Pricing</router-link>
                    </span>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/company">Company</router-link>
                    </span>
                  </li>
                </ul>
              </nav>
              <div class="header_btns_mobile">
                <router-link to="/sign-up" class="primary_btn"
                  >Get Started</router-link
                >
                <router-link to="/login" class="text_btn">Login</router-link>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="lang">
              <select
                name="lang"
                id="lang"
                @change="languageChangeFun(language)"
                v-model="language"
              >
                <option value="EN" selected>EN</option>
                <option value="CN">中文</option>
              </select>
            </div>
            <button class="toggle-menu"></button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderEng",
  data() {
    return {
      language: "EN",
    };
  },
  props: {
    languageChangeFun: Function,
  },
  watch: {
    // language(val, prev) {
    //   console.log(prev);
    //   sessionStorage.setItem("language", val);
    // },
  },
  methods: {
    // Function to update component data with session data
    updateDataFromSession() {
      // Get session data
      const language = sessionStorage.getItem("language");
      // Parse session data if available
      if (language) {
        console.log("session" + language);
        this.language = language;
        this.languageChangeFun(language);
      }
    },
    menuActive(path) {
      return this.$route.fullPath === path;
    },
  },
  created() {
    // this.updateDataFromSession();
  },
};
</script>
