<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">
                    Education Made Better
                  </h1>
                  <p class="cntnt">
                    Streamline operations, reduce costs while elevating
                    customer acquisition and retention rates with our
                    comprehensive communication solution.
                  </p>
                  <router-link to="/sign-up" class="cmn_btn light">
                    Start now
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5" stroke="#85C100"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img src="../../assets/images/industry/education_banner.png" alt="education_banner" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benifits -->
    <section class="benifits">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Empowering Online Education</h2>
        </div>
        <div class="content desktop">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/empowering_online_education.svg" alt="benifit_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>
                      Reduced Operating <br />
                      Cost
                    </h3>
                    <p>
                      Precisely engage global users across varied channels,
                      advertising new courses or services and fulfilling
                      communication needs affordably in a centralized
                      location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/increase_user_acquisition.svg" alt="benifit_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>
                      Increase User <br />
                      Acquisition
                    </h3>
                    <p>
                      Boost marketing efforts by leveraging text, voice,
                      video, and short links to promote online courses.
                      Eliminating the need for manual phone calls,
                      significantly amplifying overall effectiveness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/increase_user_loyalty.svg" alt="benifit_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>
                      Increase User <br />
                      Loyalty
                    </h3>
                    <p>
                      Improve customer satisfaction through text and voice
                      notifications for payments, course purchases, and class
                      schedules.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content mobile">
          <div class="row justify-content-center">
            <div class="ecommerceSolutionsSlider swiperSlider slider_equal_height_no_bg_default_pagination">
              <swiper :modules="modules" :slides-per-view="1" :space-between="20" :equal-height="true"
                :autoplay="{ delay: 5000, disableOnInteraction: true }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="industrySlider" @slideChange="industrySliderSlideChange">
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/empowering_online_education.svg" alt="benifit_img" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>
                            Reduced Operating <br />
                            Cost
                          </h3>
                          <p>
                            Precisely engage global users across varied channels,
                            advertising new courses or services and fulfilling
                            communication needs affordably in a centralized
                            location.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/increase_user_acquisition.svg" alt="benifit_img" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>
                            Increase User <br />
                            Acquisition
                          </h3>
                          <p>
                            Boost marketing efforts by leveraging text, voice,
                            video, and short links to promote online courses.
                            Eliminating the need for manual phone calls,
                            significantly amplifying overall effectiveness.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="cmn_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/increase_user_loyalty.svg" alt="benifit_img" />
                      </div>
                      <div class="text_wrap">
                        <div class="inner">
                          <h3>
                            Increase User <br />
                            Loyalty
                          </h3>
                          <p>
                            Improve customer satisfaction through text and voice
                            notifications for payments, course purchases, and class
                            schedules.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new col_4_1 pt_100 pb_100">
      <div class="container_1700">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_1">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/customer_service.svg" alt="registration_login" />
                </div>
                <div class="text_wrap">
                  <h3>1. Customer Service</h3>
                  <div class="tags_wrap">
                    <router-link :to="{ path: '/product-customer-engagement', query: { id: 'AICC' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="AICC"
                        data-url="../product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/aicc_icon.svg" alt="icon" />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>

                    <router-link to="/product-global-number" class="tag icon_item">
                      <i class="icon">
                        <img src="../../assets/images/customer_journey_section/global_number.svg" alt="icon" />
                      </i>
                      <span class="text">Global Number</span>
                    </router-link>

                    <div class="empty_break"></div>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'whatsappBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="icon" />
                        </i>
                        <span class="text">WhatsApp Business</span> </a>
                    </router-link>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'viberBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="viberBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/viber_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'zaloBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="zaloBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/zalo_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_2">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/registraion_verification.svg"
                    alt="registraion_verification" />
                </div>
                <div class="text_wrap">
                  <h3>2. Registration Verification</h3>
                  <div class="tags_wrap">
                    <router-link :to="{ path: '/product-message', query: { id: 'smsOtp' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="smsOtp"
                        data-url="../product-message">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/sms_otp.svg" alt="icon" />
                        </i>
                        <span class="text">SMS OTP</span> </a>
                    </router-link>
                    <router-link :to="{ path: '/product-voice', query: { id: 'voiceOtp' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="voiceOtp"
                        data-url="../product-voice">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/voice_otp.svg" alt="icon" />
                        </i>
                        <span class="text">Voice OTP</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'whatsappBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="icon" />
                        </i>
                        <span class="text">WhatsApp OTP</span>
                      </a>
                    </router-link>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'viberBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="viberBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/viber_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Viber OTP</span>
                      </a>
                    </router-link>
                    <router-link :to="{ path: '/product-fraud-prevention', query: { id: 'OTA' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="OTA"
                        data-url="../product-fraud-prevention">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/ota_icon.svg" alt="icon" />
                        </i>
                        <span class="text">OTA</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_3">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/course_promotion.svg" alt="course_promotion" />
                </div>
                <div class="text_wrap">
                  <h3>3. Course Promotion</h3>
                  <div class="tags_wrap">
                    <router-link :to="{ path: '/product-message', query: { id: 'marketingOtp' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="marketingOtp"
                        data-url="../product-message">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/marketing_sms.svg" alt="icon" />
                        </i>
                        <span class="text">Marketing SMS</span>
                      </a>
                    </router-link>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'whatsappBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="sms otp" />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'viberBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="viberBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/viber_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Viber Business</span> </a>
                    </router-link>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'zaloBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="zaloBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/zalo_icon.svg" alt="icon" />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link :to="{ path: '/product-customer-engagement', query: { id: 'AICC' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="AICC"
                        data-url="../product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/aicc_icon.svg" alt="icon" />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <router-link to="/product-global-number" class="tag icon_item">
                      <i class="icon">
                        <img src="../../assets/images/customer_journey_section/global_number.svg" alt="icon" />
                      </i>
                      <span class="text">Global Number</span></router-link>
                    <router-link :to="{ path: '/product-customer-engagement', query: { id: 'NXLink' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="NXLink"
                        data-url="../product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/nx_link.svg" alt="icon" />
                        </i>
                        <span class="text">NXLink</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_4">
                <div class="img_wrap">
                  <img src="../../assets/images/customer_journey_section/course_notification.svg"
                    alt="course_notification" />
                </div>
                <div class="text_wrap">
                  <h3>4. Course Notification</h3>
                  <div class="tags_wrap">
                    <router-link :to="{ path: '/product-message', query: { id: 'notificationOtp' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="notificationOtp"
                        data-url="../product-message">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/notification_sms.svg" alt="icon" />
                        </i>
                        <span class="text">Notification SMS</span>
                      </a>
                    </router-link>
                    <router-link :to="{ path: '/product-voice', query: { id: 'voiceNotification' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="voiceNotification"
                        data-url="../product-voice">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/voice_notification.svg" alt="icon" />
                        </i>
                        <span class="text">Voice Notification</span>
                      </a>
                    </router-link>
                    <router-link :to="{ path: '/product-customer-engagement', query: { id: 'AICC' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="AICC"
                        data-url="../product-customer-engagement">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/aicc_icon.svg" alt="icon" />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link to="/product-global-number" class="tag icon_item">
                      <i class="icon">
                        <img src="../../assets/images/customer_journey_section/global_number.svg" alt="icon" />
                      </i>
                      <span class="text">Global Number</span>
                    </router-link>
                    <router-link :to="{ path: '/product-conversation', query: { id: 'whatsappBusiness' } }">
                      <a href="javascript:void(0);" class="tabClickLink tag icon_item" data-tabclick="whatsappBusiness"
                        data-url="../product-conversation">
                        <i class="icon">
                          <img src="../../assets/images/customer_journey_section/whatsapp_icon.svg" alt="icon" />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey_new pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-12">
              <div class="img_box text-center">
                <img src="../../assets/images/industry_education.svg" alt="industry_education" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row justify-content-end">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 1</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 2</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 3</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row flex-row-reverse">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 6</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img src="../../assets/images/product_icon.svg" alt="product_icon" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 7</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recommended products -->
    <section class="product_sec pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Recommended Products</h2>
        </div>
        <div class="content desktop">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/NXLing.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>NXLink</h3>
                  <p>
                    Combining SMS, WhatsApp, and Viber capabilities, our
                    proprietary technology offers seamless communication,
                    segmented customer management, private traffic domain
                    creation for enterprises, and precise handling of
                    post-sales issues within an integrated omni-channel
                    platform.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/aicc-change.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>AICC</h3>
                  <p>
                    Merging AI and SaaS technology with traditional call
                    centers, we offer a comprehensive and intelligent solution
                    through an integrated customer service center equipped
                    with marketing and promotion features.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/Marketing-sms.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>Marketing SMS</h3>
                  <p>
                    Send consumers marketing texts comprising offers, coupons,
                    and promotional events, with attached website or platform
                    links, ensuring precision in customer targeting and
                    acquisition.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img src="../../assets/images/industry/Sms-A2P.svg" alt="product_box_icon" />
                </div>
                <div class="text_wrap">
                  <h3>SMS A2P, P2A</h3>
                  <p>
                    Supplies local fixed-line and mobile numbers for global
                    businesses, offering inbound/outbound voice and A2P SMS
                    services. Users can make and receive calls worldwide,
                    displaying location IDs without chargers for international
                    calls
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content mobile">
          <div class="row justify-content-center">
            <div class="customerSlider swiperSlider numberNav slider_equal_height">
              <swiper :modules="modules" :slides-per-view="1" :space-between="50"
                :autoplay="{ delay: 5000, disableOnInteraction: false }" :loop="true" navigation
                :pagination="{ clickable: true }" @swiper="customerSliderSwiper" @slideChange="customerSliderSlideChange">
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/NXLing.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>NXLink</h3>
                        <p>
                          Combining SMS, WhatsApp, and Viber capabilities, our
                          proprietary technology offers seamless communication,
                          segmented customer management, private traffic domain
                          creation for enterprises, and precise handling of
                          post-sales issues within an integrated omni-channel
                          platform.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/aicc-change.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>AICC</h3>
                        <p>
                          Merging AI and SaaS technology with traditional call
                          centers, we offer a comprehensive and intelligent solution
                          through an integrated customer service center equipped
                          with marketing and promotion features.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/Marketing-sms.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>Marketing SMS</h3>
                        <p>
                          Send consumers marketing texts comprising offers, coupons,
                          and promotional events, with attached website or platform
                          links, ensuring precision in customer targeting and
                          acquisition.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="col-12">
                    <div class="product_box">
                      <div class="img_wrap">
                        <img src="../../assets/images/industry/Sms-A2P.svg" alt="product_box_icon" />
                      </div>
                      <div class="text_wrap">
                        <h3>SMS A2P, P2A</h3>
                        <p>
                          Supplies local fixed-line and mobile numbers for global
                          businesses, offering inbound/outbound voice and A2P SMS
                          services. Users can make and receive calls worldwide,
                          displaying location IDs without chargers for international
                          calls
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <span class="count">{{ currentSlide }} / {{ slideLength }}</span>
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>


<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { ref } from "vue";

export default {
  name: "IndustryEducation",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const currentSlide = ref(1); // Initialize currentSlide with initial value 1
    const slideLength = ref(1); // Initialize slideLength with initial value 1

    const industrySlider = () => { };

    const industrySliderSlideChange = () => { };

    const customerSliderSwiper = (swiper) => {
      slideLength.value = swiper.slides.length;
      swiper.on("slideChange", () => {
        currentSlide.value = swiper.realIndex + 1;
        console.log(currentSlide.value);
      });
    };

    const customerSliderSlideChange = () => {
      console.log("a");
    };

    return {
      currentSlide,
      slideLength,
      industrySlider,
      industrySliderSlideChange,
      customerSliderSwiper,
      customerSliderSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  methods: {
    leftSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const prevButton = currSlider.querySelector(".swiper-button-prev");
      if (prevButton) {
        prevButton.click();
      } else {
        console.error("Previous button not found");
      }
    },
    rightSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const nextButton = currSlider.querySelector(".swiper-button-next");
      if (nextButton) {
        nextButton.click();
      } else {
        console.error("Next button not found");
      }
    },
  },
};
</script>

  

 
