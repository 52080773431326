<template>

    <main>
      <section class="container-fluid homeBanner textDark">
        <div class="container">
          <div class="row d-flex justify-content-center flex-md-reverse">
            <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
              <div class="row">
                <div class="col-12 col-md-7 d-flex align-items-center">
                  <div class="inner">
                    <h1
                      class="hdng"
                    >
                      Send and receive calls anywhere in the world safely
                    </h1>
                    <p
                      class="cntnt"
                    >
                      Phone numbers that protect your business and customers.
                    </p>
                    <router-link to="/sign-up" class="cmn_btn light"> 
                      Start now
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#85C100"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </div>
                <div class="col-12 col-md-5 mt-4 mt-md-0">
                  <img
                    src="../../assets/images/products_applications/product-global-number.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container-fluid benifitSection">
        <div class="container">
          <div class="row d-flex justify-content-center flex-md-reverse">
            <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
              <div class="row">
                <div
                  class="col-12 col-md-12 col-lg-6 d-flex align-items-center"
                >
                  <img
                    src="../../assets/images/products_applications/product_page_img.png"
                    alt=""
                  />
                </div>
                <div
                  class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0" 
                >
                  <div class="inner w-100">
                    <h1
                      class="text-start archived-hdng"
                    >
                    Setting the standards <br>for your safety
                    </h1>

                    <ul class="benifitsList">
                      <li
                      >
                        <strong>Privacy Assured</strong>
                        <span
                          >Caller and respondent numbers remain confidential
                          throughout the transaction, with the private number
                          becoming inactive after the transaction ends.</span
                        >
                      </li>
                      <li
                      >
                        <strong>Versatile</strong>
                        <span>Adaptable and supports AXB, AXYB, AXN. </span>
                      </li>
                      <li
                      >
                        <strong>Customizable</strong>
                        <span
                          >Supports various customizations including recording,
                          binding and more.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container-fluid prod_desc_section pb_200">
        <div class="container">
          <div class="row d-flex justify-content-center flex-md-reverse">
            <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
              <h1
                class="archived-hdng"
              >
                Numbers that you can trust
              </h1>
            </div>
            <div class="col-12">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="tab-1"
                    data-bs-toggle="tab"
                    data-bs-target="#privacyNumber"
                    type="button"
                    role="tab"
                    aria-controls="privacyNumber"
                    aria-selected="true"
                  >
                    <div class="icon_text">
                      <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/Privacy_number.svg"
                        alt="icon"
                      />
                    </i> -->
                      <strong> Privacy Number</strong>
                    </div>
                    <span>
                      Protect confidentiality and privacy with masked phone
                      numbers
                    </span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="tab-2"
                    data-bs-toggle="tab"
                    data-bs-target="#globalNumber"
                    type="button"
                    role="tab"
                    aria-controls="globalNumber"
                    aria-selected="false"
                  >
                    <div class="icon_text">
                      <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/Global_number.svg"
                        alt="icon"
                      />
                    </i> -->
                      <strong> SMS A2P, P2A</strong>
                    </div>
                    <span>
                      Unlock worldwide calling with A2P SMS at zero cost
                    </span>
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="privacyNumber"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                  tabindex="0"
                >
                  <div class="row d-flex justify-content-center">
                    <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                      <span class="tab_btn_desc">
                      Protect confidentiality and privacy with masked phone
                      numbers
                    </span>
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Ride_safer.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Ride Safer</h4>
                      <p class="cntnt">
                        Use a temporary contact via an interim number to
                        safeguard both passenger and driver until the order
                        ends, after which, contact will be discontinued.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Deliver_safer.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Deliver Safer</h4>
                      <p class="cntnt">
                        Offering a temporary contact via an interim number to
                        safeguard both the delivery person and the consignee
                        until the order finishes, after which, contact becomes
                        invalid to preserve privacy.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="globalNumber"
                  role="tabpanel"
                  aria-labelledby="tab-2"
                  tabindex="0"
                >
                  <div class="row d-flex justify-content-center">
                    <div class="col-12 col-sm-10 col-md-4">
                      <span class="tab_btn_desc">
                      Unlock worldwide calling with A2P SMS at zero cost
                    </span>
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Customer_hotline.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Customer Hotline</h4>
                      <p class="cntnt">
                        Displayed on official websites and apps as the
                        authorized contact channel, facilitating prompt
                        resolution of customer inquiries or complaints.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Marketing_and_advertising.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">
                        Marketing <br />
                        and Advertising
                      </h4>
                      <p class="cntnt">
                        Intergrate with our NXCallbot to facilitate global
                        telephone marketing, promoting company offerings and
                        ensuring efficient sales conversion.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Global_meeting _connection.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">
                        Global Meeting <br />
                        Connection
                      </h4>
                      <p class="cntnt">
                        Enables users worldwide to assess the online conference
                        system and multi-party calls using dedicated phone lines
                        for international meetings or interactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="marketingOtp"
                  role="tabpanel"
                  aria-labelledby="tab-3"
                  tabindex="0"
                >
                  <div class="row d-flex justify-content-center">
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Customer_hotline.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Customer Hotline</h4>
                      <p class="cntnt">
                        Displayed on official websites and apps as the
                        authorized contact channel, facilitating prompt
                        resolution of customer inquiries or complaints.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Marketing_and_advertising.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Marketing and Advertising</h4>
                      <p class="cntnt">
                        Intergrate with our NXCallbot to facilitate global
                        telephone marketing, promoting company offerings and
                        ensuring efficient sales conversion.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Global_meeting _connection.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Global Meeting Connection</h4>
                      <p class="cntnt">
                        Enables users worldwide to assess the online conference
                        system and multi-party calls using dedicated phone lines
                        for international meetings or interactions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="archived-card-section hide_temp">
        <div class="container">
          <div class="row row-archived">
            <div class="col-md-12">
              <h3
                class="archived-hdng"
              >
                Testimonial
              </h3>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
              <div
                class="card"
              >
                <div class="card-body">
                  <div class="archived-card-wrap">
                    <img
                      src="../../assets/images/dbs.svg"
                      class="img-fluid"
                      alt="card"
                    />
                    <h4 class="archived-card-hdng">
                      Increased agent productivity by 2.7x
                    </h4>
                    <p class="archived-card-desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                      pharetra congue. Interdum et malesuada
                    </p>

                    <div class="archived-card-numbering-wrap">
                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">3.5B+</h5>
                        <p class="card-number-desc">
                          Increase in <br />
                          marketing opt-in
                        </p>
                      </div>

                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">500K</h5>
                        <p class="card-number-desc">
                          WhatsApp messages <br />
                          per month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
              <div
                class="card"
              >
                <div class="card-body">
                  <div class="archived-card-wrap">
                    <img
                      src="../../assets/images/slack.svg"
                      class="img-fluid"
                      alt="slack"
                    />
                    <h4 class="archived-card-hdng">
                      Increased agent productivity by 2.7x
                    </h4>
                    <p class="archived-card-desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                      pharetra congue. Interdum et malesuada
                    </p>

                    <div class="archived-card-numbering-wrap">
                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">3.5B+</h5>
                        <p class="card-number-desc">
                          Increase in <br />
                          marketing opt-in
                        </p>
                      </div>

                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">500K</h5>
                        <p class="card-number-desc">
                          WhatsApp messages <br />
                          per month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
              <div
                class="card"
              >
                <div class="card-body">
                  <div class="archived-card-wrap">
                    <img
                      src="../../assets/images/hsbc.svg"
                      class="img-fluid"
                      alt="hsbc"
                    />
                    <h4 class="archived-card-hdng">
                      Increased agent productivity by 2.7x
                    </h4>
                    <p class="archived-card-desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                      pharetra congue. Interdum et malesuada
                    </p>

                    <div class="archived-card-numbering-wrap">
                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">3.5B+</h5>
                        <p class="card-number-desc">
                          Increase in <br />
                          marketing opt-in
                        </p>
                      </div>

                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">500K</h5>
                        <p class="card-number-desc">
                          WhatsApp messages <br />
                          per month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cts">
        <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
        <div class="container">
          <div class="row row-cts">
            <div class="col-md-8">
              <h3 class="archived-hdng cts-hdng">
                Start offering your customers a better service experience
              </h3>
            </div>

            <div class="col-md-4">
              <div class="cts-btn">
                <router-link to="/sign-up" class="btn btn-success"> 
                    Get Started
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>

<script>
    export default {
        name: 'ProductGlobalNumber'
    }
</script>

    
