<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">用电话连接全球</h1>
                  <p class="cntnt">可实现在任何地方呼叫和接听各国电话。</p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/product-global-number.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">我们的优势</h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>保护隐私</strong>
                      <span
                        >隐藏主叫、被叫号码，
                        交易过程中不泄漏双方号码；交易结束隐私号码失效。</span
                      >
                    </li>
                    <li>
                      <strong>适用性强</strong>
                      <span
                        >支持AXB、 AXYB 、 AXN 等各种常规号码匹配机制。</span
                      >
                    </li>
                    <li>
                      <strong>管控灵活</strong>
                      <span
                        >提供丰富的自定义配置项，客户可以根据业务需求自主设置超时解绑、<br />录音服务、最大绑定数量等。
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">企业可信赖的全球号码</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#privacyNumber"
                  type="button"
                  role="tab"
                  aria-controls="privacyNumber"
                  aria-selected="true"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/Privacy_number.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> 隐私号码</strong>
                  </div>
                  <span>
                    在不透露用户真实号码信息的前提下进行常规通信业务
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#globalNumber"
                  type="button"
                  role="tab"
                  aria-controls="globalNumber"
                  aria-selected="false"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/Global_number.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> SMS A2P, P2A</strong>
                  </div>
                  <span> 主要用于出海企业客服热线、<br />全球呼叫中心等 </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="privacyNumber"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <span class="tab_btn_desc">
                    在不透露用户真实号码信息的前提下进行常规通信业务
                  </span>
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Ride_safer.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">海外网约车</h4>
                    <p class="cntnt">
                      作为乘客与司机联系的中间号码，订单完
                      <br />成后号码将会失效，双方均不可通过隐私
                      <br />号码联系对方，有效保护双方隐私安全。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Deliver_safer.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">外卖/快递物流</h4>
                    <p class="cntnt">
                      作为配送员与收货人联系的中间号码，<br />收货后号码将会失效，双方均不可通过号码
                      <br />联系对方，有效保护双方隐私安全。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="globalNumber"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <span class="tab_btn_desc"> 主要用于出海企业客服热线、<br />全球呼叫中心等 </span>
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Customer_hotline.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">客服热线</h4>
                    <p class="cntnt">
                      作为企业的官方联系方式，主要应用于海外企业客服热线，供企业为客户及时解答各类咨询与投诉，提供周到满意的服务。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Marketing_and_advertising.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">营销推广</h4>
                    <p class="cntnt">
                      定制企业专属号码，结合智能语音<br />机器人，开展电话销售业务，实现在全球范围内推广公司的产品与服务，<br />促进营销转化。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Global_meeting _connection.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">全球会议号码</h4>
                    <p class="cntnt">
                      应用于企业跨国会议系统的语音<br />电话接入，使全球各地的用户可以<br />通过电话专线进行多方通话，<br />开展跨国会议或交流活动。
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div
                  class="tab-pane fade"
                  id="marketingOtp"
                  role="tabpanel"
                  aria-labelledby="tab-3"
                  tabindex="0"
                >
                  <div class="row d-flex justify-content-center">
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Customer_hotline.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Customer Hotline</h4>
                      <p class="cntnt">
                        Displayed on official websites and apps as the
                        authorized contact channel, facilitating prompt
                        resolution of customer inquiries or complaints.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Marketing_and_advertising.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Marketing and Advertising</h4>
                      <p class="cntnt">
                        Intergrate with our NXCallbot to facilitate global
                        telephone marketing, promoting company offerings and
                        ensuring efficient sales conversion.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Global_meeting _connection.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">Global Meeting Connection</h4>
                      <p class="cntnt">
                        Enables users worldwide to assess the online conference
                        system and multi-party calls using dedicated phone lines
                        for international meetings or interactions.
                      </p>
                    </div>
                  </div>
                </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>    

<script>
export default {
  name: "ZhProductGlobalNumber",
};
</script>
