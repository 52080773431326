<template>
  <main>
    <section class="developer_page desktop">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="sidebar_area_left">
              <div class="search_wrap">
                <form>
                  <input
                    type="text"
                    class="search_field"
                    placeholder="Document Keyword search..."
                    v-model="docSearchVal"
                    @input="docSearchFun"
                  />
                  <button class="search_btn"></button>
                </form>
                <p>
                  <i>
                    <a
                      href="https://github.com/nxtele/nxcloud-doc-en/wiki"
                      target="_blank"
                      >Click Here For More Updates</a
                    >
                  </i>
                </p>
              </div>
              <div class="sidebar_options_wrap">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_message"
                      aria-expanded="true"
                      aria-controls="collapse_message"
                    >
                      <img src="../../assets/images/chat_dark.svg" />
                      <img
                        src="../../assets/images/message_hovered.svg"
                        class="hovered"
                      />
                      消息
                    </button>
                    <div
                      id="collapse_message"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/chat_lock.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>SMS OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/graph_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Marketing SMS</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/bell_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>SMS Notification</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/bell_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Email OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Email-Verification-Code"
                            >
                              Send Email Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Email-Verification-Code-Receipt-Callback"
                            >
                              Email Verification Code Receipt Callback
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Email-Verification-Code-Record-Query"
                            >
                              Email Verification Code Record Query
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/others_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Others</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Billing-Standards"
                            >
                              Billing Standards
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Sending"
                            >
                              SMS Sending
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-conversion-rate-Reporting"
                            >
                              Report Conversion
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Delivery-Receipt-Webhook"
                            >
                              Delivery Receipt Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Single-Record-Query"
                            >
                              Batch Record Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Single-Record-Query"
                            >
                              Single Record Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Report"
                            >
                              SMS Report
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_voice"
                      aria-expanded="false"
                      aria-controls="collapse_voice"
                    >
                      <img src="../../assets/images/voice_dark.svg" />
                      <img
                        src="../../assets/images/voice_hovered.svg"
                        class="hovered"
                      />
                      语音
                    </button>
                    <div
                      id="collapse_voice"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Voice OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Voice-Verification-Code"
                            >
                              Send Voice Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Voice-Verification-Code-Fill-Report"
                            >
                              Voice Verification Code Fill Report
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/speaker_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>Voice Notification </b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Voice-Broadcast"
                            >
                              Send Voice Broadcast
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Voice-Notification"
                            >
                              Send Voice Notification
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/bell_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>SIP Trunk</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/others_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Others</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Upload-Voice-Recording-File"
                            >
                              Upload Voice Recording File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Uploaded-Recording-Files"
                            >
                              Query Uploaded Recording Files
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Voice-Receipt-Callback"
                            >
                              Voice Receipt Callback
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Voice-Record-Query"
                            >
                              Voice Record Query
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_flash_call"
                      aria-expanded="false"
                      aria-controls="collapse_flash_call"
                    >
                      <img src="../../assets/images/conversation_icon_2.svg" />
                      <img
                        src="../../assets/images/conversation_hovered.svg"
                        class="hovered"
                      />
                      互动对话
                    </button>
                    <div
                      id="collapse_flash_call"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/super_msg_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Super Messaging</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>WhatsApp Business</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-mt"
                            >
                              Send Message
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-markMessageAsRead"
                            >
                              Mark Inbound Message as Read
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-uploadMedia"
                            >
                              Upload Media File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getMedia"
                            >
                              Get Media File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-deleteMedia"
                            >
                              Delete Media File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getPhone"
                            >
                              Get Phone Number Information
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getTemplate"
                            >
                              Get Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-createTemplate"
                            >
                              Create Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-updateTemplate"
                            >
                              Update Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-deleteTemplate"
                            >
                              Delete Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-uploadTemplateFile"
                            >
                              Upload Template Example File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-login"
                            >
                              Embedded Page Login
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-createApp"
                            >
                              Create Client App
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-phoneList"
                            >
                              Phone List of Client App
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getCode"
                            >
                              Get Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-checkCode"
                            >
                              Verify Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-createClient"
                            >
                              Create Local Client
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap2_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Viber Business</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Viber-API-mt"
                            >
                              Send Message
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Viber-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/zolo_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Zalo Business</b></span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_short_link"
                      aria-expanded="false"
                      aria-controls="collapse_short_link"
                    >
                      <img src="../../assets/images/fraud_icon_2.svg" />
                      <img
                        src="../../assets/images/fraud__hovered.svg"
                        class="hovered"
                      />
                      防欺诈策略
                    </button>
                    <div
                      id="collapse_short_link"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/frequency_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Number Check</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/hand_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>OTA</b></span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_email_verification"
                      aria-expanded="false"
                      aria-controls="collapse_email_verification"
                    >
                      <img src="../../assets/images/global-number_icon_2.svg" />
                      <img
                        src="../../assets/images/number_hovered.svg"
                        class="hovered"
                      />
                      全球号码
                    </button>
                    <div
                      id="collapse_email_verification"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/glasses_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Privacy Number</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-bind"
                            >
                              Submit AB Binding
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-unbind"
                            >
                              Unbind AB Binding
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-query"
                            >
                              Query Opponent Number by A or B
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-list"
                            >
                              Query AXB Relationship List
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-create-G"
                            >
                              Create Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-bind-AXG"
                            >
                              Submit AXG Binding Relationship
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-list-G"
                            >
                              Query Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-update-G"
                            >
                              Update Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-delete-G"
                            >
                              Delete Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-Error-Code"
                            >
                              Error Codes
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/both_side_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>SMS A2P, P2A</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Sending-SMS-via-DID-Number-V2"
                            >
                              Sending SMS via DID phone number
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="DID-SMS-Callback-(Signed)"
                            >
                              SMS webhook (Signed)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="DID-SMS-Callback-(Unsigned)"
                            >
                              SMS webhook (Unsigned)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="DID-API-Dialout-With-Amazon-Connect"
                            >
                              DID API Dialout With Amazon Connect
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/others_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Others</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Check-API-submit"
                            >
                              Number Check API Submit
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Check-API-query"
                            >
                              Number Check API Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Check-API-callback"
                            >
                              Number Check API Callback
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#customer-engagment"
                      aria-expanded="false"
                      aria-controls="customer-engagment"
                    >
                      <img
                        src="../../assets/images/customer_engagement_icon_2.svg"
                      />
                      <img
                        src="../../assets/images/customer_engagement_hovered.svg"
                        class="hovered"
                      />
                      客户互动
                    </button>
                    <div
                      id="customer-engagment"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>NX Link</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-WhatsApp-API-send"
                            >
                              Send WhatsApp Message
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-WhatsApp-API-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-saas_plat-openapi-batch_create"
                            >
                              Batch Create Customers
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-saas_plat-openapi-customer_fields"
                            >
                              Query Customer Fields
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>AICC</b></span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_others"
                      aria-expanded="false"
                      aria-controls="collapse_others"
                    >
                      <img src="../../assets/images/others_dark.svg" />
                      <img
                        src="../../assets/images/others_dark_hovered.svg"
                        class="hovered"
                      />
                      Others
                    </button>
                    <div
                      id="collapse_others"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/exclamation_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>General</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="generate_shortlink"
                            >
                              Generate Short Link
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="query_short_link"
                            >
                              Query Short Link
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="balance_inquiry"
                            >
                              Balance Inquiry
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/agency_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Agent (Legacy)</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="nxphone-phone-usage-instructions"
                            >
                              NXphone PC Usage Instructions
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="nxphone-android"
                            >
                              NXphone Android Usage Instructions
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXphone-Android-SDK-Function-Description-Document"
                            >
                              NXphone-SDK Function Description Document
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Agent-System-SIP-Link-Invocation-Method-(Recommended)"
                            >
                              Agent System SIP Link Invocation Method
                              (Recommended)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Agent-API-Invocation-Interface-Specification-(V1.0)"
                            >
                              Agent API Invocation Interface Specification
                              (V1.0)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Desensitization-Process"
                            >
                              Number Desensitization Process
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Modify-Phone-Password-Interface"
                            >
                              Modify Phone Password Interface
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Call-Detail-Records-(CDR)-by-Order-ID"
                            >
                              Query Call Detail Records (CDR) by Order ID
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Call-Detail-Records-(CDR)-Callback-Interface-Specification-(V1.0)"
                            >
                              Call Detail Records (CDR) Callback Interface
                              Specification (V1.0)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Approval-Form"
                            >
                              Query Approval Form
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Phone"
                            >
                              Query Phone
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Explanation-of-Call-Hangup-Reasons"
                            >
                              Explanation of Call Hangup Reasons
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/support_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Call Center</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Call-center-sdk"
                            >
                              Call Center SDK
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXCC-API-CDR-Query"
                            >
                              Call Detail Record Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXCC-API-Webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXCC-API-CreateCallTask"
                            >
                              Create AI Outbound Task
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="resultNotFound" class="resultNotFound">
                  <p class="alert alert-danger" role="alert">
                    Sorry, we couldn't find any results
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <!-- <div class="center_area hide_temp">
              <img src="../../assets/images/centre_image.png" class="w-100" />
            </div> -->
            <div class="center_area">
              <div class="git_section_area">
                <!-- <div class="markdown-body" v-html="renderedMarkdown"></div> -->
              </div>
              <div class="pageLoad_loader" style="display: none"></div>
            </div>
          </div>
          <div class="col-lg-3 hide_temp">
            <div class="sidebar_area_right">
              <div class="table_content">
                <h2>Table of contents</h2>
                <ul>
                  <li>Brief Description</li>
                  <li>Request Method</li>
                  <li>Request Example</li>
                  <li>Response Example</li>
                  <li>Response Parameter Description</li>
                  <li>Note</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="developer_page xs_mobile">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="sidebar_area_left">
              <div class="search_wrap">
                <form>
                  <input
                    v-if="showSearch"
                    type="text"
                    class="search_field"
                    placeholder="Document Keyword search..."
                    v-model="docSearchVal"
                    @input="docSearchFun"
                  />
                  <div v-else class="selections" @click="openSidebarOptions">
                    Generate Short Link
                  </div>
                  <button
                    class="search_btn"
                    type="button"
                    @click="toggleSearch"
                  ></button>
                </form>
                <p>
                  <i>
                    <a
                      href="https://github.com/nxtele/nxcloud-doc-en/wiki"
                      target="_blank"
                      >Click Here For More Updates</a
                    >
                  </i>
                </p>
              </div>
              <div class="sidebar_options_wrap">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_message"
                      aria-expanded="true"
                      aria-controls="collapse_message"
                    >
                      <img src="../../assets/images/chat_dark.svg" />
                      <img
                        src="../../assets/images/message_hovered.svg"
                        class="hovered"
                      />
                      消息
                    </button>
                    <div
                      id="collapse_message"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/chat_lock.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>SMS OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/graph_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Marketing SMS</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/bell_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>SMS Notification</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/bell_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Email OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Email-Verification-Code"
                            >
                              Send Email Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Email-Verification-Code-Receipt-Callback"
                            >
                              Email Verification Code Receipt Callback
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Email-Verification-Code-Record-Query"
                            >
                              Email Verification Code Record Query
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/others_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Others</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Billing-Standards"
                            >
                              Billing Standards
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Sending"
                            >
                              SMS Sending
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-conversion-rate-Reporting"
                            >
                              Report Conversion
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Delivery-Receipt-Webhook"
                            >
                              Delivery Receipt Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Single-Record-Query"
                            >
                              Batch Record Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Single-Record-Query"
                            >
                              Single Record Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SMS-Report"
                            >
                              SMS Report
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_voice"
                      aria-expanded="false"
                      aria-controls="collapse_voice"
                    >
                      <img src="../../assets/images/voice_dark.svg" />
                      <img
                        src="../../assets/images/voice_hovered.svg"
                        class="hovered"
                      />
                      语音
                    </button>
                    <div
                      id="collapse_voice"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Voice OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Voice-Verification-Code"
                            >
                              Send Voice Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Voice-Verification-Code-Fill-Report"
                            >
                              Voice Verification Code Fill Report
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/speaker_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>Voice Notification </b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Voice-Broadcast"
                            >
                              Send Voice Broadcast
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Send-Voice-Notification"
                            >
                              Send Voice Notification
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/bell_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>SIP Trunk</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/others_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Others</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Upload-Voice-Recording-File"
                            >
                              Upload Voice Recording File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Uploaded-Recording-Files"
                            >
                              Query Uploaded Recording Files
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Voice-Receipt-Callback"
                            >
                              Voice Receipt Callback
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Voice-Record-Query"
                            >
                              Voice Record Query
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_flash_call"
                      aria-expanded="false"
                      aria-controls="collapse_flash_call"
                    >
                      <img src="../../assets/images/conversation_icon_2.svg" />
                      <img
                        src="../../assets/images/conversation_hovered.svg"
                        class="hovered"
                      />
                      互动对话
                    </button>
                    <div
                      id="collapse_flash_call"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/super_msg_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Super Messaging</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>WhatsApp Business</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-mt"
                            >
                              Send Message
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-markMessageAsRead"
                            >
                              Mark Inbound Message as Read
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-uploadMedia"
                            >
                              Upload Media File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getMedia"
                            >
                              Get Media File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-deleteMedia"
                            >
                              Delete Media File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getPhone"
                            >
                              Get Phone Number Information
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getTemplate"
                            >
                              Get Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-createTemplate"
                            >
                              Create Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-updateTemplate"
                            >
                              Update Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-deleteTemplate"
                            >
                              Delete Message Template
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-uploadTemplateFile"
                            >
                              Upload Template Example File
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-login"
                            >
                              Embedded Page Login
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-createApp"
                            >
                              Create Client App
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-phoneList"
                            >
                              Phone List of Client App
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-getCode"
                            >
                              Get Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-checkCode"
                            >
                              Verify Verification Code
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="WhatsApp-API-createClient"
                            >
                              Create Local Client
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap2_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Viber Business</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Viber-API-mt"
                            >
                              Send Message
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Viber-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/zolo_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Zalo Business</b></span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_short_link"
                      aria-expanded="false"
                      aria-controls="collapse_short_link"
                    >
                      <img src="../../assets/images/fraud_icon_2.svg" />
                      <img
                        src="../../assets/images/fraud__hovered.svg"
                        class="hovered"
                      />
                      防欺诈策略
                    </button>
                    <div
                      id="collapse_short_link"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/frequency_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Number Check</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/hand_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>OTA</b></span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_email_verification"
                      aria-expanded="false"
                      aria-controls="collapse_email_verification"
                    >
                      <img src="../../assets/images/global-number_icon_2.svg" />
                      <img
                        src="../../assets/images/number_hovered.svg"
                        class="hovered"
                      />
                      全球号码
                    </button>
                    <div
                      id="collapse_email_verification"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/glasses_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Privacy Number</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-bind"
                            >
                              Submit AB Binding
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-unbind"
                            >
                              Unbind AB Binding
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-query"
                            >
                              Query Opponent Number by A or B
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-list"
                            >
                              Query AXB Relationship List
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-create-G"
                            >
                              Create Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-bind-AXG"
                            >
                              Submit AXG Binding Relationship
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-list-G"
                            >
                              Query Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-update-G"
                            >
                              Update Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-delete-G"
                            >
                              Delete Number Group G
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="PNS-API-Error-Code"
                            >
                              Error Codes
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/both_side_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>SMS A2P, P2A</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Sending-SMS-via-DID-Number-V2"
                            >
                              Sending SMS via DID phone number
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="DID-SMS-Callback-(Signed)"
                            >
                              SMS webhook (Signed)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="DID-SMS-Callback-(Unsigned)"
                            >
                              SMS webhook (Unsigned)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="DID-API-Dialout-With-Amazon-Connect"
                            >
                              DID API Dialout With Amazon Connect
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/others_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Others</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Check-API-submit"
                            >
                              Number Check API Submit
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Check-API-query"
                            >
                              Number Check API Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Check-API-callback"
                            >
                              Number Check API Callback
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#customer-engagment"
                      aria-expanded="false"
                      aria-controls="customer-engagment"
                    >
                      <img
                        src="../../assets/images/customer_engagement_icon_2.svg"
                      />
                      <img
                        src="../../assets/images/customer_engagement_hovered.svg"
                        class="hovered"
                      />
                      客户互动
                    </button>
                    <div
                      id="customer-engagment"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>NX Link</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-WhatsApp-API-send"
                            >
                              Send WhatsApp Message
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-WhatsApp-API-webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-saas_plat-openapi-batch_create"
                            >
                              Batch Create Customers
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="SAAS-saas_plat-openapi-customer_fields"
                            >
                              Query Customer Fields
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>AICC</b></span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_others"
                      aria-expanded="false"
                      aria-controls="collapse_others"
                    >
                      <img src="../../assets/images/others_dark.svg" />
                      <img
                        src="../../assets/images/others_dark_hovered.svg"
                        class="hovered"
                      />
                      Others
                    </button>
                    <div
                      id="collapse_others"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/exclamation_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>General</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="generate_shortlink"
                            >
                              Generate Short Link
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="query_short_link"
                            >
                              Query Short Link
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="balance_inquiry"
                            >
                              Balance Inquiry
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/agency_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Agent (Legacy)</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="nxphone-phone-usage-instructions"
                            >
                              NXphone PC Usage Instructions
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="nxphone-android"
                            >
                              NXphone Android Usage Instructions
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXphone-Android-SDK-Function-Description-Document"
                            >
                              NXphone-SDK Function Description Document
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Agent-System-SIP-Link-Invocation-Method-(Recommended)"
                            >
                              Agent System SIP Link Invocation Method
                              (Recommended)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Agent-API-Invocation-Interface-Specification-(V1.0)"
                            >
                              Agent API Invocation Interface Specification
                              (V1.0)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Number-Desensitization-Process"
                            >
                              Number Desensitization Process
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Modify-Phone-Password-Interface"
                            >
                              Modify Phone Password Interface
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Call-Detail-Records-(CDR)-by-Order-ID"
                            >
                              Query Call Detail Records (CDR) by Order ID
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Call-Detail-Records-(CDR)-Callback-Interface-Specification-(V1.0)"
                            >
                              Call Detail Records (CDR) Callback Interface
                              Specification (V1.0)
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Approval-Form"
                            >
                              Query Approval Form
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Query-Phone"
                            >
                              Query Phone
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Explanation-of-Call-Hangup-Reasons"
                            >
                              Explanation of Call Hangup Reasons
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/support_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Call Center</b></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="Call-center-sdk"
                            >
                              Call Center SDK
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXCC-API-CDR-Query"
                            >
                              Call Detail Record Query
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXCC-API-Webhook"
                            >
                              Webhook
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              data-github="NXCC-API-CreateCallTask"
                            >
                              Create AI Outbound Task
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="resultNotFound" class="resultNotFound">
                  <p class="alert alert-danger" role="alert">
                    Sorry, we couldn't find any results
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <!-- <div class="center_area hide_temp">
              <img src="../../assets/images/centre_image.png" class="w-100" />
            </div> -->
            <div class="center_area">
              <div class="git_section_area">
                <!-- <div class="markdown-body" v-html="renderedMarkdown"></div> -->
              </div>
              <div class="pageLoad_loader" style="display: none"></div>
            </div>
          </div>
          <div class="col-lg-3 hide_temp">
            <div class="sidebar_area_right">
              <div class="table_content">
                <h2>Table of contents</h2>
                <ul>
                  <li>Brief Description</li>
                  <li>Request Method</li>
                  <li>Request Example</li>
                  <li>Response Example</li>
                  <li>Response Parameter Description</li>
                  <li>Note</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供<br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import MarkdownIt from "markdown-it";
// const md = new MarkdownIt();

export default {
  name: "ZhDeveloperPage",

  data() {
    return {
      markdownContent: "",
      renderedMarkdown: "",
      currBtnData: "",
      docSearchVal: "",
      resultNotFound: false,
      showSearch: false,
      githubPagesPath: "/github_pages/",
    };
  },

  methods: {
    // async getDataApi(url) {
    //     try {
    //         console.log("url: ", url);
    //         // Assuming url includes the correct path
    //         const markdownFile = await import(`!!raw-loader!/${url}.md`);
    //         this.markdownContent = markdownFile.default;
    //         this.renderedMarkdown = md.render(this.markdownContent);
    //     } catch (error) {
    //         console.error("Error loading Markdown file:", error);
    //     }
    // },

    // html append from file into center area appendDataToCenterArea()
    appendDataToCenterArea(currGitArea, htmlContent) {
      currGitArea.innerHTML = htmlContent;
    },

    // getDataApi function for get HTML data from github pages  {github pages placed in public folder}
    async getDataApi(currGitArea, dataFile) {
      try {
        // Fetching HTML content
        const response = await fetch(`${this.githubPagesPath}${dataFile}.html`);

        // Check if response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Storing HTML content as text
        const htmlContent = await response.text();
        this.appendDataToCenterArea(currGitArea, htmlContent);
      } catch (error) {
        console.error("Error loading file:", error);
      }
    },

    // click to sidebar options & get HTML files data
    handleClick(event) {
      event.preventDefault();
      const currBtn = event.currentTarget;
      const currDeveloperPageSec = currBtn.closest(".developer_page");
      const currGitArea =
        currDeveloperPageSec.querySelector(".git_section_area");
      const currGitData = currBtn.getAttribute("data-github");
      this.currBtnData = currGitData;
      if (this.currBtnData) {
        this.getDataApi(currGitArea, this.currBtnData);
      }
      if (currDeveloperPageSec.classList.contains("xs_mobile")) {
        currDeveloperPageSec
          .querySelector(".sidebar_area_left ")
          .classList.remove("showOptions");
      }
    },
    docSearchFun(event) {
      const currentTarget = event.target;
      const currDeveloperPageSec = currentTarget.closest(".developer_page ");
      const sidebar_area_left = currDeveloperPageSec.querySelector(
        ".sidebar_area_left "
      );

      const sidebar_accordion_items =
        sidebar_area_left.querySelectorAll(".accordion-item");
      const sidebar_accordion_ul = sidebar_area_left.querySelectorAll("ul");
      const sidebar_all_options = sidebar_area_left.querySelectorAll("li");
      let docSearchVal = this.docSearchVal;
      docSearchVal = docSearchVal.toLowerCase();

      let optionTextNode, optionText, anchor;
      sidebar_all_options.forEach((option) => {
        if (option.querySelector(".text b")) {
          optionText = option
            .querySelector(".text b")
            .textContent.toLowerCase();
          optionTextNode = optionText;
        } else {
          anchor = option.querySelector("a").textContent.toLowerCase();
          optionTextNode = anchor;
        }
        // check list item's inner text with input's value
        if (optionTextNode.indexOf(docSearchVal) != -1) {
          option.classList.add("show");
          option.classList.remove("hide");
        } else {
          option.classList.add("hide");
          option.classList.remove("show");
        }
      });

      // hide sidebar_accordion_item if all options are hide
      sidebar_accordion_ul.forEach((ul) => {
        const liAreFound = ul.querySelectorAll("li.show");
        if (!liAreFound.length) {
          ul.closest(".accordion-item").classList.add("hide");
          ul.closest(".accordion-item").classList.remove("show");
        } else {
          ul.closest(".accordion-item").classList.add("show");
          ul.closest(".accordion-item").classList.remove("hide");
        }
      });

      // show error if search not found
      let allItemsHide = true;
      sidebar_accordion_items.forEach((item) => {
        if (item.classList.contains("show")) {
          allItemsHide = false;
          return;
        }
      });
      this.resultNotFound = allItemsHide;

      // showOptionsFor mobile
      if (docSearchVal.length) {
        sidebar_area_left.classList.add("showOptions");
      } else {
        sidebar_area_left.classList.remove("showOptions");
      }
    },

    toggleSearch() {
      if (this.showSearch == false) {
        this.showSearch = true;
      } else {
        this.showSearch = false;
      }
    },
    openSidebarOptions(event) {
      const currentTarget = event.target;
      const sidebarAreaLeft = currentTarget.closest(".sidebar_area_left");
      sidebarAreaLeft.classList.toggle("showOptions");
    },
  },

  mounted() {
    let allDeveloperSec = document.querySelectorAll(".developer_page");
    let sidebarOptions;
    allDeveloperSec.forEach((devSec) => {
      sidebarOptions = devSec.querySelectorAll(
        ".sidebar_options_wrap [data-github]"
      );
      sidebarOptions.forEach((option) => {
        option.addEventListener("click", this.handleClick);
      });
      // Trigger click event on the first sidebar option
      if (sidebarOptions.length > 0) {
        sidebarOptions[0].click();
      }
    });
  },
};
</script>
