<template>
  <HeaderEng :languageChangeFun="languageChange" v-if="isLangEng" />
  <HeaderCh :languageChangeFun="languageChange" v-if="!isLangEng" />
  <router-view></router-view>
  <FooterEng :languageChangeFun="languageChange" v-if="isLangEng" />
  <FooterCh :languageChangeFun="languageChange" v-if="!isLangEng" />
</template>

<script>
import $ from "jquery";
import HeaderEng from "./HeaderEng.vue";
import HeaderCh from "./HeaderCh.vue";
import FooterEng from "./FooterEng.vue";
import FooterCh from "./FooterCh.vue";

export default {
  name: "HeaderGlobal",
  components: {
    HeaderEng,
    HeaderCh,
    FooterEng,
    FooterCh,
  },
  data() {
    return {
      isLangEng: true,
    };
  },
  methods: {
    mobileHeaderInitToggleBtn() {
      // Main Submenu button Toggle in header
      $(document).click(function (event) {
        if ($(event.target).hasClass("toggle-menu")) {
          console.log("a");
          $(event.target).toggleClass("active");
          $("header").toggleClass("active");
          $("html, body").toggleClass("overflow-hidden");
        }
      });
    },
    addSubmenuItemsIconClick() {
      // Submenu icon click
      $(document).click(function (event) {
        if ($(event.target).hasClass("submenu_arrow")) {
          $(event.target).toggleClass("active");
          $(event.target)
            .closest(".has_submenu")
            .find(".submenu")
            .slideToggle();
        }
      });
    },
    addNestedDropdownTrigger() {
      // Submenu icon click
      $(document).click(function (event) {
        if ($(event.target).hasClass("nested_dropdown_trigger")) {
          $(event.target).toggleClass("active");
          $(event.target)
            .closest(".col")
            .find(".nested_dropdown")
            .slideToggle();
        }
      });
    },
    hideOpenedHeaderOnPageLoad() {
      //   hide opened menu for devices under 1200px
      if ($(window).width() < 1200) {
        if ($("header").hasClass("active")) {
          $("header").removeClass("active");
        }
        if ($("header .toggle-menu").hasClass("active")) {
          $("header .toggle-menu").removeClass("active");
        }
        if ($("header .submenu_arrow").hasClass("active")) {
          $("header .submenu_arrow").removeClass("active");
        }
        if ($("header .nested_dropdown_trigger").hasClass("active")) {
          $("header .nested_dropdown_trigger").removeClass("active");
        }
        if ($("html, body").hasClass("overflow-hidden")) {
          $("html, body").removeClass("overflow-hidden");
        }
        $("header .has_submenu .submenu").slideUp();
        $("header .nested_dropdown").slideUp();
      }
    },
    languageChange(language) {
      if (language === "EN") {
        this.isLangEng = true;
      } else {
        this.isLangEng = false;
      }
      this.changePageAccordingLang();
    },
    changePageAccordingLang() {
      let currPath = this.$route.path;
      let currPathWithoutSlash = currPath.substring(1);
      let dynRoot = "";
      // Check if the current route has a path
      if (currPathWithoutSlash && currPathWithoutSlash != "zh") {
        // means it is not home page
        if (this.isLangEng == true) {
          // Redirect to English version route
          dynRoot = currPathWithoutSlash.substring(3);
          this.$router.push({
            path: "/" + dynRoot,
          });
        } else {
          // Redirect to Chinese version route
          dynRoot = "/zh-" + currPathWithoutSlash;
          this.$router.push({
            path: dynRoot,
          });
        }
      } else {
        // means it is home page
        if (this.isLangEng == true) {
          // Redirect to English version route
          this.$router.push({
            path: "/",
          });
        } else {
          // Redirect to Chinese version route
          this.$router.push({
            path: "/zh",
          });
        }
      }
    },
  },
  created() {
    this.mobileHeaderInitToggleBtn();
    this.addSubmenuItemsIconClick();
    this.addNestedDropdownTrigger();
  },
  mounted() {
    this.hideOpenedHeaderOnPageLoad();
    this.$router.afterEach(() => {
      this.hideOpenedHeaderOnPageLoad();
    });
  },
};
</script>
