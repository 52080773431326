<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">Safety first, always</h1>
                  <p class="cntnt">
                    Prevent fraudulent activities to keep your business and
                    customers safe.
                  </p>
                  <router-link to="/sign-up" class="cmn_btn light">
                    Start now
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/Fraud_prevention_banner.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">Reach real people</h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>Empty Caller ID Identification</strong>
                      <span>Detect blank or empty caller identification. </span>
                    </li>
                    <li>
                      <strong>Location ID Identification</strong>
                      <span
                        >Precisely identify the caller's country through
                        reliable country ID recognition.
                      </span>
                    </li>
                    <li>
                      <strong>Operator Identification</strong>
                      <span
                        >Identify and display operator information linked to
                        incoming calls.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">One tap is all you need</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#FraudProtector"
                  type="button"
                  role="tab"
                  aria-controls="FraudProtector"
                  aria-selected="true"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/OTA.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> Fraud Protector </strong>
                  </div>
                  <span> Your sentinel against fraud </span>
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#OTA"
                  type="button"
                  role="tab"
                  aria-controls="OTA"
                  aria-selected="true"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/OTA.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> OTA </strong>
                  </div>
                  <span>
                    Put your customers' safety first with our one-tap
                    authentication services
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#numberCheck"
                  type="button"
                  role="tab"
                  aria-controls="numberCheck"
                  aria-selected="false"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/Number_check.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> Number Check</strong>
                  </div>
                  <span>
                    Segment and analyze customer phone numbers to identify
                    potential risks
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade"
                id="FraudProtector"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/Protection.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Codeless <br />
                      Protection
                    </h4>
                    <p class="cntnt">
                      Dynamic dashboard generates blocking rules to combat new
                      fraud or suspicious traffic.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/Real_Time.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Real Time <br />
                      Monitoring
                    </h4>
                    <p class="cntnt">
                      Proactively preventing fraud and risk by constantly
                      monitoring 24/7 for customer safety.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/Binocular.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Constant <br />
                      Vigilance
                    </h4>
                    <p class="cntnt">
                      Detects uncommon SMS patterns, enforces blocking, alerts,
                      or suspends traffic to manage risk.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="OTA"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Seamless_customer_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Seamless User <br />
                      Experience
                    </h4>
                    <p class="cntnt">
                      Streamline the login process by eliminating the need for
                      users to manually enter usernames and passwords. With a
                      single tap or click, users can access their accounts
                      swiftly, enhancing the overall user experience.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Advanced_security_measures.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Advanced Security <br />
                      Measures
                    </h4>
                    <p class="cntnt">
                      Utilise biometric verification or secure tokens stored on
                      the device will help to ensure authorized access and
                      prevent unauthorized logins.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Frictionless_experience.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Frictionless Experience</h4>
                    <p class="cntnt">
                      Effectively reduce the number of steps required to
                      authenticate, encouraging users to engage more frequently
                      with applications and services, ultimately boosting user
                      retention and satisfaction.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade show active"
                id="numberCheck"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Authentic_promotions.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Authentic Promotions</h4>
                    <p class="cntnt">
                      Enhance marketing precision and increase sales conversions
                      by using our number check service. Screen bulk user
                      numbers before sending SMS or group voice call promotions.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Approve_better.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Approve Better</h4>
                    <p class="cntnt">
                      Verify and authenticate applicant-provided mobile numbers
                      during credit card applications, crucial in the
                      decision-making process for approving or declining the
                      issuance of credit cards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ProductFraudPrevention",
};
</script>
