<template>

  <main>

    <section class="terms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="terms-hdng">NXCLOUD Privacy Policy</h1>
            <p class="terms-desc mb-0">Update date: February 6th, 2023</p>
            <p class="terms-desc term-days">Effective date:February 6th, 2023</p>

            <h2 class="terms-list-hdng privacy-hdng">Introduction</h2>
            <p class="terms-desc">Welcome you to use the products and/or services provided by NXCLOUD ASIA PTE. LTD.
              (hereinafter referred to as "NXCLOUD" or "We") and its affiliated companies on the NXCLOUD platform
              (hereinafter referred to as "this platform"). Our registered address is [111 North Bridge Road, #21-01,
              Singapore 17909].</p>

            <p class="terms-desc"><strong>Prior to the registration, browsing or use of the NXCLOUD service, if you
                confirm your
                acceptance of this policy or continue to use the NXCLOUD service by checking the pop-up window or any
                other means of active choice, you shall be deemed to have carefully completed the reading and fully
                understood and accepted all the contents of this clause. This policy shall have legal effect between you
                and NXCLOUD, especially the clauses (including jurisdiction clauses) that limit or exempt the liability,
                and such clauses shall be highlighted to your attention by bold and/or underlined. This policy applies
                to
                all services provided by NXCLOUD.</strong> </p>
            <p class="terms-desc">If you have any questions regarding this clause or related matters, please contact us
              via <a href="mailto:info@nxcloud.com"> info@nxcloud.com.</a></p>

            <h2 class="terms-list-hdng privacy-hdng">Definition</h2>
            <p class="terms-desc"><strong class="terms-link">The website: <a href="https://www.nxcloud.com/english/"> https://www.nxcloud.com/english</a></strong> </p>

            <p class="terms-desc"><strong>NXCLOUD:</strong> refers to NXCLOUD ASIA PTE. LTD. (111 North Bridge Road,
              #21-01, Singapore 179098).</p>
            <p class="terms-desc"><strong>User or You:</strong> Refers to an individual or business using NXCLOUD
              products and/or services</p>
            <p class="terms-desc">Personal information refers to all kinds of information recorded by electronic or
              other means, which can be used separately or in combination with other information to identify specific
              natural persons or reflect the activities of specific natural persons. For the personal information
              referred to in this policy, see the types listed in the "How We Collect and Use Your Personal Information"
              section. Without doubt, personal information includes but is not limited to personal sensitive information
            </p>

            <p class="terms-desc"> Personal sensitive information refers to the personal information that, once
              disclosed or illegally used, is likely to cause damage to personal dignity or personal and property
              safety, and is likely to cause damage to personal reputation, physical and mental health or discriminatory
              treatment. Personal sensitive information referred to in these Policies may include: property information
              (including virtual property information such as bank account number and top-up information); personal
              identification information (including identity card); network identification information (including
              account name, nickname of account, email address and password related thereto); and other information
              (including address book, personal phone number, mobile phone number, itinerary information, web browsing
              record, precise positioning information). For the convenience of you to be aware of the above personal
              sensitive information listed in this Policy, we will, in addition to the uniform definition and listing
              herein, remind you of it in bold, underlined or otherwise highlighted in this Policy.
            </p>
            <p class="terms-desc"> Non- personal information: information that cannot be identified or associated with
              an individual. Including anonymous information (non-recoverable), as well as all non- personal information
              publicized and disclosed by the relevant competent authorities (such as the enterprise's contact address,
              contact information, business years, enterprise scale, industry, main business, main products, website
              domain name, etc.).
            </p>

            <p class="terms-desc"> Decryption: The process of making your personal information unidentifiable or
              unrelated by technical means without additional information.
            </p>
            <p class="terms-desc"> Business data: Different from user information, it refers to the data that users of
              NXCLOUD use the service of NXCLOUD to upload, download, distribute and so on.
            </p>

            <h3 class="terms-list-hdng privacy-hdng">Legal statement</h3>
            <h3 class="terms-list-hdng privacy-list">I. Ownership</h3>
            <ul class="article article-Registration">
              <li>1. The text, graphics and their combinations such as the logo and "NXCLOUD" of NXCLOUD website, as
                well as other logos, logos and names of NXCLOUD services of NXCLOUD website are registered trademarks of
                NXCLOUD and its affiliated companies in Singapore. Without the written authorization of NXCLOUD, no one
                may display, use or do other treatment (including but not limited to reproduction, dissemination,
                display, mirroring, uploading and downloading) in any way, or show others that you/your enterprise has
                the right to display, use or do other treatment.</li>

              <li>1.2. The intellectual property rights of all products, services, technologies and all procedures
                (hereinafter referred to as "technical services") of NXCLOUD website and NXCLOUD platform belong to
                NXCLOUD or its right holder.</li>

              <li>1.3. Unless otherwise stated by NXCLOUD, NXCLOUD has all the rights (including but not limited to
                copyright, trademark rights, patent rights, trade secrets and all other relevant rights) to publish
                documents and other information (including but not limited to text, graphics, pictures, photos, audio,
                video, icons, colors, layout, electronic documents) within the website. Without the permission of
                NXCLOUD, no one may use the above content without authorization (including but not limited to
                monitoring, copying, spreading, displaying, mirroring, uploading or downloading any content in NXCLOUD
                website through programs or devices). If authorized to browse, reproduce, print and disseminate the
                contents of the information on the NXCLOUD Web Site, such contents shall not be used for commercial
                purposes and the use of all and any part of the contents of the information must include this claim.
              </li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">II. Limitation of Liability</h3>
            <ul class="article article-Registration">
              <li>2.1 Users of NXCLOUD shall upload, provide and publish relevant information on NXCLOUD's website by
                themselves, including but not limited to user name, company name, contact person and contact
                information, relevant pictures and information. Such information shall be provided by users themselves,
                and users of NXCLOUD shall bear full responsibility for any information provided by them in accordance
                with the law.</li>
              <li><strong> 2.2 NXCLOUD reminds you that you and/or your end users shall abide by the laws of the
                  Singapore during
                  the use of NXCLOUD services, and shall not endanger the network security or use NXCLOUD services to
                  engage in activities infringing upon reputation, privacy, intellectual property and other legitimate
                  rights and interests. Despite the foregoing, NXCLOUD is not liable for the purposes and purposes of
                  using NXCLOUD Services by you and/or your end users.</strong></li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">II. Limitation of Liability</h3>
            <ul class="article article-Registration ">
              <li>We respect intellectual property rights and oppose and crack down on infringements. Any organization
                or individual who believes that the contents of the webpage of NXCLOUD's website (including
                https://www.nxcloud.com/english） may infringe upon its/his legitimate rights and interests may, by
                submitting a written notice of rights to NXCLOUD via email <a href="mailto:info@nxcloud.com">
                  info@nxcloud.com.</a>, be dealt with as soon as
                possible in accordance with the law upon receipt of the qualified notice from the intellectual property
                right holder.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-hdng">Privacy Policy</h3>
            <ul class="article article-Registration ">
              <li>This privacy policy mainly includes the following contents:</li>
              <li>I. How do we collect and use user information</li>
              <li>II. Cookies and similar technologies</li>
              <li>III. How do we share, transfer and publicly disclose user information</li>
              <li>IV. Rights of Users</li>
              <li>V. How do we protect and preserve user information</li>
              <li>VI. Protection of Minors' Privacy</li>
              <li>VII. How to update this Agreement</li>
              <li>VIII. Scope of Application</li>
              <li>IX. Jurisdiction of Disputes</li>
              <li>X. Contact Us</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">I、 How do we collect and use user information</h3>

            <ul class="article article-Registration article-privacy-list">

              <li>1.1 Types of user information collected and used</li>

              <li>1.1.1 When users use the NXCLOUD Platform and services, we will collect and use the information
                voluntarily provided by users in the process of using the NXCLOUD Platform services and generated from
                users' use of the NXCLOUD Platform and services in accordance with the principles of legality,
                justification and necessity for the purposes specified herein.</li>

              <li>1.1.2 In order to complete the registration, login, management, real-name authentication and other
                necessary activities for the accounts of the NXCLOUD platform, users are required to submit true,
                legitimate and effective information. The information required to be submitted shall include but not be
                limited to: basic information of the user (name, email address, mobile phone number, etc.), basic
                enterprise information of the user (name of organisation, business licence, unified social credit code,
                etc.), name of the user's legal representative, information of the user's handling officer (including
                name, mobile phone number, email address, identity card number, etc.) and bank account information of
                the user (including account opening bank, account number, account name, registration address and
                registration telephone number, etc.); for the purpose of real-name authentication, the user agrees and
                authorises us to provide, enquire and verify the aforesaid information of the user to the relevant
                identity authentication agency (such as personal credit investigation agency, government department,
                etc.) on our own or entrust a third party.</li>

              <li>1.2 Provide registration and authentication services for users</li>

              <li>1.2.1 When a user registers and logs into theNXCLOUD Platform, the user may create an account through
                the mobile phone number or mailbox, and we will verify whether the user's identity is valid by sending a
                verification code, or the user may login and create an account through the local number or mailbox with
                one button. Users can take the initiative to improve the relevant network identity information (such as
                avatars and passwords), which are collected to help users complete registration. The mobile phone number
                and mailbox submitted by a user are used to receive the verification code when the user registers, logs
                on, associates with the account, and retrieves the password, and are used as one of the contact methods
                designated by the user and NXCLOUD to receive the relevant business notices (such as launch of new
                products, service changes, etc.), and NXCLOUD may, through this contact method, market and promote
                products to users, send business notices (including bills) or carry out business communication with
                users.</li>
            </ul>

            <ul class="article article-Registration">
              <li>1.2.2 If the user only needs to use the basic services such as browsing the NXCLOUD website, the user
                does not need to register as a member and provide the above information.</li>
            </ul>


            <ul class="article article-Registration article-privacy-list">

              <li>1.3 Provision of technical services to users
                We collect the following information as users use the service:</li>

              <li>1.3.1 Log information: In order to ensure the normal operation of the NXCLOUD platform and protect the
                personal and property safety of users or third parties from being infringed, when users use the NXCLOUD
                platform and related services, in order to ensure the normal operation of the software and services, we
                will collect the device models, operating system version numbers, device identifiers (Android such as
                IMEI, AndroidID, OAID, IMSI, ICCID, GAID, MEID, iOS such as IDFV, IDFA; different identifiers will be
                different in terms of validity, whether they can be reset by users and how they obtain them), software
                version numbers, login IP addresses, how the network is accessed, types and status, network quality
                data, operation logs, service logs, device MAC addresses, storage and telephone rights, and software
                list information. Please understand that this information is our service and guarantee the normal
                operation of the basic services to collect information.</li>

              <li>.3.2 We collect this information in order to better serve our users, and to achieve this we will also
                use our users' information for the following purposes: (1) contacting users to solve problems; (2)
                improving the quality of our services; and (3) other purposes as permitted by users.</li>
            </ul>

            <ul class="article article-Registration">

              <li>1.3.3 Support information for user accounts: based on the user's consultation records, security
                records and troubleshooting processes for user failures (such as communications or call records)
                resulting from the user's use of the NXCLOUD service, NXCLOUD will record and analyze this information
                to better respond to user requests for help in a timely manner and to use it to improve services.</li>

              <li>1.3.4 When providing users with business functions or specific services, we will collect, use, store
                and protect user information in accordance with this Policy and the provisions of the corresponding
                product service agreement; if the collection of user information is beyond the scope and purpose of this
                Policy and the corresponding product service agreement, we will separately explain to the users the
                scope and purpose of information collection, and collect user information necessary for providing the
                corresponding services after obtaining the consent of the users; if the users choose not to provide the
                aforesaid information, it will affect the users to use the corresponding products, but will not affect
                the users to use the basic functions of the NXCLOUD website and other products.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">1.4 We obtain your personal information from third parties</h3>
            <ul class="article article-Registration">
              <li>1.4.1 We may collect and use the User's personal information from third parties (our partners) within
                the scope of the User's authorized consent. We guarantee to handle the user's personal information in
                strict accordance with the contract signed with the third party and the relevant laws, and ask the user
                to read the third party's privacy policy and user agreement. If users refuse to collect, use or transfer
                the personal information of users when providing services, users may be unable to use the corresponding
                services of NXCLOUD.</li>
              <li>1.4.2. We may obtain and properly use the public information about users through web pages and other
                public channels, or obtain from third-party providers the business information about users and
                enterprises that has been publicized to the public, so as to help us better understand our customer
                base, such as the industry of users, the scale of user enterprises and the website URL of user
                enterprises.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">1.5 The device permissions we need to call</h3>
            <ul class="article article-Registration">
              <li>We invoke some of the user's device permissions during the service. Users can choose to turn off
                some or all of the permissions in Settings to deny us the ability to collect personal information
                about users. The specific types are as follows:</li>

              <li>(1) Camera permissions</li>

              <li>In order to provide users with video recording services, capture camera images, take photos and
                record images, we need to obtain the user's camera permissions.</li>

              <li>(2) Microphone permissions</li>

              <li>In order to provide users with video recording services, capture user voice, recording sound, we
                need to obtain user microphone permissions.</li>

              <li>(3) Handset authority</li>

              <li>In order to provide users with audio playback services and modify the configuration information of
                the handset and speaker, we need to obtain the user's audio permissions.</li>
            </ul>


            <h3 class="terms-list-hdng privacy-list">1.6 Providing security for users</h3>
            <ul class="article article-Registration">
              <li>In order to enhance the security of users' use of our services and protect the personal and property
                security of users or the public from infringement, we use or integrate the user's registration
                information, equipment information, network logs, as well as the frequency of users' use of the
                application, crash data, overall usage, performance data and the source of the application to
                comprehensively judge user accounts and transaction risks, conduct identity verification, detection and
                prevention of security incidents, and take necessary measures for recording, auditing, analysis and
                disposal in accordance with the law.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">1.6 Providing security for users</h3>
            <ul class="article article-Registration">
              <li>In order to enhance the security of users' use of our services and protect the personal and property
                security of users or the public from infringement, we use or integrate the user's registration
                information, equipment information, network logs, as well as the frequency of users' use of the
                application, crash data, overall usage, performance data and the source of the application to
                comprehensively judge user accounts and transaction risks, conduct identity verification, detection and
                prevention of security incidents, and take necessary measures for recording, auditing, analysis and
                disposal in accordance with the law.</li>
            </ul>


            <h3 class="terms-list-hdng privacy-list">1.7 Other purposes</h3>
            <ul class="article article-Registration">
              <li>Under any of the following circumstances, we will separately seek the consent of the user in advance
                in accordance with the laws and regulations:</li>
              <li>1.7.1 We use the information for other purposes or purposes not specified in this Agreement;</li>
              <li>1.7.2 We use information collected for specific use purposes for other use purposes.</li>
              <li>1.8 In providing our products and/or services, we may need to apply to users for partial device system
                permissions that are not enabled by default and are used only with the express authorization of the user
                for the implementation of specific product features and/or services, and the user may revoke the
                authorization. In particular, we do not collect information from users when they are not needed even if
                they are authorized to do so</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">II、 Cookies and similar technologies</h3>
            <ul class="article article-Registration">
              <li>2.1 cookies and similar technologies are common on the Internet. To make sure the NXCLOUD platform
                works, make it easier for users to access it, recommend content or ads they might be interested in, we
                store small data files called cookies on their mobile devices. Cookies usually contain identifiers, site
                names, and numbers and characters. With the help of cookies and similar technologies, websites and
                clients can store users' registered accounts, e-mails, passwords (encryption), preferences and other
                data, eliminate the need to repeatedly fill in personal information, and can also help us calculate
                traffic information and analyze the effectiveness of page design and advertising.</li>

              <li>2.2 We will not use cookies or similar technologies for any purpose other than those specified in this
                Agreement. Of course, users can also choose to clear all cookies saved on their mobile device (My -
                Settings - Clear Cache).</li>

              <li>2.3 In addition to cookies, we will also use Web site beacons and pixel tags and other similar
                technologies. For example, an email we send to a user may contain an address link to our content, and if
                the user clicks on that link, we will track that click to help us understand their product or service
                preferences so that we can proactively improve the service experience. A website beacon is usually a
                transparent image embedded in a website or email. With the help of the pixel labels in the email, we can
                see if the email is open. If users do not want their activities to be tracked in this way, they can
                unsubscribe from our mailing list at any time.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">III、 How do we share, transfer and publicly disclose user
              information</h3>
            <ul class="article article-Registration">
              <li>3.1 We will not share user information with any company, organization or individual other than NXCLOUD
                Platform,
                except for the following circumstances:</li>


              <li>3.1.1 Shared with the explicit consent or authorization of the user (and guardian) or the voluntary
                choice of the
                user.</li>

              <li>3.1.2 Sharing under statutory circumstances. We will share user information in accordance with laws
                and regulations,
                dispute resolution needs, or as required by administrative or judicial authorities in accordance with
                the law. For
                example, if we determine that a user has violated laws and regulations or seriously violated the
                relevant agreements
                and rules of the NXCLOUD Platform, or in order to protect the personal and property safety of users of
                the NXCLOUD
                Platform and its affiliated companies or the public, we may disclose information about the user in
                accordance with
                laws and regulations or the relevant agreements and rules of the Platform.</li>

              <li>3.1.3 When we authenticate a user, we use the authentication service of a third-party authentication
                agency to
                authenticate the user. For this purpose, we provide the user's name and identity card number to the
                third-party
                authentication agency based on the user's consent to complete such authentication service.</li>

              <li>3.1.4 In the event of merger, acquisition or bankruptcy liquidation of NXCLOUD Platform, if the
                transfer of user
                information is involved, we will require the successor to continue to be bound by this Agreement;
                otherwise, we will
                require the company, organization and individual to re-seek the authorized consent from the user.</li>
            </ul>


            <ul class="article article-Registration article-privacy-list">
              <li>3.2 In accordance with the provisions of relevant laws and regulations, the sharing, transfer and
                public disclosure of personal information of users are not subject to the authorization and consent of
                users in advance under the following circumstances:</li>

              <li>3.2.1 Those directly related to national security and national defense security;</li>

              <li>3.2.2 Those directly related to public security, public health or major public interests;</li>

              <li>3.2.3 Those related to criminal investigation, prosecution, trial and enforcement of judgments;</li>

              <li>3.2.4 It is difficult to obtain the personal consent for the purpose of safeguarding the life,
                property or other major legitimate rights and interests of you or other individuals;</li>

              <li>3.2.5 The personal information collected is disclosed to the public by you;</li>

              <li>3.2.6 Other circumstances stipulated by laws and regulations.</li>

              <li>3.3 According to the provisions of the law, sharing or transferring the de-identified personal
                information and ensuring that the data receiver is unable to recover and re-identify the subject of
                personal information shall not be deemed as sharing, transferring or disclosing personal information to
                the public, and the preservation and processing of such data shall not be subject to separate
                notification to you and your consent.
                3.4 Tips on sensitive personal information</li>
            </ul>
            <ul class="article article-Registration">
              <li>Sensitive personal information refers to the personal information that is likely to cause damage to a
                natural person's personal dignity or personal or property safety once disclosed or illegally used. The
                above sensitive personal information that may be included in the user's information provided by the user
                or collected by us, such as identity document number, personal biometric information (static or
                dynamic), bank account number. Users are advised to be cautious and mindful of sensitive personal
                information. Users agree that we can handle sensitive personal information for the purposes and in the
                manner described in this policy.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">IV、 Rights of Users</h3>
            <ul class="article article-Registration">
              <li>4.1 Access, change, delete user account information
                After the user completes the registration, login and necessary authentication of the account, the user
                has the right
                to access, correct and delete the account information of the user through the following paths:</li>

              <li>4.1.1 Access to personal information: click "Console - User Management" to view basic information.
              </li>

              <li>4.1.2 Correction of personal information: Click "Console - User Management - Basic Information" to
                correct basic
                information other than personal/company name.</li>
              <li>4.1.3 Inquire about the mobile phone number or mailbox: Click "console - user management - basic
                information" to
                view the mobile phone number or mailbox.</li>

              <li>4.1.4 Change the mobile phone number or mailbox: Click "console - user management - basic information"
                to change the
                mobile phone number or mailbox.</li>

              <li>4.1.5 Cancellation of accounts: We provide users with the means to cancel accounts. Subject to the
                conditions agreed
                in our Terms of Service and the provisions of relevant laws and regulations, users may choose to contact
                our online
                customer service (400-7800-900) or contact us through the feedback channels listed in the "Contact Us"
                section of
                this Policy to cancel users' NXCLOUD accounts. When users log off their accounts, they may be asked to
                authenticate
                to protect their information. Users should be aware that canceling their NXCLOUD account will result in
                permanent
                loss of access to the account and the data in the account. After verifying the identity of the user and
                negotiating
                with the user to process the assets in the user's account, we will provide the account cancellation
                service for the
                user. In order to provide users with a more convenient way to log off, we will continue to optimize our
                products,
                and through page announcements to inform users. After canceling the NXCLOUD account, we will stop
                providing users
                with products or services and delete or anonymize the personal information of users as soon as possible
                according to
                the requirements of users, unless otherwise stipulated by laws and regulations.</li>

              <li>4.2 Please understand that each business function requires some basic user information to be
                completed. When a user
                withdraws his consent or authorization, we cannot continue to provide the user with the service
                corresponding to the
                withdrawal of consent or authorization, nor will we process the user's personal information. However,
                the decision
                of the user to withdraw his consent or authorization shall not affect the information processing based
                on the user's
                authorization.</li>

              <li>4.3 If the User finds that we collect and use the User's information in violation of the provisions of
                laws and
                regulations or the agreement of both parties, the User may request us to delete such information. If
                users find that
                we collect, store the information of users have errors, and can not correct themselves, users can also
                request us to
                correct. When removing information, we may require users to authenticate to secure their accounts. Once
                the above
                request for deletion is answered, the personal information of the user will be deleted unless otherwise
                required by
                laws and regulations.</li>

              <li>4.4 Some information of a user may be inaccessible, modified or deleted due to legitimate reasons such
                as the
                requirements of laws and regulations and protection of information security.</li>

              <li>4.5 Obtain a copy of personal information
                Users have the right to obtain copies of their personal information and can contact customer service by
                email at any
                time. If it is technically feasible, such as data interface matching, we can also transfer the user's
                personal
                information directly to the third party designated by the user.</li>

              <li>4.6 Respond to user's request above
                For security reasons, we may require that the user's identity be verified before processing the user's
                request. The
                user may need to provide a written request or otherwise identify the user. For all requests made by
                users under this
                section, we will in principle respond within 15 days of receipt of the request and verification of the
                identity of
                the user.</li>
            </ul>

            <ul class="article article-Registration article-privacy-list">
              <li>4.7 Right to report to us</li>

              <li>4.7.1 When a user believes that his or her information may be infringed, he or she can click "online
                customer service" or contact the NXCLOUD platform via the email addressinfo@nxcloud.com. NXCLOUD
                Platform will review the issues involved as soon as possible, and verify the identity of users in a
                timely manner to reply, generally within 15 days to give a reply.</li>

              <li>4.8 Review privacy policy</li>

              <li>4.8.1 We will remind you of this privacy policy on the Register/Login Bull Cloud Platform account
                page. Alternatively, you may view the full contents of this privacy policy at any time by clicking on
                "User Privacy Policy" at the end of the homepage of the https://www.nxcloud.com/english.</li>

              <li>4.9 Right to be informed of cessation of operation
                If the NXCLOUD Platform terminates its service or operation, we will timely stop the collection of user
                information, and will notify users of the termination notice in the form of one-by-one delivery or
                announcement at least 30 days in advance, and delete or anonymize the user information held after the
                termination of the service or operation.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">V、 How do we protect and preserve user information</h3>
            <ul class="article article-Registration">
              <li><strong>5.1 Technical protection measures</strong></li>
              <li>5.1.1 We attach great importance to the security of user information and will endeavor to take various
                reasonable security measures in line with industry standards to protect user information so that user
                information will not be leaked, damaged or lost, including but not limited to SSL, encrypted storage of
                privacy information and access control of data centers. We use encryption to improve the security of
                user information; we use trusted protection against malicious attacks on user information; and we deploy
                access control mechanisms to ensure that only authorized persons have access to user information.</li>
              <li><strong>5.2 Security management system</strong></li>
              <li>5.2.1 We have an advanced data -based data security management system focusing on the life cycle of
                data to enhance the security of the whole system in terms of organization construction, system design,
                personnel management, product technology, personal information security impact assessment and other
                aspects.</li>
              <li>5.2.2 We have also adopted strict management on the employees or outsourced personnel who may have
                access to the users' information, including but not limited to taking different authority control
                according to different positions, signing confidentiality agreements with them, monitoring their
                operation and other measures. We will conduct security and privacy training courses to enhance staff
                awareness of the importance of user information.</li>
              <li><strong>5.3 Account Protection</strong></li>
              <li>5.3.1 All accounts of users have security protection functions. Please properly keep the account and
                password information of users. If the user found that the account was stolen or information leakage,
                please contact us, so that we take appropriate measures.</li>

              <li>5.3.2 Please use complex passwords to help us keep our accounts secure. We will do our best to ensure
                the security of any information users send us. If our physical, technical or management protection
                facilities are damaged, resulting in unauthorized access to information, public disclosure, tampering or
                destruction, resulting in damage to the legitimate rights and interests of users, we will bear the
                corresponding legal liability.</li>

              <li><strong>5.3 Account Protection</strong></li>

              <li>5.3.1 All accounts of users have security protection functions. Please properly keep the account and
                password information of users. If the user found that the account was stolen or information leakage,
                please contact us, so that we take appropriate measures.</li>
              <li>5.3.2 Please use complex passwords to help us keep our accounts secure. We will do our best to ensure
                the security of any information users send us. If our physical, technical or management protection
                facilities are damaged, resulting in unauthorized access to information, public disclosure, tampering or
                destruction, resulting in damage to the legitimate rights and interests of users, we will bear the
                corresponding legal liability.</li>

              <li><strong>5.4 Information security incident handling</strong></li>
              <li>5.4.1 Despite the aforesaid security measures, users are also required to understand that there is no
                "perfect security measures" on the information network. In the event of an unfortunate user information
                security incident, we will inform the user of the basic information of the security incident and its
                possible impact, the measures we have taken or will take to deal with the incident, the suggestions that
                the user may take to prevent and reduce risks, the remedial measures for the user, etc. We will inform
                users of the relevant information by telephone, push notification and other means. If it is difficult to
                inform the subject of personal information one by one, we will take reasonable and effective measures to
                release an announcement. Meanwhile, we will also report the handling of user information security
                incidents in accordance with the requirements of regulatory departments.</li>

              <li><strong>5.5 How do we save user information</strong></li>
              <li>5.5.1 Time limit for storage</li>

              <li>Unless otherwise provided by law or regulation or regulatory authorities, we only store personal
                information for
                such periods as are necessary for the purposes of this Policy. In the event of termination of services
                or
                operations, we shall promptly cease collection of personal information, notify users in advance pursuant
                to the
                relevant laws and regulations, and delete or anonymise the relevant personal information upon
                termination of
                services or operations, unless otherwise stipulated by laws and regulations or the regulatory
                authorities.</li>

              <li>5.5.2 Storage Territory</li>

              <li>In principle, we store users' personal information only in Singapore, However, we may provide users'
                personal
                information outside Singapore or store users' personal information outside Singapore under the following
                circumstances:</li>

              <li>(1) There are clear provisions in laws and regulations;</li>

              <li>(2) Obtain your consent, provided that you ensure that you have obtained the consent of the end user.
              </li>

              <li>We ask you to pay special attention to that different countries or regions have their own regulatory
                requirements
                for the collection, storage, use and sharing of personal information, and you shall actively abide by
                the laws,
                regulations and regulatory requirements of each country or region. In order to prevent you from
                violating the local
                regulatory provisions due to your ignorance of the laws and regulations on personal information
                protection in the
                country or region where your end user is located, we strongly recommend that you only use the NXCLOUD
                service in
                Singapore. If you apply cloud credit services outside Singapore, you shall comply with local legal
                requirements on
                cross-border transfer of personal information to ensure that the storage of personal information within
                Singapore
                meets local regulatory requirements. In particular, when cross-border transmission involves Russia,
                India, the
                European Union, the United States and other countries or regions, please pay attention to the regulation
                of personal
                information in these countries and regions. You agree that you will be responsible for the risks and
                liabilities
                arising from the cross-border transmission, and you will be liable to compensate us in full for any loss
                caused to
                us.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">VI、 Protection of Minors' Privacy</h3>
            <ul class="article article-Registration">
              <li>6.1 We do not accept the registration of children as our users and we do not accept the provision of
                personal
                information of end users under the age of 14 years in principle. Please note and provide such
                information carefully.
                Although the definition of a child varies by law and custom, we consider anyone under the age of 14 to
                be a child.
                If we unknowingly collect personal information from children under the age of 14, we will promptly
                delete the
                relevant information unless the law requires us to retain such information.</lI>

              <li>6.2 If you believe that we have wrongly or accidentally collected information from children under the
                age of 14,
                please contact us immediately: info@nxcloud.com.</lI>

              <li>6.3 If your application is designed and developed for children under 14 years of age, make sure that
                your end users
                are the guardians, have read and agree to the application's privacy policy, and are authorized to
                provide us with
                the child's personal information to enable the functionality of your application.</lI>
            </ul>

            <h3 class="terms-list-hdng privacy-list">VII、 How to update this Agreement</h3>
            <ul class="article article-Registration">
              <li>NXCLOUD Platform reserves the right to update or modify this policy from time to time. If the NXCLOUD
                platform makes changes or revisions to the privacy policy statement, the NXCLOUD platform may send
                change notices to users through different channels, such as e-mails or other means. We encourage users
                to check this privacy policy for the latest changes. If Customer continues to use our Services, we agree
                to accept the contents of the amended Agreement and if Customer does not agree to the amended Policy, we
                will no longer be able to provide services to Customer.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">VIII、 Scope of Application of this Privacy Policy</h3>
            <ul class="article article-Registration">
              <li> <strong>8.1 If you register an account on the NXCLOUD website or purchase NXCLOUD products and
                  services, this
                  policy will apply, unless there is an independent privacy policy for the relevant services or a
                  special
                  agreement in the corresponding user agreement.</strong>
              </li>

              <li> <strong>.2 This privacy policy does not apply to products or services linked to other third parties
                  in NXCLOUD
                  Platform Services. Users who use these third party services (including any personal information
                  provided by
                  users to these third parties) will be subject to the service terms and privacy policy of these third
                  parties
                  (not this privacy policy). Users are specifically required to read the third party terms carefully.
                  Please
                  protect the information properly and provide it to the third party only when necessary.</strong>
              </li>

              <li> <strong>8.3 Specifically, as a user of NXCLOUD, we also suggest that you use our cloud services to
                  provide services
                  to your users. If the collection and use of your personal information are involved, you shall
                  separately agree
                  with your users on privacy policies.</strong>
              </li>

              <li> <strong>8.4 Other legal basis for handling personal information (EEA only)
                  We request the user to pay special attention to: when the user carries out personal information
                  processing
                  activities, if the user meets any of the following conditions, the user is requested to pay attention
                  to comply
                  with the EU General Data Protection Regulation (hereinafter referred to as "GDPR"):</strong>
              </li>

              <li> <strong>1 the user is in the EU Economic Area ("EEA"), whether or not the processing is carried out
                  in the EU;</strong>
              </li>

              <li> <strong>(2) Providing goods or services (whether paid or not) to individuals in the EEA, or
                  monitoring their
                  activities in the EEA;</strong>
              </li>

              <li> <strong>(3) Outside the EEA, but the laws of the EU Member States (e.g. embassies or consulates of
                  the EEA Member
                  States) apply in accordance with public international law.</strong>
              </li>
            </ul>

            <ul class="article article-Registration">
              <li>If you and/or your end users are from the EEA, the legal basis for us to collect and use the above
                personal
                information will depend on the relevant personal information and the specific context in which we
                collect it.</li>

              <li>However, we usually do so only if we need personal information to perform a contract with a user, or
                if it is in our
                legitimate interest to deal with a situation that is not covered by the user's data protection or
                fundamental rights
                and freedoms, or if we obtain the user's consent. In some cases, we may also have a legal obligation to
                collect
                personal information from you and/or your end users, or may need personal information to protect the
                immediate
                interests of users or others, for example, to provide personal information of users at the request of
                the relevant
                authorities for the purpose of public safety, or to respond to requests from law enforcement
                authorities.</li>

              <li> you have questions about the legal basis for us to collect and use personal information about you
                and/or your end
                users or if you need further information, please contact us using the contact information we provide in
                Section 10
                of this privacy policy.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">IX、 Jurisdiction of Disputes</h3>
            <ul class="article article-Registration">
              <li>The establishment, effectiveness, implementation, interpretation and dispute resolution of this
                privacy policy shall be governed by the laws of Singapore.</li>
              <li>In case of any dispute or dispute between the Customer and us, the dispute or dispute shall be settled
                through friendly negotiation first. If the negotiation fails, the Customer agrees to submit the dispute
                or dispute to the Singapore courts for settlement.</li>
            </ul>

            <h3 class="terms-list-hdng privacy-list">Contact us:</h3>
            <ul class="article article-Registration">
              <li>If you have any questions, comments or suggestions regarding this privacy policy, you can contact us
                by email: <a href="mailto:info@nxcloud.com"> info@nxcloud.com.</a>, telephone: 400-7800-900, we will
                respond to your request within 15 business days</li>

              <li>In the case of an individual in the EEA, the User has the right to lodge a complaint with the
                competent data protection authority or bring a suit before a court of competent jurisdiction under the
                applicable data protection law.</li>
            </ul>


          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success"> 
                    Get Started
                </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
    export default {
        name: 'PrivacyPolicy'
    }
</script>

  