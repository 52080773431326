<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-11 col-xl-11">
            <div class="row row_sign_up">
              <div class="col-12 col-lg-6 col-md-12 d-flex col-sign-up">
                <div class="inner">
                  <h1
                    class="hdng"
                  >
                    重设密码
                  </h1>
                </div>

                <section class="contact_sec">
                  <div class="form_wrap">
                    <form id="contactForm">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form_field">
                            <input
                              type="tel"
                              class="form_control"
                              name="phone"
                              id="phone"
                              placeholder="手机号码（国家代码+手机号码）/ 电子邮件 / 用户名"
                              v-model="forgotPassData.formData.email"
                              @input="checkUserEmail"
                            />
                            <div
                              v-if="!forgotPassData.errors.showErrorEmail"
                              class="errorInput"
                            >
                              Please Enter Company Email
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-9">
                          <div class="form_field">
                            <input
                              type="text"
                              class="form_control"
                              name="phone"
                              id="phone"
                              placeholder="输入验证码"
                              v-model="forgotPassData.formData.verifyCode"
                              @input="checkUserOtp"
                            />
                            <div
                              v-if="!forgotPassData.errors.showErrorOtp"
                              class="errorInput"
                            >
                              Please Enter Valid OTP
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-3">
                          <div class="form_field">
                            <button
                              :class="{
                                disabled: forgotPassData.errors.isOtpClick,
                              }"
                              class="otpButton"
                              @click="sendOtp"
                            >
                              OTP
                            </button>
                            <!-- <div class="loader">
                              <div class="tm-ring"><div></div><div></div><div></div><div></div></div>
                            </div> -->
                          </div>
                        </div>
                        <div
                          class="col-12 col-xl-9 col-lg-12 mt-minus"
                          v-if="forgotPassData.errors.messageAfterOtp"
                        >
                          <div
                            :class="{
                              error: forgotPassData.errors.messageAfterOtp,
                            }"
                            class="messageAfterSubmit"
                          >
                            {{ forgotPassData.errors.messageAfterOtp }}
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form_field">
                            <input
                              type="password"
                              class="form_control"
                              name="phone"
                              id="phone"
                              placeholder="输入新密码"
                              v-model="forgotPassData.formData.changePassword"
                              @input="checkUserPass"
                            />
                            <div
                              v-if="!forgotPassData.errors.showErrorPassword"
                              class="errorInput"
                            >
                              Please Enter Password
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form_field">
                            <input
                              type="password"
                              class="form_control"
                              name="phone"
                              id="phone"
                              placeholder="再次输入新密码"
                              v-model="confPass"
                              @input="checkUserConfPass"
                            />
                            <div
                              v-if="
                                !forgotPassData.errors.showErrorConfPassword
                              "
                              class="errorInput"
                            >
                              Please Enter Password Again
                            </div>

                            <div
                              v-if="forgotPassData.errors.passwordMatch"
                              class="error messageAfterSubmit"
                            >
                              Password & Confirm PAssword Not Macth
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-9 col-lg-12">
                          <div class="submit_wrap">
                            <div class="submitWrapper signUp">
                              <input
                                type="submit"
                                value="确认
"
                                @click="submitForgotForm"
                              />
                              <!-- Loader -->
                              <div
                                class="loader"
                                v-if="forgotPassData.errors.isForgotSubmit"
                              >
                                <div class="tm-ring">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>

                            <div
                              v-if="forgotPassData.errors.messageAfterSubmit"
                              :class="{
                                error: forgotPassData.errors.messageAfterSubmit,
                              }"
                              class="messageAfterSubmit"
                            >
                              {{ forgotPassData.errors.messageAfterSubmit }}
                            </div>
                            <p class="info">
                              没有帐户？
                              <router-link to="/zh-sign-up">
                                <u>立即注册</u>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>

              <div class="col-12 col-lg-6 col-md-12 mt-4 mt-md-0">
                <img
                  src="../../assets/images/login_banner.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供<br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
export default {
  name: "ZhForgetPassword",
  data() {
    return {
      confPass: "",
      forgotPassData: {
        errors: {
          showErrorEmail: true,
          showErrorOtp: true,
          showErrorPassword: true,
          showErrorConfPassword: true,
          isForgotSubmit: false,
          isOtpClick: false,
          isOtpSubmit: false,
          messageAfterOtp: "",
          messageAfterSubmit: "",
          passwordMatch: false,
        },
        formData: {
          email: "",
          verifyCode: "",
          changePassword: "",
        },
      },
    };
  },
  methods: {
    checkUserEmail() {
      if (!this.forgotPassData.formData.email.trim()) {
        this.forgotPassData.errors.showErrorEmail = false;
      } else {
        this.forgotPassData.errors.showErrorEmail = true;
      }
    },
    checkUserOtp() {
      if (!this.forgotPassData.formData.verifyCode.trim()) {
        this.forgotPassData.errors.showErrorOtp = false;
      } else {
        this.forgotPassData.errors.showErrorOtp = true;
      }
    },
    checkUserPass() {
      if (!this.forgotPassData.formData.changePassword.trim()) {
        this.forgotPassData.errors.showErrorPassword = false;
      } else {
        this.forgotPassData.errors.showErrorPassword = true;
      }
    },
    checkUserConfPass() {
      if (!this.confPass.trim()) {
        this.forgotPassData.errors.showErrorConfPassword = false;
      } else {
        this.forgotPassData.errors.showErrorConfPassword = true;
      }
    },
    sendOtp(event) {
      event.preventDefault();
      //  check if fields are empty
      if (!this.forgotPassData.formData.email.trim()) {
        this.forgotPassData.errors.showErrorEmail = false;
        return;
      }
      this.forgotPassData.errors.isOtpSubmit = true;
      axios
        .get(
          `https://testdid.nxcloud.com/register/mailForgetPassword/?email=${this.forgotPassData.formData.email.trim()}`,
          {
            headers: {
              "Content-Language": "EN",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          // this.signUpFormData.errors.showErrorTermsCheck = true;
          // this.signUpFormData.errors.isLoginSubmit = false;
          this.forgotPassData.errors.messageAfterOtp = response.data.msg;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitForgotForm(event) {
      event.preventDefault();
      if (!this.forgotPassData.formData.email.trim()) {
        this.forgotPassData.errors.showErrorEmail = false;
        return;
      }
      if (!this.forgotPassData.formData.verifyCode.trim()) {
        this.forgotPassData.errors.showErrorOtp = false;
        return;
      }
      if (!this.forgotPassData.formData.changePassword.trim()) {
        this.forgotPassData.errors.showErrorPassword = false;
        return;
      }
      if (!this.confPass.trim()) {
        this.forgotPassData.errors.showErrorConfPassword = false;
        return;
      }
      if (
        this.confPass.trim() !=
        this.forgotPassData.formData.changePassword.trim()
      ) {
        console.log("password is not same");
        this.forgotPassData.errors.passwordMatch = true;
        return;
      }
      this.forgotPassData.errors.isForgotSubmit = true;

      axios
        .post(
          "https://testdid.nxcloud.com/register/mailChangePassword",
          this.forgotPassData.formData,
          {
            headers: {
              "Content-Language": "EN",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.forgotPassData.errors.isForgotSubmit = false;
          this.forgotPassData.errors.messageAfterSubmit = response.data.msg;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
.ap_country_list {
  position: relative;
}

.ap_country_list > span {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background-color: var(--white-color);
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
  display: none;
  overflow-y: scroll;
}

.ap_country_list .countryDD.show {
  display: block;
}

.ap_country_list {
  position: relative;
}

.ap_country_list > span {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background: #fff;
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
}

.ap_country_list .countryDD .c_name {
  background: #fff;
  padding: 3px 15px;
  cursor: pointer;
  font-size: 15px;
}

.ap_country_list .countryDD .c_name:hover {
  background: #e9e9e9;
}

.ap_country_list .countryDD .c_name .flag-icon {
  margin-right: 15px;
}
.ap_country_list::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: transparent;
  z-index: 9;
  transform: rotate(45deg) translateY(-50%);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  right: 20px;
  top: 40%;
  pointer-events: none;
}

.otpButton {
  background-color: #85c100;
  border: 0;
  height: 48px;
  width: 100%;
  border-radius: 7px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.mt-minus {
  margin-top: -20px;
  margin-bottom: 15px;
}
.disabled.otpButton {
  pointer-events: none;
}
</style>
